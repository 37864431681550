<template>
  <div v-mousetrap="['?', 'h']" @mousetrap="() => $emit('mousetrap')">
    <Teleport to="#modals">
      <BuOrganismModalNew v-bind="$attrs" :headline="{ text: t('headline') }">
        <div :class="$style.content">
          <BuMoleculeContentBox
            :headline="{ text: t('navigation.headline') }"
            :modifier="['small']"
          >
            <dl :class="$style.list">
              <dt>{{ t("navigation.actions.search") }}</dt>
              <dd><kbd>s</kbd>, <kbd>/</kbd></dd>
              <dt>{{ t("navigation.actions.results") }}</dt>
              <dd><kbd>r</kbd></dd>
              <dt>{{ t("navigation.actions.filters") }}</dt>
              <dd><kbd>f</kbd></dd>
              <dt>{{ t("navigation.actions.detail") }}</dt>
              <dd><kbd>d</kbd>, <kbd>v</kbd></dd>
              <dt>{{ t("navigation.actions.help") }}</dt>
              <dd><kbd>?</kbd>, <kbd>h</kbd></dd>
            </dl>
          </BuMoleculeContentBox>
          <BuMoleculeContentBox
            :headline="{ text: t('actions.headline') }"
            :modifier="['small']"
          >
            <dl :class="$style.list">
              <dt>{{ t("actions.actions.toggleComparison") }}</dt>
              <dd><kbd>space</kbd></dd>
            </dl>
          </BuMoleculeContentBox>
        </div>
      </BuOrganismModalNew>
    </Teleport>
  </div>
</template>

<script>
export default {
  inheritAttrs: false,
  emits: ["mousetrap"],

  methods: {
    t(key, options) {
      const prefix = "components.organisms.modalKeyboardShortcuts";
      return this.$t(`${prefix}.${key}`, options);
    },
  },
};
</script>

<style lang="scss" module>
@import "~@webprojects/ui-pattern-library/src/stylesheets/environment";

.content {
  display: grid;
  grid-gap: get-space(m);
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
}

.list {
  display: flex;
  flex-wrap: wrap;
  margin: 0 0 -1 * get-space(xs) 0;

  dt,
  dd {
    @include text-type-copy-small;
    margin: 0 0 get-space(xs) 0;
    flex: 1 0 50%;
  }

  dd {
    text-align: right;
  }

  kbd {
    background: get-color(blue-light);
    border-radius: 2px;
    border: 1px get-color(gray-light) solid;
    margin: 0 2px;
    padding: 0 4px;
  }
}
</style>
