<template>
  <OrganismLayoutSection :is-loading="isUpdating">
    <template #title>{{ t("headline", { total: totalItems }) }}</template>
    <template v-if="!isLoading" #actions>
      <!--
        only show toggle inactive products for user of role `sales`
      -->
      <BuAtomToggle
        v-if="can('read:inactive')"
        :id="`toggle-showinactive-${uid}`"
        :label="t('toggleInactive')"
        :modifier="['align-right']"
        :value="inactiveEnabled"
        @change="inactiveEnabled = $event.target.checked"
      />
      <BuAtomToggle
        :id="`toggle-compare-${uid}`"
        :label="t('toggleComparison')"
        :modifier="['align-right']"
        :value="comparisonEnabled"
        @change="comparisonEnabled = $event.target.checked"
      />
    </template>

    <OrganismDataTableLoading v-if="isLoading" />
    <ResultsTable v-else />
  </OrganismLayoutSection>
</template>

<script>
import { uuid } from "vue-uuid";
import { mapState } from "vuex";

import OrganismDataTableLoading from "../../styleguide/organisms/OrganismDataTableLoading.vue";
import OrganismLayoutSection from "../../styleguide/organisms/OrganismLayoutSection.vue";

import ResultsTable from "./ResultsTable.vue";

import mixins from "../../../lib/GlobalMixins";

export default {
  components: {
    OrganismLayoutSection,
    OrganismDataTableLoading,

    ResultsTable,
  },

  mixins: [mixins],

  data() {
    return {
      uid: uuid.v4(),
    };
  },

  computed: {
    ...mapState({
      userRole: (state) => state.global.user.data.role,
      isLoading: (state) => {
        const { actionOrigin, isLoading } = state.search.result;
        return isLoading && actionOrigin === "search";
      },
      isUpdating: (state) => {
        const { actionOrigin, isLoading } = state.search.result;
        return isLoading && actionOrigin === "result";
      },
      totalItems: (state) =>
        Object.keys(state.search.result.data).length > 0
          ? state.search.result.data.pagination.total
          : null,
    }),

    comparisonEnabled: {
      get() {
        return this.$store.getters["compare/checkboxesEnabled"];
      },
      set(checkboxesEnabled) {
        this.$store.commit("compare/setCheckboxesEnabled", checkboxesEnabled);
        if (!checkboxesEnabled) {
          this.$store.commit("compare/reset");
        }
      },
    },

    inactiveEnabled: {
      get() {
        return this.$store.state.search.additionalFacets.showInactive;
      },
      set(enabled) {
        this.$store.commit("search/setAdditionalFacet", {
          key: "showInactive",
          value: enabled,
        });

        // reload search request
        this.$store.dispatch("search/load", "result");
      },
    },
  },

  methods: {
    t(key, options) {
      const prefix = "connected.results";
      return this.$t(`${prefix}.${key}`, options);
    },
  },
};
</script>
