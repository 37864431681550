<template>
  <BuAtomLink v-if="shouldShow" icon="reload" @button-click="onClick">
    {{ t("reset") }}
  </BuAtomLink>
</template>

<script>
export default {
  emits: ["reset"],

  computed: {
    shouldShow() {
      return Object.keys(this.$store.state.search.result.data).length > 0;
    },
  },

  methods: {
    t(key, options) {
      const prefix = "connected.search";
      return this.$t(`${prefix}.${key}`, options);
    },
    onClick() {
      this.$store.commit("detail/reset");
      this.$store.commit("global/setIsContentMuted", false);
      this.$store.commit("search/reset", true);
      this.$emit("reset");
    },
  },
};
</script>
