import { getComparison, getSearchResults } from "../../../api";

/**
 * Load the compare list
 */
export const load = async ({ commit, state }) => {
  const { skus } = state;
  if (skus.length === 0) {
    return;
  }

  commit("setIsLoading", true);

  try {
    const { data } = await getComparison(skus);
    commit("setData", { data });
  } catch (e) {
    commit("setData", { error: e.format(), data: {} });
  }

  commit("setIsLoading", false);
};

export const addSkuToComparison = async ({ commit, dispatch }, sku) => {
  // check if sku exists
  const { data } = await getSearchResults({
    additionalFacets: {
      groupCode: "",
      searchTerm: sku,
      searchTermType: "ident",
      showInactive: false,
    },
    filters: {},
    language: "en",
    pagination: { limit: 50, offset: 0 },
    sortBy: {},
  });

  if (data.items.length > 0) {
    // see components/connected/detail/Detail.vue:onCompare
    commit("setSkuInComparison", {
      sku: sku,
    });
    await dispatch("load");
    return true;
  } else {
    return false;
  }
};
