<template>
  <div :class="$style.root">
    <BuMoleculePopup
      :id="`dtcc-${uid}`"
      :handler-attributes="{ title: t('title') }"
      :popper-options="popperOptions"
      icon-on-right
      icon="edit"
    >
      <div
        :style="`max-height: ${maxHeight()}px; max-width: ${maxWidth()}px; overflow: auto;`"
      >
        <div :class="$style.headline">
          <BuAtomHeadline
            :modifier="['copy-xs', 'bold']"
            :text="t('title')"
            tag="span"
          />
        </div>
        <ul :class="$style.list">
          <li
            v-for="{ text, value } in headers"
            :key="value"
            :class="$style['list-item']"
          >
            <BuAtomCheckbox
              :checked="internalVisibleColumns"
              :class="$style['list-item__checkbox']"
              :modifier="['variant']"
              :value="value"
              @update:checked="updateVisibleColumns(value, $event)"
              >{{ text }}</BuAtomCheckbox
            >
          </li>
        </ul>
      </div>
    </BuMoleculePopup>
  </div>
</template>

<script>
import { uuid } from "vue-uuid";
const popperOptions = {
  placement: "bottom-end",
  positionFixed: true,
  modifiers: {
    offset: {
      offset: "0, 10",
    },
    flip: {
      enabled: false,
    },
    preventOverflow: {
      boundariesElement: "scrollParent",
    },
  },
};

export default {
  props: {
    headers: {
      type: Array,
      default: () => [],
    },
    visibleColumns: {
      type: Array,
      default: () => [],
    },
  },
  emits: ["update:visibleColumns"],

  data() {
    return {
      uid: uuid.v4(),
      popperOptions,
      internalVisibleColumns: this.visibleColumns,
    };
  },

  watch: {
    visibleColumns(visibleColumns) {
      this.internalVisibleColumns = visibleColumns;
    },
    internalVisibleColumns(visibleColumns) {
      this.$emit("update:visibleColumns", visibleColumns);
    },
  },

  methods: {
    t(key, options) {
      const prefix = "components.molecules.dataTableColumnChooser";
      return this.$t(`${prefix}.${key}`, options);
    },
    updateVisibleColumns(value, checked) {
      if (checked) {
        return (this.internalVisibleColumns = [
          ...this.internalVisibleColumns,
          value,
        ]);
      }

      this.internalVisibleColumns = this.internalVisibleColumns.filter(
        (col) => col !== value
      );
    },
    maxHeight() {
      const tableWrapper = this.$parent.$refs?.["table-wrapper"];

      if (!tableWrapper) {
        return undefined;
      }
      return tableWrapper.clientHeight - 80;
    },
    maxWidth() {
      const tableWrapper = this.$parent.$refs?.["table-wrapper"];

      if (!tableWrapper) {
        return undefined;
      }
      return tableWrapper.clientWidth - 55;
    },
  },
};
</script>

<style lang="scss" module>
@import "~@webprojects/ui-pattern-library/src/stylesheets/environment";

.root {
  display: flex;
  justify-content: flex-end;
  position: relative;
}

.headline {
  margin-bottom: get-space(s);
  position: sticky;
  top: 0;
  background: get-color(light);
  z-index: 1;
}

.list {
  list-style: none;
  margin: 0;
  padding: 0;
}

.list-item {
  &__checkbox {
    margin-bottom: get-space(s) !important;
  }

  &:last-child &__checkbox {
    margin-bottom: 0 !important;
  }
}
</style>
