<template>
  <button
    :class="$style.root"
    :aria-expanded="`${value}`"
    type="button"
    @click="$emit('update:value', !value)"
  >
    <div v-if="!value" :class="$style.label">
      <BuAtomLink
        icon="down"
        tag="span"
        :class="$style.link"
        :modifier="['icon-right']"
      >
        {{ labels.less }}
      </BuAtomLink>
    </div>
    <div v-else :class="$style.label">
      <BuAtomLink
        icon="up"
        tag="span"
        :class="$style.link"
        :modifier="['icon-right']"
      >
        {{ labels.more }}
      </BuAtomLink>
    </div>
  </button>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    labels: {
      type: Object,
      default: () => ({
        more: "show more",
        less: "show less",
      }),
    },
  },
  emits: ["update:value"],
};
</script>

<style lang="scss" module>
@import "~@webprojects/ui-pattern-library/src/stylesheets/environment";

.root {
  @extend %button-reset;
  border-bottom: 1px get-color(gray-light) solid;
  display: block;
  width: 100%;
  height: 40px;

  &,
  &:hover,
  &:focus,
  &:active {
    background-color: rgba(get-color(gray-light), 0.5);
    background-image: repeating-linear-gradient(
      45deg,
      transparent,
      transparent 1px,
      get-color(light) 1px,
      get-color(light) 6px
    );
  }
}

.label {
  position: relative;
}

.link {
  .root:hover & {
    color: get-color(blue-dark);
  }
}
</style>
