<template>
  <div :class="$style.root">
    <!-- Query yields multiple categories -->
    <MoleculeNoteChoice
      v-if="error.code === 900"
      :choices="error.data.categories"
      :text="t('multipleCategories.note')"
      type="categories"
      @choice="onSearchWithCategory"
    />

    <!-- Query may either be typecode or sku -->
    <MoleculeNoteChoice
      v-else-if="error.code === 901"
      :text="t('typeOrIdent.note')"
      :choices="[
        { id: 'type', title: t('typeOrIdent.type') },
        { id: 'ident', title: t('typeOrIdent.ident') },
      ]"
      @choice="onSearchWithSearchTermType"
    />

    <!-- Query yields too many results -->
    <MoleculeNote
      v-else-if="!error && hasTooManyResults"
      arrow="left"
      :text="t('tooManyResults.note')"
    />

    <!-- No results -->
    <MoleculeNote
      v-else-if="!error && hasNoResults"
      :text="t('noResults.note')"
    />

    <!-- Unknown error -->
    <MoleculeNote v-else :text="t('unknown.note')" />
  </div>
</template>

<script>
import { mapActions } from "vuex";

import MoleculeNote from "../../styleguide/molecules/MoleculeNote.vue";
import MoleculeNoteChoice from "../../styleguide/molecules/MoleculeNoteChoice.vue";

export default {
  components: {
    MoleculeNote,
    MoleculeNoteChoice,
  },

  props: {
    error: {
      type: [Boolean, Object, Error],
      default: false,
    },
    hasNoResults: {
      type: Boolean,
      default: false,
    },
    hasTooManyResults: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    t(key, options) {
      const prefix = "connected.results.error";
      return this.$t(`${prefix}.${key}`, options);
    },
    ...mapActions({
      loadCategories: "categories/load",
    }),
    async onSearchWithCategory(id) {
      await this.loadCategories();
      this.$store.commit("search/setAdditionalFacet", {
        key: "groupCode",
        value: id,
      });
      this.$store.dispatch("search/load", "search");
    },
    onSearchWithSearchTermType(searchTermType) {
      this.$store.commit("search/setAdditionalFacet", {
        key: "searchTermType",
        value: searchTermType,
      });
      this.$store.dispatch("search/load", "search");
    },
  },
};
</script>

<style lang="scss" module>
.root {
  height: 100%;
}
</style>
