<template>
  <div :class="[$style.root, $style[`root--${type}`]]">
    <span :class="$style.label">
      {{ label }}
    </span>
    <BuAtomIconPopup v-if="$slots.default" icon="info" :class="$style.icon">
      <slot />
    </BuAtomIconPopup>
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      default: "default",
      validator: (x) => ["default", "warning"].includes(x),
    },
  },
};
</script>

<style lang="scss" module>
@import "~@webprojects/ui-pattern-library/src/stylesheets/environment";

.root {
  align-items: center;
  display: inline-flex;
  border-radius: 4px;
  padding: 5px get-space(s);

  &--default {
    background: get-color(gray-light);
    color: get-color(gray-dark);
  }

  &--warning {
    background: get-color(warning-light);
    color: get-color(gray-dark);
  }
}

.label {
  @include text-type-meta;
  line-height: 20px;
  white-space: nowrap;
}

.icon {
  margin-left: get-space(xs);
}
</style>
