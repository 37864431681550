<template>
  <AccordionItem v-slot="{ isExpanded }" :uuid="filterGroup.name">
    <AccordionItemHeading>
      <AccordionItemButton
        :class="[
          $style['heading'],
          { [$style['heading--is-expanded']]: isExpanded },
        ]"
      >
        <BuAtomSvgSprite id="up" :class="$style['heading__icon']" />
        <span :class="$style['heading__title']">{{ filterGroup.name }}</span>
        <span
          v-if="!isExpanded && filterCount > 0"
          :class="$style['heading__count']"
          >{{ filterCount }}</span
        >
      </AccordionItemButton>
    </AccordionItemHeading>
    <AccordionItemPanel>
      <slot />
    </AccordionItemPanel>
  </AccordionItem>
</template>

<script>
import AccordionItem from "./AccordionItem.vue";
import AccordionItemHeading from "./AccordionItemHeading.vue";
import AccordionItemButton from "./AccordionItemButton.vue";
import AccordionItemPanel from "./AccordionItemPanel.vue";

export default {
  components: {AccordionItemPanel, AccordionItemButton, AccordionItemHeading, AccordionItem},
  props: {
    filterGroup: {
      type: Object,
      default: () => {},
    },
    filterCount: {
      type: Number,
      default: 0,
    },
  },
};
</script>

<style lang="scss" module>
@import "~@webprojects/ui-pattern-library/src/stylesheets/environment";

.heading {
  @extend %button-reset;
  @include text-type-copy-small-extended;
  display: flex;
  padding: get-space(m);
  text-align: left;
  width: 100%;

  &,
  &:active,
  &:focus {
    background-color: get-color(blue-secondary);
    color: get-color(blue-dark);
  }

  &:hover {
    background-color: get-color(hover-blue-secondary);
    color: get-color(hover-blue-dark);
  }

  &__icon {
    margin-right: get-space(s);
    transform: rotate(180deg);
    transition: all 0.2s ease;
  }

  &--is-expanded &__icon {
    transform: rotate(0);
  }

  &__title {
    margin: 2px 0;
  }

  &__count {
    @include text-type-meta-bold;
    background: get-color(warning-light);
    border-radius: 10px;
    margin-left: get-space(s);
    padding: 2px get-space(xs);
    text-align: center;
  }
}
</style>
