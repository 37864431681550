<template>
  <BuMoleculeHintBox #content v-bind="$attrs" hide-close-btn>
    <div :class="$style.inner">
      <div :class="$style.content">
        <h4 v-if="headline" :class="$style.headline">{{ headline }}</h4>
        <p v-if="text" :class="$style.copy">
          {{ text }}
          <slot />
        </p>
      </div>
      <div v-if="$slots.actions">
        <slot name="actions" />
      </div>
    </div>
  </BuMoleculeHintBox>
</template>

<script>
export default {
  inheritAttrs: false,

  props: {
    headline: {
      type: String,
      default: null,
    },
    text: {
      type: String,
      default: null,
    },
  },
};
</script>

<style lang="scss" module>
@import "~@webprojects/ui-pattern-library/src/stylesheets/environment";

.inner {
  align-items: center;
  display: flex;
}

.content {
  flex: 1;
}

.headline {
  @include text-type-copy-small-extended;
}

.copy {
  @include text-type-copy-small;
}

.headline,
.copy {
  line-height: 1.75;
}
</style>
