<template>
  <component
    :is="tag"
    ref="parent"
    :class="[
      $style.root,
      {
        [$style['is-vertical']]: direction == 'vertical',
      },
    ]"
  >
    <div><slot name="a"></slot></div>
    <div><slot name="b"></slot></div>
  </component>
</template>

<script>
/**
 * @file General-Purpose horizontal/vertical draggable splitter layout
 */
import Split from "split.js";
import { pick } from "../../../lib/helper";

export default {
  props: {
    sizes: {
      type: Array,
      default: () => null,
    },
    minSize: {
      type: [Array, Number],
      default: 10,
    },
    expandToMin: {
      type: Boolean,
      default: false,
    },
    gutterSize: {
      type: Number,
      default: 10,
    },
    gutterAlign: {
      type: String,
      default: "center",
    },
    snapOffset: {
      type: Number,
      default: 30,
    },
    dragInterval: {
      type: Number,
      default: 1,
    },
    direction: {
      type: String,
      default: "horizontal",
    },
    cursor: {
      type: String,
      default: null,
    },
    tag: {
      type: String,
      default: "div",
    },
  },

  data() {
    return {
      currentSizes: this.sizes,
    };
  },

  mounted() {
    this.mountSplit();
  },

  updated() {
    // TODO only remount the split if props have actually changed
    // this.mountSplit();
  },

  beforeUnmount() {
    this.unmountSplit();
  },

  methods: {
    mountSplit() {
      if (this.split) {
        this.unmountSplit();
      }

      const options = pick(this.$props, [
        "minSize",
        "expandToMin",
        "gutterSize",
        "gutterAlign",
        "snapOffset",
        "dragInterval",
        "direction",
        "cursor",
      ]);

      this.split = new Split(this.$refs.parent.children, {
        ...options,

        // custom gutter element for CSS module support
        gutter: (index, direction) => {
          const gutter = document.createElement("div");
          gutter.className = `${this.$style.gutter} ${
            this.$style[`gutter-${direction}`]
          }`;
          return gutter;
        },

        // save and restore sizes so re-creating the split.js instance does not
        // affect the user's custom sizing
        sizes: this.currentSizes,
        onDragEnd: (sizes) => {
          this.currentSizes = sizes;
        },
      });
    },

    unmountSplit() {
      this.split.destroy();
      delete this.split;
    },
  },
};
</script>

<style lang="scss" module>
@import "~@webprojects/ui-pattern-library/src/stylesheets/environment";

.root {
  -webkit-overflow-scrolling: touch;
  display: flex;
  overflow: auto;
}

.is-vertical {
  flex-direction: column;
}

.gutter {
  background-repeat: no-repeat;
  background-position: 50%;
  background-color: rgba(get-color(gray-light), 0.5);
  margin: 1px;

  &:hover {
    background-color: #eee;
  }

  &.gutter-horizontal {
    background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAAeCAYAAADkftS9AAAAIklEQVQoU2M4c+bMfxAGAgYYmwGrIIiDjrELjpo5aiZeMwF+yNnOs5KSvgAAAABJRU5ErkJggg==");
    cursor: col-resize;
  }

  &.gutter-vertical {
    background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAFAQMAAABo7865AAAABlBMVEVHcEzMzMzyAv2sAAAAAXRSTlMAQObYZgAAABBJREFUeF5jOAMEEAIEEFwAn3kMwcB6I2AAAAAASUVORK5CYII=");
    cursor: row-resize;
  }
}
</style>
