<template>
  <div>
    <Spy @intersecting="onSpyIntersecting" />

    <MoleculeTableLoading v-if="!isActivated || isLoading" />
    <BuAtomCopy
      v-else-if="error && error.code === 404"
      :modifier="['small']"
      :text="t('noResults')"
      tag="p"
    />
    <MoleculeError
      v-else-if="error"
      :error="error"
      :class="$style.error"
      @retry="onRetry"
    />
    <MoleculeTable
      v-else
      v-bind="parts"
      :id="`bom-table-${uid}`"
      :modifier="['autowidth']"
      :widths="['50', '120', '75']"
    />
  </div>
</template>

<script>
import { uuid } from "vue-uuid";
import { mapState } from "vuex";

import MoleculeError from "../../styleguide/molecules/MoleculeError.vue";
import MoleculeTable from "../../styleguide/molecules/MoleculeTable.vue";
import MoleculeTableLoading from "../../styleguide/molecules/MoleculeTableLoading.vue";

import Spy from "../../../lib/Spy.vue";

export default {
  components: {
    MoleculeError,
    MoleculeTable,
    MoleculeTableLoading,

    Spy,
  },

  data() {
    return {
      /**
       * Whether the parts list was scrolled into view at least once
       * @type {Boolean}
       */
      isActivated: false,
      uid: uuid.v4(),
    };
  },

  computed: {
    ...mapState({
      error: (state) => state.detail.parts.error,
      isLoading: (state) => state.detail.parts.isLoading,
      parts: (state) => state.detail.parts.data,
    }),
  },

  watch: {
    isLoading: function (newValue, oldValue) {
      if (oldValue && !newValue) {
        this.isActivated = true;
      }
    },
  },

  methods: {
    t(key, options) {
      const prefix = "connected.detail.parts";
      return this.$t(`${prefix}.${key}`, options);
    },
    onRetry() {
      this.$store.dispatch("detail/loadParts");
    },

    /**
     * Scroll spy event handler
     * @returns {void}
     */
    onSpyIntersecting(isIntersecting) {
      if (!isIntersecting || this.isActivated) {
        return;
      }

      this.isActivated = true;
      this.$store.dispatch("detail/loadParts");
    },
  },
};
</script>

<style lang="scss" module>
.error {
  padding: 0;
}
</style>
