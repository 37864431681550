<template>
  <component :is="tag" data-accordion-component="Accordion">
    <slot />
  </component>
</template>

<script>
export default {
  provide() {
    return {
      allowMultipleExpanded: this.allowMultipleExpanded,
      allowZeroExpanded: this.allowZeroExpanded,
      isExpanded: this.isExpanded,
      onExpand: this.onExpand,
      onExpandFirst: this.onExpandFirst
    };
  },

  model: {
    prop: "preExpanded",
    event: "update:preExpanded"
  },

  props: {
    tag: {
      type: String,
      default: "div"
    },
    allowMultipleExpanded: {
      type: Boolean,
      default: false
    },
    allowZeroExpanded: {
      type: Boolean,
      default: false
    },
    preExpanded: {
      type: Array,
      default: () => []
    }
  },

  emits: ['update:preExpanded'],

  data() {
    return {
      expanded: this.preExpanded
    };
  },

  watch: {
    preExpanded(expanded) {
      this.expanded = expanded;
    }
  },

  methods: {
    isExpanded(uuid) {
      return this.expanded.indexOf(uuid) !== -1;
    },
    onExpand(uuid) {
      if (this.expanded.indexOf(uuid) !== -1) {
        if (this.expanded.length > 1 || this.allowZeroExpanded) {
          this.expanded = this.expanded.filter(
            expandedUuid => expandedUuid !== uuid
          );
        }
      } else {
        if (this.allowMultipleExpanded) {
          this.expanded.push(uuid);
        } else {
          this.expanded = [uuid];
        }
      }

      this.$emit("update:preExpanded", this.expanded);
    },
    onExpandFirst(uuid) {
      if (!this.allowZeroExpanded && this.expanded.length === 0) {
        this.onExpand(uuid);
      }
    }
  },
};
</script>
