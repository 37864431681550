<template>
  <component
    :is="tag"
    v-show="shouldShow"
    :id="id"
    v-click-outside="onClickOutside"
    :aria-labelledby="ariaLabelledby"
    role="region"
  >
    <slot v-if="shouldShow" />
  </component>
</template>

<script>
import { vOnClickOutside } from "@vueuse/components";

export default {
  directives: {
    ClickOutside: vOnClickOutside
  },

  inject: ["uuid", "isExpanded"],

  props: {
    tag: {
      type: String,
      default: "div"
    }
  },

  emits: ["click-outside"],

  computed: {
    id() {
      return `accordion__panel-${this.uuid}`;
    },
    ariaLabelledby() {
      return `accordion__button-${this.uuid}`;
    },
    shouldShow() {
      return this.isExpanded(this.uuid);
    }
  },

  methods: {
    onClickOutside() {
      this.$emit("click-outside");
    }
  }
};
</script>
