<template>
  <footer v-if="!isLoading && can('show:customnumber')" :class="$style.footer">
    <template v-if="can('show:customnumber') && customerNumber">
      <BuAtomCopy
        :class="$style['footer-item']"
        :text="`${t('customerNumber')}: ${customerNumber}`"
        :modifier="['smaller']"
      />
      <BuAtomCopy
        :class="$style['footer-item']"
        :text="`${t('customerRole')}: ${customerRole}`"
        :modifier="['smaller']"
      />
      <BuAtomLink
        :class="$style['footer-item']"
        :label="t('siteInfo')"
        target="_blank"
        href="https://country.burkert.com/?url=global-site-info"
        :modifier="['small']"
      />
      <BuAtomLink
        :class="$style['footer-item']"
        target="_blank"
        :label="t('dataPrivacy')"
        href="https://country.burkert.com/?url=global-data-privacy"
        :modifier="['small']"
      />
      <!-- href="/de/customer/logout" -->

      <!--
        https://login.burkert.com/auth/realms/master/protocol/openid-connect/auth?scope=email&state=cbc1cbc92348bb3cd31d5555df0a3bdd&response_type=code&approval_prompt=auto&redirect_uri=https%3A%2F%2Fwww.buerkert.de%2Fde%2Fkeycloak%2Fcheck&client_id=webshop

http://keycloak-test.local.burkert.com:8080/auth/realms/master/protocol/openid-connect/auth?scope=email&state=13d6915579e8438db54dc3b2165866c8&response_type=code&approval_prompt=auto&redirect_uri=https%3A%2F%2Fextern-productfinder.local.burkert.com%2Fapi%2Fsso_login&client_id=webshop

http://keycloak-test.local.burkert.com:8080/auth/realms/master/protocol/openid-connect/auth?scope=email&state=b6b8aeaa1001f3c18869e3088552c065&response_type=code&approval_prompt=auto&redirect_uri=https%3A%2F%2Fextern-productfinder.local.burkert.com%2Fapi%2Fsso_login&client_id=webshop

https://login.burkert.com/auth/realms/master/protocol/openid-connect/auth?scope=email&state=13d6915579e8438db54dc3b2165866c8&response_type=code&approval_prompt=auto&redirect_uri=https%3A%2F%2Fextern-productfinder.local.burkert.com%2Fapi%2Fsso_login&client_id=webshop
        href="http://keycloak-test.local.burkert.com:8080/auth/realms/master/protocol/openid-connect/auth?scope=email&state=b6b8aeaa1001f3c18869e3088552c065&response_type=code&approval_prompt=auto&redirect_uri=https%3A%2F%2Fextern-productfinder.local.burkert.com%2Fapi%2Fsso_login&client_id=webshop"


-->
      <BuAtomLink
        ref="logout"
        :class="$style['footer-item']"
        label="logout"
        :modifier="['small']"
        @button-click="onLogout()"
      />
    </template>
  </footer>
</template>

<script>
import { mapState } from "vuex";
import mixins from "../../../lib/GlobalMixins";

export default {
  mixins: [mixins],
  data() {
    return {
      x: "1",
    };
  },
  computed: {
    ...mapState({
      isLoading: (state) => state.detail.data.isLoading,
      customerNumber: (state) => state.global.user.data.customerNumber,
      role: (state) => state.global.user.data.role,
      error: (state) => state.global.user.error,
    }),
    customerRole() {
      return this.t(`roles.${this.role}`);
    },
  },
  methods: {
    t(key, options) {
      const prefix = "connected.footer";
      return this.$t(`${prefix}.${key}`, options);
    },
    onLogout() {
      this.$store.dispatch("global/logoutUser");
      window.sessionStorage.removeItem("token");
      window.sessionStorage.clear();
      window.location.href = "/";
    },
  },
};
</script>

<style lang="scss" module>
@import "~@webprojects/ui-pattern-library/src/stylesheets/environment";

.footer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -1px;
  position: relative;
  z-index: 1;
  border-top: 1px solid white;
  background: get-color(blue-light);

  text-align: center;
  height: get-space(m2);

  &-item {
    padding: 0 get-space(s) !important;
    color: get-color(gray-dark);
    display: block !important;
  }

  &-item + &-item {
    position: relative;

    &:before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      display: block;
      width: 1px;
      height: 15px;
      background: get-color(gray-light);
    }
  }
}
</style>
