import { apiEndpoints } from "../../settings";
import apiCall from "../apiCall";

/**
 * Get single article with parts
 * @param {String} sku - Article SKU
 * @returns {Promise} Promise that resolves with an article or rejects with an ApiError
 */
export const getMiner = async (sku, quantity, customerNr, segment, salesOrg) =>
  apiCall(
    apiEndpoints.miner,
    {
      sku,
      quantity,
      customerNr,
      segment,
      salesOrg,
    },
    "POST"
  );
