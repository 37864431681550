<template>
  <table v-mousetrap="['r']" :class="$style.root" @mousetrap="onMousetrap">
    <thead v-roving-tabindex-container.horizontal>
      <tr :class="$style['thead-tr']">
        <th :class="$style.th">
          <button
            v-if="showSelect"
            v-show="selected.length > 1"
            :class="$style['reset-selection-btn']"
            :title="t('unselectAll')"
            type="button"
            @click.prevent="onResetSelection"
          />
          <button
            v-if="showSelect"
            v-show="selected.length <= 1"
            ref="selectAllItems"
            :class="$style['all-selection-btn']"
            :title="t('select.all.products')"
            @click="selectAllItems"
          />
        </th>
        <AtomDataTableHeaderCell
          v-for="th in headers"
          v-bind="th"
          :key="th.value"
          v-roving-tabindex="!!th.sortable"
          :class="$style.th"
          :sort-by="sortBy[th.value]"
          @update:sort-by="(sortBy) => onUpdateSortBy(th.value, sortBy)"
        />
      </tr>
    </thead>
    <tbody v-roving-tabindex-container>
      <tr
        v-for="({ values, product }, i) in computedItems"
        :key="values[itemKey]"
        v-roving-tabindex
        :class="[
          $style['tbody-tr'],
          {
            [$style['tbody-tr--active']]: activeItem === values[itemKey],
            [$style['tbody-tr--selected']]: selected.includes(values[itemKey]),
            [$style['tbody-tr--unavailable']]: product && !product.isAvailable,
            [$style['tbody-tr--discontinued']]:
              product && product.isDiscontinued,
          },
        ]"
        @keydown="onRowKeydown($event, values)"
      >
        <td :class="[$style.td, $style['td--actions']]">
          <div>
            <BuAtomCheckbox
              v-if="showSelect"
              :attributes="[{ key: 'tabindex', value: '-1' }]"
              :checked="selected.includes(values[itemKey])"
              :class="$style.checkbox"
              :modifier="['variant']"
              :title="t('select')"
              @input="(checked) => onSelect(values[itemKey], checked)"
            />
            <BuAtomIconPopup
              v-if="product && !product.isAvailable"
              :class="$style['icon-popup']"
              icon="info"
            >
              {{
                product.popup
                  ? product.popup
                  : t("productNotAvailable", { status: values.status })
              }}
            </BuAtomIconPopup>
          </div>
        </td>
        <td
          v-for="([header, cell], j) in getCells(values)"
          :key="`${cell}-${i}-${j}`"
          :class="[$style.td, { [$style['is-key-attr']]: header.keyAttr }]"
          @click="$emit('rowClick', values)"
        >
          {{ cell }}
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
/**
 * @file WAI-ARIA 3.21 Table https://www.w3.org/TR/wai-aria-practices/#table
 */
import AtomDataTableHeaderCell from "../atoms/AtomDataTableHeaderCell.vue";

export default {
  components: {
    AtomDataTableHeaderCell,
  },

  model: {
    prop: "selected",
    event: "select",
  },

  props: {
    headers: {
      type: Array,
      required: true,
    },
    items: {
      type: Array,
      default: () => [],
    },
    itemKey: {
      type: String,
      default: "id",
    },
    activeItem: {
      type: String,
      default: null,
    },
    multiSort: {
      type: Boolean,
      default: false,
    },
    sortBy: {
      type: Object,
      default: () => ({}),
    },
    showSelect: {
      type: Boolean,
      default: false,
    },
    selected: {
      type: Array,
      default: () => [],
    },
  },
  emits: ["update:selected", "update:sortBy", "rowClick"],

  data() {
    return {
      internalSelected: [],
      checkedProduct: "checkedProduct",
      select: [],
    };
  },

  computed: {
    /**
     * API 1.0 compatibility wrapper as it has not used the "values" property
     */
    computedItems() {
      return this.items.map((item) =>
        ({}.hasOwnProperty.call(item, "values") ? item : { values: item })
      );
    },
  },

  methods: {
    t(key, options) {
      const prefix = "components.organisms.dataTable";
      return this.$t(`${prefix}.${key}`, options);
    },
    getCells(row) {
      return this.headers.map((th) => [th, row[th.value]]);
    },

    /**
     * Event handler for when all row checkboxes get resetted
     */
    onResetSelection() {
      this.$emit("update:selected", []);
    },

    /**
     * Event handler for table row checkbox selection
     * @param {string} value - Checkbox value
     * @param {boolean} checked - Whether the checkbox got checked
     */
    onSelect(value, checked) {
      const selected = checked
        ? [...this.selected, value]
        : this.selected.filter((x) => x !== value);

      this.$emit("update:selected", selected);
    },

    /**
     * Event handler to select all checkboxes in compare products
     */
    selectAllItems() {
      //const select = [];
      for (const item of this.items) {
        this.select.push(item.values.sku);
      }
      this.$emit("update:selected", this.select);
    },

    /**
     * Event handler for table column header clicks
     * @param {string} key - Table column ID
     * @param {string} value - Sort value, either 'asc', 'desc' or none
     */
    onUpdateSortBy(key, value) {
      let next = this.multiSort ? Object.freeze(this.sortBy) : {};
      next[key] = value;
      if (!value) {
        delete next[key];
      }

      this.$emit("update:sortBy", next);
    },

    onRowKeydown(ev, row) {
      switch (ev.key) {
        case "Enter": {
          this.$emit("rowClick", row);
          break;
        }
        case " ": {
          ev.preventDefault();
          const value = row[this.itemKey];
          this.onSelect(value, this.selected.indexOf(value) === -1);
          break;
        }
        default: {
          break;
        }
      }
    },

    onMousetrap() {
      this.$el.querySelector('tbody tr[tabindex="0"]').focus();
    },
  },
};
</script>

<style lang="scss" module>
@import "~@webprojects/ui-pattern-library/src/stylesheets/environment";

.root {
  width: 100%;
}

.th,
.td {
  padding: 5px;
  white-space: nowrap;
}

.reset-selection-btn {
  @extend %button-reset;
  border-radius: 4px;
  border: 1px get-color(gray-dark) solid;
  display: block;
  height: 20px;
  position: relative;
  width: 20px;

  &:after {
    content: "";
    background: get-color(gray-dark);
    height: 2px;
    left: 5px;
    position: absolute;
    top: 8px;
    width: 8px;
  }
}
.all-selection-btn {
  @extend %button-reset;
  border-radius: 4px;
  border: 1px get-color(gray-dark) solid;
  display: block;
  height: 20px;
  position: relative;
  width: 20px;

  text-align: center;
  &:after {
    content: "+";
    display: block;
    position: absolute;
    padding: 5px;
    top: -8px;
  }
}
.checkbox {
  margin: 0 !important;
}

.icon-popup {
  margin-left: get-space(s);
  white-space: normal;

  svg {
    display: block;
  }
}

.td--actions {
  width: 20px;

  div {
    display: flex;
  }
}

.td.is-key-attr + .td.is-key-attr {
  position: relative;

  &:before {
    content: "";
    position: absolute;
    top: 50%;
    left: calc(-50% + #{2 * 5px});
    width: 5px;
    border-bottom: 1px #979797 solid;
  }
}

.thead-tr {
  @include text-type-copy-small-bold;

  th {
    background: get-color(light);
    position: sticky;
    top: 0;
    z-index: 1;
  }
}

.tbody-tr {
  @include text-type-copy-small;

  [data-whatintent="mouse"] &,
  [data-whatintent="touch"] & {
    outline: 0;
  }

  &:hover,
  &:focus {
    .td:not(.td--actions) {
      @include text-type-copy-small-bold;
      color: get-color(blue-dark);
      cursor: pointer;
    }
  }

  &--active {
    background: get-color(blue-light);
  }

  &--selected {
    color: get-color(blue-dark);
  }

  &--unavailable {
    .td:not(.td--actions) {
      opacity: 0.5;
    }
  }
}
</style>
