<template>
  <div>
    <div
      v-if="isSearchable"
      :class="[$style.search, { [$style['search--is-sticky']]: isSticky }]"
    >
      <AtomFacetFilterSearchInput
        v-model="query"
        :label="t('placeholder')"
        :placeholder="t('placeholder')"
        @keydown="onKeyDown"
      />
    </div>
    <Spy @intersecting="onSpyIntersecting" />
    <div ref="filter">
      <p v-if="noResults" :class="$style['no-results']">
        {{ t("noResults") }}
      </p>
      <slot
        v-else
        :filtered-data="filteredData"
        :selected-option="selectedOption"
      />
    </div>
  </div>
</template>

<script>
import AutosuggestSuffixTree from "../../../lib/AutosuggestSuffixTree";
import Spy from "../../../lib/Spy.vue";

import AtomFacetFilterSearchInput from "../atoms/AtomFacetFilterSearchInput.vue";

/**
 * Object key within `data.options` that gets searched
 * @type {String}
 */
const SEARCH_KEY = "title";

export default {
  components: {
    Spy,
    AtomFacetFilterSearchInput,
  },

  props: {
    /**
     * Current filter data
     */
    data: {
      type: Object,
      required: true,
    },

    /**
     * Whether the current filter can be searched
     */
    isSearchable: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      /**
       * Index of the currently selected option
       * @type {Number}
       */
      internalSelectedOption: 0,

      /**
       * Whether the search input is currently sticky
       * @type {Boolean}
       */
      isSticky: false,

      /**
       * Search input value
       * @type {String}
       */
      query: "",
    };
  },

  computed: {
    /**
     * Get `data` with options filtered according to `searchInput`
     * @returns {Object} Filtered data
     */
    filteredData() {
      if (!this.isSearchable || this.query.trim().length === 0) {
        return this.data;
      }

      const options = this.tree.getMatches(this.query);
      return {
        ...this.data,
        options,
      };
    },

    /**
     * Get whether there are no results
     * @returns {Boolean} Whether there are no results
     */
    noResults() {
      return this.filteredData.options.length === 0;
    },

    /**
     * Get and set the index of the selected option while staying within the limits
     */
    selectedOption: {
      get() {
        return this.isSearchable ? this.internalSelectedOption : -1;
      },
      set(idx) {
        // @see https://web.archive.org/web/20090717035140if_/javascript.about.com/od/problemsolving/a/modulobug.htm
        const { length } = this.filteredData.options;
        this.internalSelectedOption = ((idx % length) + length) % length;

        this.$nextTick(() => {
          const $selected = this.$refs.filter.querySelector("[aria-selected]");
          if ($selected) {
            $selected.scrollIntoView({
              block: "center",
            });
          }
        });
      },
    },

    /**
     * Get a tree for the options
     * @returns {AutosuggestSuffixTree} Fresh tree used for options searching
     */
    tree() {
      return new AutosuggestSuffixTree(this.data.options, SEARCH_KEY);
    },
  },

  methods: {
    t(key, options) {
      const prefix = "components.molecules.facetFilterGroupItemSearch";
      return this.$t(`${prefix}.${key}`, options);
    },
    /**
     * Scroll spy event handler
     * @returns {void}
     */
    onSpyIntersecting(isIntersecting) {
      this.isSticky = !isIntersecting;
    },

    /**
     * Search keydown event handler
     * @param {Event} ev - Event
     * @returns {void}
     */
    onKeyDown(ev) {
      switch (ev.key) {
        case "ArrowUp": {
          ev.preventDefault();
          this.selectedOption -= 1;
          return;
        }
        case "ArrowDown": {
          ev.preventDefault();
          this.selectedOption += 1;
          return;
        }
        case "Enter": {
          ev.preventDefault();

          if (this.noResults) {
            // do nothing when there are no results
            return;
          }

          const $cur = this.$refs.filter.querySelector("[aria-selected] input");
          $cur.click();

          return;
        }
        default: {
          return;
        }
      }
    },
  },
};
</script>

<style lang="scss" module>
@import "~@webprojects/ui-pattern-library/src/stylesheets/environment";

.search {
  background: get-color(blue-light);
  border-bottom: 1px transparent solid;
  margin-bottom: -1 * get-space(s);
  padding: get-space(m) get-space(m) get-space(s) get-space(m);
  position: sticky;
  top: 0;
  z-index: 1;

  &--is-sticky {
    border-color: get-color(light);
  }
}

.no-results {
  @include text-type-copy-small;
  margin: get-space(m);
}
</style>
