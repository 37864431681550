<template>
  <div :class="$style.root">
    <label :class="$style.label" :for="id">{{ label }}</label>
    <input
      v-bind="$attrs"
      :id="id"
      :class="$style.input"
      :type="type"
      :value="modelValue"
      @input="(ev) => $emit('update:modelValue', ev.target.value)"
      @keydown="$emit('keydown', $event)"
    />
    <BuAtomSvgSprite id="search" :class="$style.icon" />
  </div>
</template>

<script>
import { uuid } from "vue-uuid";

export default {
  inheritAttrs: false,

  props: {
    id: {
      type: String,
      default() {
        // eslint-disable-next-line no-underscore-dangle
        return `a-ff-search-${uuid.v4()}`;
      },
    },
    label: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      default: "search",
    },
    modelValue: {
      type: String,
      default: "",
    },
  },
  emits: ["update:modelValue", "keydown"],
};
</script>

<style lang="scss" module>
@import "~@webprojects/ui-pattern-library/src/stylesheets/environment";

.root {
  position: relative;
}

.label {
  @extend %visually-hidden;
}

.input {
  @include text-type-copy-small;
  appearance: none;
  border-radius: 4px;
  border: 1px solid get-color(gray-light);
  color: get-color(gray-dark);
  height: 30px;
  line-height: 30px;
  padding: 0 get-space(l) 0 get-space(s);
  width: 100%;

  [data-whatintent="mouse"] &,
  [data-whatintent="touch"] & {
    outline: 0;
  }
}

.icon {
  color: get-color(gray-dark);
  pointer-events: none;
  position: absolute;
  right: get-space(s);
  top: 5px;
}
</style>
