<template>
  <!--test-->
  <TemplateApp>
    <template v-if="isInitialized" #header>
      <OrganismHeader>
        <template #search>
          <Search />
        </template>
        <template #actions>
          <MoleculePopupHelp />
          <MoleculePopupLocator />
          <!-- Has to be declared to trigger handler for cookie dialogue over cookie_consent.js (Backend) again after first / initial setup -->
          <div id="oil-preference-center" style="display: none"></div>
          <span class=""></span>
        </template>
      </OrganismHeader>
    </template>
    <template #main>
      <Home v-if="isInitialized" />
      <main v-else :class="$style.loader">
        <div v-if="hasError" :class="$style['loader__error']">
          <div :class="$style['loader__error-hl']">
            <BuAtomHeadline :text="error.message" />
          </div>
          <BuAtomButton
            v-if="[401, 403].includes(error.code)"
            :modifier="['light', 'small']"
            tag="a"
            text="login"
            :href="error.url"
            :force-emit="true"
            @button-click="onLogin"
          />
          <BuAtomButton
            v-else
            :modifier="['light', 'small']"
            text="reload"
            @button-click="onReload"
          />
        </div>
        <div v-else :class="$style['loader__circle']"></div>
      </main>

      <OrganismModalKeyboardShortcuts
        v-if="isInitialized"
        v-model:is-opened="showKeyboardShortcutsModal"
        @mousetrap="showKeyboardShortcutsModal = true"
      />

      <MoleculeHintboxUpdate
        v-if="isInitialized && hasUpdate"
        @refresh="onRefresh"
      />

      <OrganismModalHintBox />
    </template>

    <template #footer>
      <Footer v-if="isInitialized" />
    </template>

    <div id="#modals"></div>
  </TemplateApp>
</template>

<script>
import { mapState } from "vuex";

/**
 * @file Main entry component
 */
import MoleculeHintboxUpdate from "./styleguide/molecules/MoleculeHintboxUpdate.vue";
import MoleculePopupHelp from "./styleguide/molecules/MoleculePopupHelp.vue";
import MoleculePopupLocator from "./styleguide/molecules/MoleculePopupLocator.vue";
import OrganismHeader from "./styleguide/organisms/OrganismHeader.vue";
import OrganismModalKeyboardShortcuts from "./styleguide/organisms/OrganismModalKeyboardShortcuts.vue";
import OrganismModalHintBox from "./styleguide/organisms/OrganismModalHintBox.vue";
import TemplateApp from "./styleguide/templates/TemplateApp.vue";

import Search from "./connected/search/Search.vue";
import Footer from "./connected/footer/Footer.vue";

import Home from "./views/Home.vue";

export default {
  components: {
    MoleculeHintboxUpdate,
    MoleculePopupHelp,
    MoleculePopupLocator,
    OrganismHeader,
    OrganismModalKeyboardShortcuts,
    OrganismModalHintBox,
    TemplateApp,

    Search,
    Footer,

    Home,
  },

  computed: {
    ...mapState({
      hasError: (state) => state.global.hasError,
      hasUpdate: (state) => state.global.hasUpdate,
      isInitialized: (state) => state.global.isInitialized,
      error: (state) => state.global.user.error,
    }),
    showKeyboardShortcutsModal: {
      get() {
        return this.$store.state.global.showKeyboardShortcutsModal;
      },
      set(showModal) {
        this.$store.commit("global/setShowKeyboardShortcutsModal", showModal);
      },
    },
  },

  async mounted() {
    // Getting / load role before executing further methods
    await this.$store.dispatch("global/loadUser");
  },

  beforeUnmount() {
    this.unwatchLastUpdated();
  },

  methods: {
    /**
     * Event handler for when the user wants to update the PWA
     * @see https://developers.google.com/web/tools/workbox/guides/advanced-recipes#offer_a_page_reload_for_users
     */
    async onRefresh() {
      window.wb.addEventListener("controlling", () => location.reload(true));
      window.wb.messageSW({ type: "SKIP_WAITING" });
    },

    /**
     * Event handler for when the user wants to reload the page
     */
    onReload() {
      location.reload(true);
    },

    onLogin() {
      // remove old token from session storage
      sessionStorage.removeItem("token");
    },
  },
};
</script>

<style lang="scss" module>
@import "~@webprojects/ui-pattern-library/src/stylesheets/environment";

.loader {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;

  background-color: get-color(blue-light);
}

.loader__error {
  text-align: center;
}

.loader__error-hl {
  margin-bottom: get-space(m);
}

.loader__circle {
  $size: 60px;

  display: inline-block;
  width: $size;
  height: $size;

  &:after {
    content: " ";
    display: block;
    width: $size;
    height: $size;
    margin: auto;
    border-radius: 50%;
    border: 4px solid get-color(blue-dark);
    border-color: get-color(blue-dark) transparent get-color(blue-dark)
      transparent;
    animation: lds-dual-ring 1.2s linear infinite;
  }
}
@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
