/**
 * Helper to remove duplicate entries from an Array
 * @param {Array} arr - Input array
 * @returns {Array} `arr` without duplicate entries
 */
const removeDuplicates = (arr) =>
  arr.reduce((acc, cur) => (acc.includes(cur) ? acc : [...acc, cur]), []);

/**
 * Get whether there is comparison data
 * @param {*} state - Vuex state
 * @returns {Boolean} Whether there is comparison data
 */
export const hasData = (state) => Object.keys(state.data.data).length > 0;

/**
 * Get the amount of products in comparison
 * @param {*} state - Vuex state
 * @param {*} getters - Vuex getters
 * @returns {Number} Amount of products in comparison
 */
export const count = (state, getters) =>
  getters.hasData ? state.data.data.items.length : state.skus.length;

/**
 * Get which sections and headers have different items
 * Used to compute "only show differences" sections and items
 * Array keys stay the same, the value is false if a section / header does not
 * has different items
 * @param {*} state - Vuex state
 * @param {*} getters - Vuex getters
 * @returns {Array} Array of arrays where each value corresponds to a section / header and is true or false
 */
export const differentSectionIdx = (state, getters) => {
  if (!getters.hasData) {
    return [];
  }

  const { items, sections } = state.data.data;
  return sections.reduce((acc, section, si) => {
    const headers = section.headers.reduce((hacc, h, hi) => {
      const isIncluded =
        removeDuplicates(items.map((i) => i.values[si][hi])).length > 1;
      return [...hacc, isIncluded];
    }, []);

    return [...acc, headers.some((h) => h) && headers];
  }, []);
};

/**
 * Get sections / headers where the items have different values
 * @param {*} state - Vuex state
 * @param {*} getters - Vuex getters
 * @returns {Object[]} Sections with headers where rows with equal content are omitted
 */
export const onlyDifferentSections = (state, getters) => {
  if (!getters.hasData) {
    return [];
  }

  const { differentSectionIdx } = getters;
  return state.data.data.sections.reduce(
    (acc, section, si) =>
      differentSectionIdx[si]
        ? [
            ...acc,
            {
              ...section,
              headers: section.headers.filter(
                (h, hi) => differentSectionIdx[si][hi]
              ),
            },
          ]
        : acc,
    []
  );
};

/**
 * Get items only with different items
 * @param {*} state - Vuex state
 * @param {*} getters - Vuex getters
 * @returns {Object[]} Items where values of rows with equal content are omitted
 */
export const onlyDifferentItems = (state, getters) => {
  if (!getters.hasData) {
    return [];
  }

  const { differentSectionIdx } = getters;
  return state.data.data.items.map((item) => ({
    ...item,
    values: item.values.reduce(
      (acc, val, si) =>
        differentSectionIdx[si]
          ? [...acc, val.filter((hv, hi) => differentSectionIdx[si][hi])]
          : acc,
      []
    ),
  }));
};

/**
 * Get sections, either all or only those with different rows
 * @param {*} state - Vuex state
 * @param {*} getters - Vuex getters
 * @returns {Object[]} Sections
 */
export const sections = (state, getters) => {
  if (!getters.hasData) {
    return [];
  }

  return state.onlyDifferences
    ? getters.onlyDifferentSections
    : state.data.data.sections;
};

/**
 * Get items, either all or only those with different rows
 * @param {*} state - Vuex state
 * @param {*} getters - Vuex getters
 * @returns {Object[]} Items
 */
export const items = (state, getters) => {
  if (!getters.hasData) {
    return [];
  }

  return state.onlyDifferences
    ? getters.onlyDifferentItems
    : state.data.data.items;
};

/**
 * Get whether the comparison checkboxes are visible
 * @param {*} state - Vuex state
 * @returns {Boolean} Whether the comparison checkboxes are visible
 */
export const checkboxesEnabled = (state) =>
  state.skus.length > 0 || state.checkboxesEnabled;
