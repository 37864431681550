<template>
  <div :class="$style.root">
    <!-- left most column -->
    <MoleculeSearchPopupColumn>
      <MoleculeSearchPopupColumnOptions>
        <AtomSearchPopupColumnOption
          @keydown.up="onEdgeOptionKeyDown"
          @click="$emit('searchVia', 'type')"
        >
          {{ t("viaType") }}
        </AtomSearchPopupColumnOption>
        <AtomSearchPopupColumnOption @click="$emit('searchVia', 'ident')">
          {{ t("viaIdent") }}
        </AtomSearchPopupColumnOption>
        <AtomSearchPopupColumnOption @click="$emit('searchVia', 'devicekey')">
          {{ t("viaDevicekey") }}
        </AtomSearchPopupColumnOption>
        <AtomSearchPopupColumnOption
          v-if="enableKeyConverter"
          @click="$emit('searchVia', 'keyConverter')"
        >
          {{ t("keyConverter") }}
        </AtomSearchPopupColumnOption>
      </MoleculeSearchPopupColumnOptions>

      <BuAtomHr :modifier="['top-m', 'bottom-m']" />

      <MoleculeSearchPopupColumnOptions>
        <!-- dynamic navigatable categories -->
        <template v-if="can('navigate:categories')">
          <AtomSearchPopupColumnOption
            ref="showCategoriesButton"
            :is-active="showCategories"
            @click="onShowCategoriesClick"
            @keydown.down="onEdgeOptionKeyDown"
            @keydown.right="onShowCategoriesKeydown"
          >
            {{ t("productCategory") }}
            <template #icon-after>
              <BuAtomSvgSprite id="forward" />
            </template>
          </AtomSearchPopupColumnOption>
        </template>
        <!-- static category text with web link -->
        <template v-else>
          <BuAtomCopy
            :class="$style.notification"
            :text="t('categories.notification')"
            :modifier="['small']"
          />
          <BuAtomLink
            :class="$style.link"
            :label="t('categories.label')"
            :href="website"
            :modifier="['icon-right']"
            target="_blank"
            icon="forward"
          />
        </template>
      </MoleculeSearchPopupColumnOptions>
    </MoleculeSearchPopupColumn>

    <!-- categories: loading placeholder -->
    <MoleculeSearchPopupColumn v-if="showCategories && categoriesLoading">
      <MoleculeSearchPopupColumnOptions>
        <AtomSearchPopupColumnOptionLoading
          v-for="i in 4"
          :key="i"
          icon-before
        />
      </MoleculeSearchPopupColumnOptions>
    </MoleculeSearchPopupColumn>

    <!-- categories: actual columns -->
    <MoleculeSearchPopupColumn
      v-for="categoryIds in categoriesColumns"
      v-else-if="showCategories && !categoriesLoading"
      ref="categories"
      :key="categoryIds.id"
    >
      <MoleculeSearchPopupColumnOptions>
        <AtomSearchPopupColumnOption
          v-for="categoryId in categoryIds.sub"
          :key="categoryId"
          :is-active="categoriesActive.includes(categoryId)"
          @keydown="(ev) => onCategoryKeydown(ev, categoryId, categoryIds.id)"
          @click="() => onOpenCategory(categoryId, categoryIds.id)"
        >
          <template v-if="categories[categoryId].thumb" #icon-before>
            <img
              :src="categories[categoryId].thumb"
              :alt="categories[categoryId].title"
            />
          </template>
          {{ categories[categoryId].title }}
          <template v-if="categories[categoryId].sub" #icon-after>
            <BuAtomSvgSprite id="forward" />
          </template>
        </AtomSearchPopupColumnOption>
      </MoleculeSearchPopupColumnOptions>
    </MoleculeSearchPopupColumn>
  </div>
</template>

<script>
import { mapActions, mapState, mapMutations } from "vuex";
import mixins from "../../../lib/GlobalMixins";

import AtomSearchPopupColumnOption from "../../styleguide/atoms/AtomSearchPopupColumnOption.vue";
import AtomSearchPopupColumnOptionLoading from "../../styleguide/atoms/AtomSearchPopupColumnOptionLoading.vue";
import MoleculeSearchPopupColumn from "../../styleguide/molecules/MoleculeSearchPopupColumn.vue";
import MoleculeSearchPopupColumnOptions from "../../styleguide/molecules/MoleculeSearchPopupColumnOptions.vue";

export default {
  components: {
    AtomSearchPopupColumnOption,
    AtomSearchPopupColumnOptionLoading,
    MoleculeSearchPopupColumn,
    MoleculeSearchPopupColumnOptions,
  },

  mixins: [mixins],

  props: {
    isExpanded: {
      type: Boolean,
      default: false,
    },
    enableKeyConverter: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["searchVia", "focusInput", "setCategory"],

  data() {
    return {
      showCategories: false,
    };
  },

  computed: {
    ...mapState({
      categories: (state) => state.categories.data.data.entities.categories,
      categoriesActive: (state) => state.categories.active,
      categoriesLoading: (state) => state.categories.data.isLoading,
      website: (state) => state.global.user.data.website,
    }),
    categoriesColumns() {
      if (this.showCategories && !this.categoriesLoading) {
        return this.$store.getters["categories/getColumns"];
      }
      return [];
    },
  },

  watch: {
    isExpanded(isExpanded) {
      // reset categories when the popup gets closed
      if (!isExpanded) {
        this.showCategories = false;
        this.setCategoriesActive(["0"]);
      }
    },
    categoriesLoading(categoriesLoading) {
      if (this.showCategories && !categoriesLoading) {
        // focus first category when categories have finished loading
        this.focusLastCategoryColumn();
      }
    },
    showCategories(showCategories) {
      if (showCategories && !this.categoriesLoading) {
        // focus first category when categories got opened without loading
        this.focusLastCategoryColumn();
      }
    },
  },

  methods: {
    t(key, options) {
      const prefix = "connected.search.popup";
      return this.$t(`${prefix}.${key}`, options);
    },

    ...mapMutations({
      setCategoriesActive: "categories/setActive",
    }),
    ...mapActions({
      loadCategories: "categories/load",
    }),

    /**
     * Set focus on last category column
     */
    focusLastCategoryColumn(selector = "button") {
      this.$nextTick(() => {
        this.$refs.categories[this.$refs.categories.length - 1].$el
          .querySelector(selector)
          .focus();
      });
    },

    /**
     * Event handler for arrow keys on category elements
     */
    onCategoryKeydown(ev, id, parentId) {
      switch (ev.key) {
        case "ArrowLeft": {
          // left arrow should move the focus to the preceding column
          ev.preventDefault();
          if (parentId === "0") {
            // within the first column, move focus onto show categories button
            this.showCategories = false;
            this.$refs.showCategoriesButton.$el.querySelector("button").focus();
          } else {
            // within other columns, move focus onto previous column
            this.setCategoriesActive(this.categoriesActive.slice(0, -1));
            this.focusLastCategoryColumn('button[tabindex="0"]');
          }
          break;
        }
        case "ArrowRight": {
          // right arrow should move the focus to the succeeding column
          const category = this.categories[id];
          if (category.sub) {
            ev.preventDefault();
            this.onOpenCategory(id, parentId);
            this.focusLastCategoryColumn();
          }
          break;
        }
        default: {
          break;
        }
      }
    },

    /**
     * Event handler for ArrowUp/ArrowDown keydown on the left-most column's first/last option
     */
    onEdgeOptionKeyDown(ev) {
      ev.stopImmediatePropagation();
      this.$emit("focusInput");
    },

    /**
     * Event handler for a click on a category
     */
    onOpenCategory(id, parentId) {
      const category = this.categories[id];
      if (!category.sub) {
        this.$emit("setCategory", id);
        this.setCategoriesActive(["0"]);
        this.showCategories = false;
        return;
      }

      this.setCategoriesActive(
        this.categoriesActive
          .slice(0, this.categoriesActive.indexOf(parentId) + 1)
          .concat([id])
      );

      // the popup may be scrolled so make sure we get to the top
      this.$el.closest(".m-popup__container").scrollTo({ top: 0 });
    },

    /**
     * Event handler for a click on the show categories button
     */
    onShowCategoriesClick(e) {
      e.stopPropagation();
      this.showCategories = !this.showCategories;
      if (this.showCategories) {
        this.loadCategories();
      }
    },

    /**
     * Event handler for ArrowRight keydown on the show categories button
     */
    onShowCategoriesKeydown(ev) {
      ev.stopImmediatePropagation();
      this.showCategories = true;
      this.loadCategories();
    },
  },
};
</script>

<style lang="scss" module>
@import "~@webprojects/ui-pattern-library/src/stylesheets/environment";

.root {
  display: flex;
  height: 100%;
}

.notification {
  margin-bottom: get-space(s);
}
</style>
