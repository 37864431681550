<template>
  <div v-roving-tabindex-container :class="$style.root">
    <slot />
  </div>
</template>

<style lang="scss" module>
@use "~@webprojects/ui-pattern-library/src/stylesheets/environment";

$columnWidth: 260px;

.root {
  flex: 0 0 $columnWidth;
  width: $columnWidth;
  border-left: 1px get-color(gray-light) solid;
  margin-right: get-space(m);
  padding-left: get-space(m);

  &:first-child {
    border: 0;
    padding-left: 0;
  }

  &:last-child {
    margin-right: 0;
  }
}
</style>
