const state = () => ({
  /**
   * Whether the app got into an erroneous state during initialization
   * @type {Boolean}
   */
  hasError: false,

  /**
   * Whether there is a service worker update available and the page needs a refresh
   * @type {boolean}
   */
  hasUpdate: false,

  /**
   * Whether the main app content is muted using a translucent black overlay
   * @type {Boolean}
   */
  isContentMuted: false,

  /**
   * Whether the app is initialized
   * @type {Boolean}
   */
  isInitialized: false,

  /**
   * Whether the keyboard shortcuts help modal is shown
   * @type {Boolean}
   */
  showKeyboardShortcutsModal: false,

  user: {
    /**
     * User API result data
     * @type {Object}
     */
    data: {
      /**
       * User role (sales|external)
       * @type {String}
       */
      role: "external",

      /**
       * User website landing page based on language
       * @type {String}
       */
      website: "https://www.burkert.com/en",
    },

    /**
     * API response error
     * @type {Boolean|Object}
     */
    error: false,

    /**
     * Whether data is currently loading
     * @type {Boolean}
     */
    isLoading: false,

    /**
     * When the API last got called
     * @type {Number}
     */
    lastUpdated: -1,
  },
  /**
   * Caching customer number in rapid miner
   * @type {String}
   */
  minerCustomer: "",

  /**
   * Caching segment in rapid miner
   * @type {String}
   */
  minerSegment: "",

    /**
   * Caching segment list in rapid miner
   * @type {Array}
   */
  minerSegmentList: [],

  /**
   * Hint box data for headline, content and triggering via boolean
   * @type {Object}
   */
  hintBoxModal: {
    headline: "",
    content: "",
    showHintBoxModal: false,
  },
});

export default state;
