<template>
  <OrganismCompareLoading v-if="isLoading" />
  <MoleculeError v-else-if="error" :error="error" @retry="onRetry" />
  <OrganismCompare
    v-else
    v-model:show-differences="showDifferences"
    :items="items"
    :sections="sections"
    :substitute-skus="substituteSkus"
    @open-product="onOpenProduct"
    @remove-product="onRemoveProduct"
  />
</template>

<script>
import { mapState, mapGetters } from "vuex";

import MoleculeError from "../../styleguide/molecules/MoleculeError.vue";
import OrganismCompare from "../../styleguide/organisms/OrganismCompare.vue";
import OrganismCompareLoading from "../../styleguide/organisms/OrganismCompareLoading.vue";

export default {
  components: {
    MoleculeError,
    OrganismCompare,
    OrganismCompareLoading,
  },

  computed: {
    ...mapState({
      error: (state) => state.compare.data.error,
      isLoading: (state) => state.compare.data.isLoading,
      substituteSkus: (state) => state.compare.substitute,
    }),
    ...mapGetters({
      items: "compare/items",
      sections: "compare/sections",
    }),
    showDifferences: {
      get() {
        return this.$store.state.compare.onlyDifferences;
      },
      set(showDifferences) {
        this.$store.commit("compare/setOnlyDifferences", showDifferences);
      },
    },
  },

  methods: {
    onOpenProduct(sku) {
      this.$store.commit("compare/setIsVisible", false);
      this.$store.commit("detail/setSku", sku);
      this.$store.dispatch("detail/load");
    },
    onRemoveProduct(sku) {
      const skus = this.$store.state.compare.skus.filter((x) => x !== sku);
      this.$store.commit("compare/setSkus", skus);
      this.$store.commit("compare/setSubstitute", []);
      this.$store.dispatch("compare/load");
    },
    onRetry() {
      this.$store.dispatch("compare/load");
    },
  },
};
</script>
