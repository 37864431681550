<template>
  <div :class="$style.root" />
</template>

<script>
export default {
  props: {
    root: {
      type: Element,
      default: null,
    },
  },
  emits: ["intersecting"],

  mounted() {
    this.observer = new IntersectionObserver(this.onObserve, {
      root: this.root,
    });
    this.observer.observe(this.$el);
  },

  beforeUnmount() {
    this.observer.disconnect();
  },

  methods: {
    onObserve(ev) {
      this.$emit("intersecting", ev[0].isIntersecting);
    },
  },
};
</script>

<style module>
.root {
  height: 1px;
  margin-bottom: -1px;
}
</style>
