import store from "../store";

window.dataLayer = window.dataLayer || [];

// User role (sales|external)
const userRoles = {
  sales: "sales",
  external: "external",
};

const mixins = {
  methods: {
    /**
     * Global helper of an centralized access control list
     *
     * @param {string} key - control list key
     * @returns {Boolean} is access enabled
     */
    can: (key = "") => {
      // User can toggle interactive product
      if (
        key === "read:inactive" &&
        [userRoles.sales, userRoles.external].includes(
          store.state.global.user.data.role
        )
      )
        return true;

      // User can request custom prices
      if (
        key === "request:customprices" &&
        [userRoles.external].includes(store.state.global.user.data.role)
      )
        return true;

      // User can request custom prices
      if (
        key === "navigate:categories" &&
        [userRoles.sales].includes(store.state.global.user.data.role)
      )
        return true;

      // User can see customer number
      if (
        key === "show:customnumber" &&
        [userRoles.external].includes(store.state.global.user.data.role)
      )
        return true;

      return false;
    },
  },
};

export default mixins;
