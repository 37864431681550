import { apiEndpoints } from "../../settings";
import apiCall from "../apiCall";
import i18n from "../../i18n";

/**
 * Get user specific prices for article
 * @param {String} sku - Article SKU
 * @param {Number} quantity - Article SKU
 * @returns {Promise} Promise that resolves with an article or rejects with an ApiError
 */
export const getPrice = async (sku, quantity) =>
  apiCall(apiEndpoints.price, {
    language: i18n.global.locale.value,
    product: {
      sku,
      quantity,
    },
  });
