<template>
  <div :class="$style['root']">
    <div :class="$style['column']">
      <template v-if="product.customerPrice">
        <!-- Unit Price -->
        <div
          v-if="product.unitPrice && product.unitPrice.netto"
          :class="$style['wrapper']"
        >
          <span :class="$style['label']">
            {{ t("unitPrice.netto") }}
          </span>
          <span :class="$style['value']">
            {{ product.unitPrice.netto.formatted }}
          </span>
        </div>

        <!-- Customer Disount -->
        <div :class="$style['discount']">
          <div
            v-if="
              product.customerPrice &&
              product.customerPrice.discountPercentageFormatted
            "
            :class="[$style['wrapper'], $style['wrapper--has-discount']]"
          >
            <span :class="$style['label']">
              {{ t("customerPrice.discountPercentage") }}
            </span>
            <span :class="$style['value']">
              {{ product.customerPrice.discountPercentageFormatted }}
            </span>
          </div>
          <div
            v-if="
              product.customerPrice && product.customerPrice.discountFormatted
            "
            :class="$style['wrapper']"
          >
            <span :class="$style['label']">
              {{ t("customerPrice.discount") }}
            </span>
            <span :class="$style['value']">
              {{ product.customerPrice.discountFormatted }}
            </span>
          </div>
        </div>

        <!-- Customer Price -->
        <div
          v-if="
            product.customerPrice &&
            product.customerPrice.unitPrice &&
            product.customerPrice.unitPrice.netto
          "
          :class="$style['wrapper']"
        >
          <span :class="$style['label']">
            {{ t("customerPrice.netto") }}
          </span>
          <span
            :class="
              product.customerPrice.customerPriceTotalFormatted &&
              product.customerPrice.componentSurcharge
                ? $style['value']
                : [$style['value'], $style['value--highlighted']]
            "
          >
            {{ product.customerPrice.unitPrice.netto.formatted }}
          </span>
        </div>

        <div
          v-if="
            product.customerPrice.componentSurchargeFormatted &&
            product.customerPrice.componentSurcharge
          "
        >
          <span :class="$style['value']">
            + {{ t("customerPrice.componentSurcharge") }}:
            {{ product.customerPrice.componentSurchargeFormatted }}
          </span>
        </div>

        <div
          v-if="
            product.customerPrice.customerPriceTotalFormatted &&
            product.customerPrice.componentSurcharge
          "
        >
          <span :class="[$style['value'], $style['value--highlighted']]">
            {{ product.customerPrice.customerPriceTotalFormatted }} </span
          ><br />
        </div>

        <BuMoleculeHintBox
          v-if="product.customerPrice.surchargePercentFormatted"
          :modifier="[null]"
          headline=""
          :subline="null"
          :text="
            t('customerPrice.hintboxSurchargePercent', {
              val: product.customerPrice.surchargePercentFormatted,
            })
          "
          hide-close-btn
        />
      </template>
      <template v-else>
        <!-- Unit Price -->
        <div
          v-if="product.unitPrice && product.unitPrice.netto"
          :class="$style['wrapper']"
        >
          <span :class="$style['label']">
            {{ t("unitPrice.netto") }}
          </span>
          <span :class="[$style['value'], $style['value--highlighted']]">
            {{ product.unitPrice.netto.formatted }}
          </span>
        </div>
      </template>

      <!-- NUMBER INPUT -->
      <div :class="$style['quantity']">
        <BuMoleculeNumberInput
          v-if="
            can('request:customprices') &&
            !(!product.showPriceButton && !product.orderable)
          "
          :class="$style['quantity__input']"
          :input-disabled="isLoading"
          :max="product.maxOrderQuantity || 999999999999"
          :min="product.minOrderQuantity"
          :update-value-on-input="true"
          :value="quantity"
          @change="onQuantityChange"
        />
        <div v-if="product.minOrderQuantity" :class="$style['quantity__label']">
          {{ t("minOrderQuantity") }}:
          <strong :class="$style['quantity__value']">
            {{ product.minOrderQuantity }}
          </strong>
        </div>
        <div v-if="product.maxOrderQuantity" :class="$style['quantity__label']">
          {{ t("maxOrderQuantity") }}:
          <strong :class="$style['quantity__value']">
            {{ product.maxOrderQuantity }}
          </strong>
        </div>
        <div v-if="product.packagingUnit" :class="$style['quantity__label']">
          {{ t("packagingUnit") }}:
          <strong :class="$style['quantity__value']">
            {{ product.packagingUnit }}
          </strong>
        </div>
      </div>
    </div>

    <div :class="$style['column']">
      <!-- EXPRESS -->
      <div v-if="product.express" :class="$style['express']">
        <BuAtomSvgSprite id="express" :class="$style['express__icon']" />
        {{ t("express") }}
      </div>

      <BuAtomHr :modifier="['top-m', 'bottom-m']" />

      <!-- Price Valid Dates & Delivery Date -->
      <div v-if="product.unitPriceValidFromFormatted" :class="$style['label']">
        {{ t("unitPriceValidFrom") }}
        {{ product.unitPriceValidFromFormatted }}
      </div>
      <div v-if="product.unitPriceValidUntilFormatted" :class="$style['label']">
        {{ t("unitPriceValidUntil") }}
        {{ product.unitPriceValidUntilFormatted }}
      </div>
      <div v-if="product.deliveryDateFormatted" :class="$style['label']">
        {{ t("deliveryDate") }}
        {{ product.deliveryDateFormatted }}
      </div>
      <MoleculeDiscountRange
        v-if="role !== 'external' && allowedMinerSalesOrg.includes(salesOrg)"
        ref="discountRange"
        :class="$style.discountRange"
        :sku="sku"
        :sales-org="salesOrg"
      />

      <!-- ACTIONS -->
      <BuAtomButtonLoadableSimple
        v-if="can('request:customprices') && product.showPriceButton"
        :class="$style['button']"
        :disabled="submitIsDisabled"
        :is-loading="isLoading"
        :modifier="['small', 'secondary', 'full-width']"
        icon="price-and-availability"
        @button-click="onCustomerPrice"
      >
        <span>
          {{ !product.customerPrice ? t("loadCustomerPrice") : null }}
        </span>
      </BuAtomButtonLoadableSimple>

      <BuAtomHr v-if="canAddToBasket" :modifier="['top-m', 'bottom-m']" />

      <BuAtomButton
        v-if="canAddToBasket"
        :href="addToBasketHref"
        :modifier="['small', 'primary', 'full-width']"
        icon="shopping-cart"
        rel="noopener noreferrer"
        target="_blank"
      >
        <span>{{ t("addToBasket") }}</span>
      </BuAtomButton>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import mixins from "../../../lib/GlobalMixins";
import MoleculeDiscountRange from "./MoleculeDiscountRange.vue";

export default {
  components: { MoleculeDiscountRange },
  mixins: [mixins],

  props: {
    product: {
      type: Object,
      required: true,
    },
    salesOrg: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      submitIsDisabled: false,
      allowedMinerSalesOrg: [
        "4000", // AE
        "5700", // AT
        "2100", // AU
        "2400", // BE
        "0001", // BR
        "2700", // CA 
        "2900", // CH
        "3100", // CN
        "3400", // CZ 
        "0002", // DE
        "3500", // DK 
        "7300", // ES
        "3700", // FR
        "6900", // FI 
        "4200", // IN 
        "4300", // IT
        "4500", // JP
        "4700", // KR 
        "4900", // MY
        "5100",  // NL
        "5300", // NO 
        "5500", // NZ 
        "5900", // PH 
        "6100", // PL 
        "7500", // SE 
        "7100", // SG
        "6500", // TW 
        "3900", // UK
        "8100", // US
        "2000", // UY
        "6700", // ZA
      ],
    };
  },

  computed: {
    ...mapState({
      role: (state) => state.global.user.data.role,
      sku: (state) => state.detail.sku,
      quantity: (state) => state.detail.quantity,
      isLoading: (state) =>
        state.detail.data.isRunningRequest === "loadCustomerPrice",
      addToBasketHref: (state) => {
        const { addToBasketLink } = state.global.user.data;

        if (!addToBasketLink) {
          return null;
        }

        return addToBasketLink
          .replace("%sku%", state.detail.sku)
          .replace("%quantity%", state.detail.quantity);
      },
    }),

    canAddToBasket() {
      return this.product.orderable && this.addToBasketHref;
    },
  },

  watch: {
    isLoading(isLoading) {
      if (isLoading) {
        this.submitIsDisabled = true;
      }
    },
  },

  methods: {
    t(key, options) {
      const prefix = "components.organisms.details.product";
      return this.$t(`${prefix}.${key}`, options);
    },
    onCustomerPrice() {
      this.$store.dispatch("detail/loadCustomerPrice");
    },

    onQuantityChange(quantity) {
      this.submitIsDisabled = false;
      this.$store.commit("detail/setQuantity", Number(quantity));
    },
  },
};
</script>

<style lang="scss" module>
@import "~@webprojects/ui-pattern-library/src/stylesheets/environment";

@media print {
  .discountRange {
    display: none !important;
  }
}
.root {
  margin-top: get-space(l);
  display: flex;

  @include mq(l) {
    flex-direction: column;
    margin: 0 0 0 get-space(xl);
  }
}

.column {
  flex-basis: 50%;

  @include mq(l) {
    flex-basis: auto;
  }

  &:nth-child(even) {
    margin-left: get-space(m);

    @include mq(l) {
      margin: 0;
    }
  }

  &:nth-child(odd) {
    margin-right: get-space(m);

    @include mq(l) {
      margin: 0;
    }
  }
}

.discount {
  display: flex;
}

.wrapper {
  margin-bottom: get-space(xs);

  &--has-discount {
    margin-right: get-space(m);
  }
}

.label {
  @include text-type-meta;

  display: block;
}

.value {
  @include text-type-copy-small-bold;

  display: block;
  white-space: nowrap;

  &--highlighted {
    @include text-type-headline-big;

    white-space: nowrap;
  }
}

.quantity {
  margin: get-space(m) 0 0;

  &__input {
    min-width: 200px;
    z-index: 0;
    margin-bottom: get-space(s);
    &,
    & input,
    & input[type="number"],
    & button {
      height: 30px !important;
      min-height: 30px;
    }
  }

  &__label {
    @include text-type-meta;
  }

  &__value {
    @include text-type-meta-bold;
  }
}

.express {
  @include text-type-meta;
  align-items: center;
  display: flex;
  white-space: nowrap;
  margin-top: get-space(m);

  &__icon {
    width: 40px !important;
    height: 37px !important;
    margin: -8.5px get-space(s) -8.5px 0; // 20px height
  }
}

.button {
  margin-top: get-space(s);
  white-space: nowrap;
}
</style>
