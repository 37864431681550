<template>
  <table
    v-roving-tabindex-container
    :class="[$style.root, computedModifierClasses]"
  >
    <thead v-if="header.length > 0">
      <tr>
        <td
          v-for="(column, index) in header"
          :key="index"
          :class="$style['header-col']"
          :colspan="computedHeaderColspan"
          :width="widths[index]"
        >
          {{ column }}
        </td>
        <td v-if="hasExpandableRows" />
      </tr>
    </thead>

    <tbody v-if="rows.length > 0">
      <template v-for="(row, index) in rows">
        <!-- ROW WITH RELATED SUBROWS -->
        <template v-if="isCollapsibleRow(row)">
          <MoleculeTableRow
            :key="index"
            :row="Array.isArray(row[0].row) ? row[0].row : []"
            :index="index"
            :modifier="['can-expand', getRowModifier(index)]"
            :expander="opened.includes(`${id}_${index}`) ? 'open' : 'close'"
            @toggle="onToggleRow(`${id}_${index}`)"
          />
          <MoleculeTableRow
            v-for="(r, i) in row[0].subrows"
            v-show="opened.includes(`${id}_${index}`)"
            :key="`${index}_${i}`"
            :row="r"
            :index="i"
            :modifier="['subrow', getRowModifier(index)]"
            :spacer="hasExpandableRows"
          />
        </template>

        <!-- SIMPLE ROW -->
        <template v-else>
          <MoleculeTableRow
            :key="index"
            :row="row"
            :index="index"
            :modifier="[getRowModifier(index)]"
            :spacer="hasExpandableRows"
          />
        </template>
      </template>
    </tbody>
  </table>
</template>

<script>
import MoleculeTableRow from "./MoleculeTableRow.vue";

export default {
  components: {
    MoleculeTableRow,
  },

  props: {
    id: {
      type: String,
      required: true,
    },
    header: {
      type: Array,
      default: () => [],
    },
    rows: {
      type: Array,
      default: () => [],
    },
    widths: {
      type: Array,
      default: () => [],
    },
    modifier: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      opened: [],
    };
  },

  computed: {
    computedModifierClasses() {
      return this.modifier.map((modifier) => this.$style[`root--${modifier}`]);
    },
    computedHeaderColspan() {
      if (this.header.length > 1) {
        return false;
      }

      try {
        return (this.rows[0] || []).filter(
          (col) => !(typeof col === "object" && col.subtable)
        ).length;
      } catch (error) {
        return 0;
      }
    },
    hasExpandableRows() {
      return this.rows.some((row) => this.isCollapsibleRow(row));
    },
  },

  methods: {
    getRowModifier(index) {
      return index % 2 === 0 ? "odd" : "even";
    },
    onToggleRow(id) {
      const index = this.opened.indexOf(id);
      if (index > -1) {
        this.opened.splice(index, 1);
      } else {
        this.opened.push(id);
      }
    },
    isCollapsibleRow(row) {
      return (
        Array.isArray(row) &&
        row.length === 1 &&
        {}.hasOwnProperty.call(row[0], "row")
      );
    },
  },
};
</script>

<style lang="scss" module>
@import "~@webprojects/ui-pattern-library/src/stylesheets/environment";

.root {
  table-layout: fixed;
  // white-space: nowrap;
  width: 100%;

  &--autowidth {
    table-layout: auto;
  }
}

.header-col {
  @include text-type-copy-small-extended;
  padding: 0 0 get-space(m) get-space(s);

  &:first-child {
    padding-left: 0;
  }
}
</style>
