<template>
  <BuMoleculePopup
    :id="`popup-locator-${uid}`"
    v-model:is-opened="isOpened"
    :class="['m-popup-locator', { 'm-popup-locator--is-opened': isOpened }]"
    :handler-attributes="{ title: t('label') }"
    :label="$i18n?.locale?.toUpperCase()"
    icon-on-right
    icon="down"
    role="menu"
  >
    <BuMoleculeContextmenu
      :id="`popup-locator-menu-${uid}`"
      :headline="t('label')"
    >
      <BuMoleculeContextmenuItem
        v-for="l in computedLanguages"
        :key="l.key"
        :modifier="l.key === $i18n?.locale ? ['active'] : []"
        @click="() => onInput(l)"
      >
        {{ l.value }}
      </BuMoleculeContextmenuItem>
    </BuMoleculeContextmenu>
  </BuMoleculePopup>
</template>

<script>
import { uuid } from "vue-uuid";
import { languages } from "../../../settings";
import { loadLanguageTranslations } from "../../../i18n";

export default {
  data() {
    return {
      uid: uuid.v4(),
      isOpened: false,
      languages,
    };
  },

  computed: {
    computedLanguages() {
      return this.languages
        .slice() // .sort() mutates the array so better make a copy
        .sort()
        .map((lang) => ({
          key: lang,
          value: this.t(`languages.${lang}`),
        }));
    },
  },

  methods: {
    t(key, options) {
      const prefix = "components.molecules.popupLocator";
      return this.$t(`${prefix}.${key}`, options);
    },
    async onInput(value) {
      this.isOpened = false;

      await loadLanguageTranslations(value.key);

      // reissue all API requests
      // TODO move into connected/
      this.$nextTick(() => {
        this.$store.commit("categories/reset");
        this.$store.dispatch("compare/load");
        this.$store.dispatch("detail/load");
        if (this.$store.state.search.result.lastUpdated !== -1) {
          this.$store.dispatch("search/load", "search");
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@webprojects/ui-pattern-library/src/stylesheets/environment";

.m-popup-locator {
  margin-left: get-space(l);

  ::v-deep(.m-popup__button .a-svg-sprite) {
    transition: all 0.2s ease;
  }
  &--is-opened ::v-deep(.m-popup__button .a-svg-sprite) {
    transform: rotate(180deg);
  }

  ::v-deep(.a-link--active:focus) {
    color: get-color(blue-dark);
  }
}
</style>
