<template>
  <BuMoleculePopup
    :id="`popup-help-${uid}`"
    v-model:is-opened="isOpened"
    :class="['m-popup-helper']"
    :label="t('label')"
    icon="info"
    role="menu"
  >
    <BuMoleculeContextmenu
      :id="`popup-help-help-${uid}`"
      :headline="t('content.help.headline')"
    >
      <!--
      <BuMoleculeContextmenuItem
        :href="t('content.help.links.video.href')"
        :label="t('content.help.links.video.label')"
        rel="nofollow noopener"
        target="_blank"
      />
      -->
      <!-- FAQ -->
      <BuMoleculeContextmenuItem
        :label="t('content.help.links.faq.label')"
        @click="onFaqClick"
      />
      <!-- Shortcuts -->
      <BuMoleculeContextmenuItem
        :label="t('content.help.links.shortcuts.label')"
        @click="onKbdClick"
      />
      <!-- Changelog -->
      <BuMoleculeContextmenuItem
        v-if="role === 'sales'"
        :label="t('content.help.links.changelog.label')"
        @click="onChangelogClick"
      />
    </BuMoleculeContextmenu>
    <BuAtomHr :modifier="['top-m', 'bottom-m']" />
    <BuMoleculeContextmenu
      :id="`popup-help-contact-${uid}`"
      :headline="t('content.contact.headline')"
    >
      <BuMoleculeContextmenuItem
        :href="t('content.contact.links.phone.href')"
        :label="t('content.contact.links.phone.label')"
        icon="phone"
        rel="nofollow noopener"
        target="_blank"
      />
      <BuMoleculeContextmenuItem
        :href="t('content.contact.links.mail.href')"
        :label="t('content.contact.links.mail.label')"
        icon="mail"
        rel="nofollow noopener"
        target="_blank"
      />
      <!--
      <BuMoleculeContextmenuItem
        :href="t('content.contact.links.feedback.href')"
        :label="t('content.contact.links.feedback.label')"
        icon="feedback-form"
        rel="nofollow noopener"
        target="_blank"
      />
      -->
    </BuMoleculeContextmenu>
    <BuAtomHr v-if="role === 'sales'" :modifier="['top-m', 'bottom-m']" />
    <BuMoleculeContextmenu
      v-if="role === 'sales'"
      :id="`popup-help-help-${uid}`"
      :headline="t('content.information.headline')"
    >
      <BuMoleculeContextmenuItem
        :label="t('content.information.links.siteInfo')"
        href="https://country.burkert.com/?url=global-site-info"
        target="_blank"
      />
      <BuMoleculeContextmenuItem
        :label="t('content.information.links.dataPrivacy')"
        href="https://country.burkert.com/?url=global-data-privacy"
        target="_blank"
      />
    </BuMoleculeContextmenu>

    <template v-if="release">
      <BuAtomHr :modifier="['top-m', 'bottom-m']" />
      <BuAtomCopy :modifier="['smaller']" tag="p" :text="release" />
    </template>

    <!-- Modal FAQ -->
    <OrganismModalFaq v-model:is-opened="faqModalIsOpened" />

    <!-- Modal Changelog -->
    <OrganismModalChangelog v-model:is-opened="changelogModalIsOpened" />
    <!-- LOGOUT BUTTON
    <BuAtomHr
      v-if="this.role === 'external'"
      :modifier="['top-m', 'bottom-m']"
    />
    <BuMoleculeContextmenu>
      <BuMoleculeContextmenuItem
        label="Logout"
        href="https://login2.burkert.com/login"
        @click="onLogout()"
      />
    </BuMoleculeContextmenu>
    -->
  </BuMoleculePopup>
</template>

<script>
import { uuid } from "vue-uuid";
import { mapState } from "vuex";
import OrganismModalFaq from "../organisms/OrganismModalFaq.vue";
import OrganismModalChangelog from "../organisms/OrganismModalChangelog.vue";

export default {
  components: {
    OrganismModalFaq,
    OrganismModalChangelog,
  },

  data() {
    return {
      uid: uuid.v4(),
      /**
       * Whether the help popup is opened
       * @type {Boolean}
       */
      isOpened: false,

      /**
       * Whether the FAQ modal is opened
       * @type {Boolean}
       */
      faqModalIsOpened: false,

      /**
       * Whether the changelog modal is opened
       * @type {Boolean}
       */
      changelogModalIsOpened: false,

      /**
       * Current version number
       * @type {String}
       */
      release: process.env.RELEASE,
    };
  },

  computed: {
    ...mapState({
      role: (state) => state.global.user.data.role,
    }),
  },

  methods: {
    t(key, options) {
      const prefix = "components.molecules.popupHelp";
      return this.$t(`${prefix}.${key}`, options);
    },
    onFaqClick() {
      this.isOpened = false;
      this.faqModalIsOpened = true;
    },
    onKbdClick() {
      this.isOpened = false;
      this.$store.commit("global/setShowKeyboardShortcutsModal", true);
    },
    onChangelogClick() {
      this.isOpened = false;
      this.changelogModalIsOpened = true;
    },
    /**
     * Event handler for loging out
     */
    /* LOGOUT BUTTON
    onLogout() {
      sessionStorage.clear();
    }
    */
  },
};
</script>

<style lang="scss" scoped>
@import "~@webprojects/ui-pattern-library/src/stylesheets/environment";

.m-popup-helper {
  margin-left: get-space(l);
}
</style>
