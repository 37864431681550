<template>
  <header :class="$style.root">
    <a :class="$style.logo" href="/">
      <svg :viewBox="logo.viewBox" role="img">
        <title>Bürkert</title>
        <use v-bind="{ 'xlink:href': '#' + logo.id }" />
      </svg>
    </a>
    <div :class="$style.search"><slot name="search" /></div>
    <div :class="$style.actions"><slot name="actions" /></div>
    <!--<div></div>-->
  </header>
</template>

<script>
/**
 * @file Page header. Should be included within the header slot of LayoutApp
 */
import logo from "../../../assets/buerkert-logo.svg";

export default {
  data() {
    return {
      logo,
    };
  },
};
</script>

<style lang="scss">
@import "~@webprojects/ui-pattern-library/src/stylesheets/environment";

path.logo {
  fill: get-color(blue-dark);
}

path.slogan {
  fill: get-color(gray-dark)
}

</style>

<style lang="scss" module>
@import "~@webprojects/ui-pattern-library/src/stylesheets/environment";

.root {
  background-color: get-color(blue-light);
  display: grid;
  grid-column-gap: get-space(l);
  grid-template-columns: auto 1fr auto;
  grid-template-rows: 1fr;
  padding: get-space(m);
}

.logo {
  align-items: center;
  display: flex;
  grid-column: 1;

  svg {
    display: block;
    width: 100px;
    height: 37px;
  }
}

.search {
  grid-column: 2;
}

.actions {
  grid-column: 3;
  display: flex;
}
</style>
