export const apiEndpoints = {
  article: `${process.env.API_ROOT}/article`,
  categories: `${process.env.API_ROOT}/categories`,
  compare: `${process.env.API_ROOT}/compare`,
  parts: `${process.env.API_ROOT}/parts`,
  price: `${process.env.API_ROOT}/price`,
  search: `${process.env.API_ROOT}/search`,
  token: `${process.env.API_ROOT}/token`,
  translation: `${process.env.API_ROOT}/translation`,
  user: `${process.env.API_ROOT}/user`,
  miner: `${process.env.API_ROOT}/discount`,
  logout: `${process.env.API_ROOT}/sso_logout`,
  export: `${process.env.API_ROOT}/export`,
};

export const languages = ["de", "en", "fr"];
