import getInitialState from "./state";

/**
 * Reset/Unset the article
 * @param {Object} state - Vuex state
 */
export const reset = (state) => {
  Object.assign(state, getInitialState());
};

/**
 * Set the article data
 * @param {Object} state - Vuex state
 * @param {Object} data - Response data
 */
export const setData = (state, { error, data }) => {
  state.data.lastUpdated = Date.now();
  state.data.error = error;
  state.data.data = data;
};

/**
 * Set whether data is loading
 * @param {Object} state - Vuex state
 * @param {Boolean} isLoading - Whether data is loading
 */
export const setIsLoading = (state, isLoading) => {
  state.data.isLoading = isLoading;
};

/**
 * Set whether data is loading
 * @param {Object} state - Vuex state
 * @param {Boolean} isRunningRequest - Whether a request is running
 */
export const setIsRunningRequest = (state, isRunningRequest) => {
  state.data.isRunningRequest = isRunningRequest;
};

/**
 * Set the article SKU
 * @param {Object} state - Vuex state
 * @param {String} sku - Article SKU
 */
export const setSku = (state, sku) => {
  state.sku = sku;
};

/**
 * Set the previous article SKU
 * @param {Object} state - Vuex state
 * @param {String} sku - Article SKU
 */
export const setPreviousSku = (state, sku) => {
  state.previousSku = sku;
};

/**
 * Set the article QUANTITY
 * @param {Object} state - Vuex state
 * @param {Number} quantity - Article QUANTITY
 */
export const setQuantity = (state, quantity) => {
  state.quantity = quantity;
};

/**
 * Set the parts data
 * @param {Object} state - Vuex state
 * @param {Object} data - Response data
 */
export const setPartsData = (state, { error, data }) => {
  state.parts.lastUpdated = Date.now();
  state.parts.error = error;
  state.parts.data = data;
};

/**
 * Set whether parts data is loading
 * @param {Object} state - Vuex state
 * @param {Boolean} isLoading - Whether data is loading
 */
export const setPartsIsLoading = (state, isLoading) => {
  state.parts.isLoading = isLoading;
};
