import { createRouter, createWebHistory } from "vue-router";
import Search from "/src/components/connected/search/Search.vue";

const routes = [{ path: "/item/:id", component: Search, name: "showSku" }];

const router = createRouter({
  history: createWebHistory(),
  base: process.env.BASE_URL,
  routes,
});

export default router;
