import { normalize, schema } from "normalizr";

import { getCategories } from "../../../api";

// Schema for categories
const category = new schema.Entity("categories");
const categories = new schema.Array(category);
category.define({ sub: categories });

/**
 * Request product categories from API, then commit them normalized
 * @param {Object} context
 * @param {function} context.commit
 * @param {Object} context.getters
 * @returns {Promise} Promise that resolves when the data has finished loading
 */
export const load = async ({ commit, getters }) => {
  if (getters.getIsNotEmpty) {
    // do nothing if categories are already populated
    return;
  }

  commit("setIsLoading", true);

  try {
    const { data } = await getCategories();
    const normalizedData = normalize(
      { id: "0", sub: data.categories },
      category
    );
    commit("setData", { data: normalizedData });
  } catch (e) {
    commit("setData", { error: e.format(), data: {} });
  }

  commit("setIsLoading", false);
};
