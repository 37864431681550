<template>
  <Accordion
    v-mousetrap="['f']"
    v-bind="$attrs"
    allow-multiple-expanded
    allow-zero-expanded
    @mousetrap="onMousetrap"
  >
    <slot />
  </Accordion>
</template>

<script>

import Accordion from "../molecules/Accordion.vue";
export default {
  components: {Accordion},
  methods: {
    onMousetrap() {
      this.$el.querySelector("button").focus();
    },
  },
};
</script>
