import { createI18n } from "vue-i18n";
import { getTranslations } from "./api";

// Create i18n instance
const i18n = createI18n({
  legacy: false,
  locale: "en",
  fallbackLocale: "en",
  messages: {},
  warnHtmlMessage: false,
});

/**
 * Load messages for a specific language
 * @param {String} lng - Language to load messages for
 * @param {Function} getTranslations - API method to fetch translations
 */
export const loadLanguageTranslations = async (lng) => {
  const { data } = await getTranslations(lng);
  i18n.global.setLocaleMessage(lng, data);
  i18n.global.locale.value = lng;
  i18n.global.data = data;
  i18n.global.onChange = (cb) => {
    window.addEventListener("language-changed", (e) => {
      cb(e.detail);
    });
  };

  window.dispatchEvent(
    new CustomEvent("language-changed", {
      detail: {
        lng,
        data,
      },
    })
  );

  document.querySelector("html").setAttribute("lang", lng);
};

export default i18n;
