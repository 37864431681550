<template>
  <li :class="$style.root">
    <div :class="$style.inner">
      <AtomPlaceholder v-if="iconBefore" :class="$style['icon-before']" />
      <AtomPlaceholder :class="$style.label" />
      <AtomPlaceholder v-if="iconAfter" :class="$style['icon-after']" />
    </div>
  </li>
</template>

<script>
import AtomPlaceholder from "./AtomPlaceholder.vue";

export default {
  components: { AtomPlaceholder },

  props: {
    iconBefore: {
      type: Boolean,
      default: false,
    },
    iconAfter: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" module>
@import "~@webprojects/ui-pattern-library/src/stylesheets/environment";

.root {
  display: block;
  margin: get-space(s) 0;

  &:first-child {
    margin-top: 0;
  }

  &:last-child {
    margin-bottom: 0;
  }
}

.inner {
  align-items: center;
  display: flex;
}

.icon-before {
  height: 60px;
  width: 60px;
}

.label {
  height: 16px;
  margin: 0 get-space(xs);
  width: 60%;
}

.icon-after {
  height: 16px;
  margin-left: auto;
  width: 16px;
}
</style>
