import { getUser } from "../../../api";

/**
 * Request user global configuration from API
 */
export const loadUser = async ({ commit }) => {
  commit("setUserIsLoading", true);

  try {
    const { data } = await getUser();
    commit("setSegmentList", data.segmentList)
    commit("setUserData", { data });
  } catch (e) {
    commit("setUserData", { error: e.format(), data: {} });
  }

  commit("setUserIsLoading", false);
};

export const setCustomer = async ({ commit, state }) => {
  const { customer } = state;
  commit("setCustomer", customer);
};

export const setSegment = async ({ commit, state }) => {
  const { segment } = state;
  commit("setSegment", segment);
};

export const logoutUser = async ({ commit }) => {
  commit("logoutUser");
};
