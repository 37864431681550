import getInitialState from "./state";

/**
 * Reset the search store
 * @param {Object} state - Vuex state
 * @param {Boolean} withAdditionalFacets - Optional. Default: false. Whether to also reset additionalFacets
 * @returns {void}
 */
export const reset = (state, withAdditionalFacets = false) => {
  const initialState = getInitialState();
  if (!withAdditionalFacets) {
    delete initialState.additionalFacets;
  }
  Object.assign(state, initialState);
};

/**
 * Reset the search pagination
 * @param {*} state - Vuex state
 * @returns {void}
 */
export const resetPagination = (state) => {
  Object.assign(state.pagination, getInitialState().pagination);
};

/**
 *
 * @param {Object} state - Vuex state
 * @param {Boolean} showAllResults - if result will be shown completelly or limited
 * @returns {void}
 */
export const setShowAllResults = (state, showAllResults) => {
  state.showAllResults = showAllResults;
};

/**
 * Set an additional facet
 * @param {Object} state - Vuex state
 * @param {Object} facet
 * @param {string} facet.key - Search facet key
 * @param {string} facet.value - Search facet value
 * @returns {void}
 */
export const setAdditionalFacet = (state, { key, value }) => {
  state.additionalFacets[key] = value;
};

/**
 * Set whether the next filter deletion should trigger a reverse search
 * @param {Object} state - Vuex state
 * @param {Boolean} nextIsReverseSearch - Whether the next filter deletion triggers a reverse search
 * @returns {void}
 */
export const setReverseSearchMode = (state, nextIsReverseSearch) => {
  state.reverseSearchMode = nextIsReverseSearch;
};

/**
 * Set a search facet filter value
 * @param {Object} state - Vuex state
 * @param {Object} filter
 * @param {string} filter.key - Filter ID
 * @param {*} filter.value - Filter value, depends on the filter type
 * @returns {void}
 */
export const setFilter = (state, { key, value }) => {
  /* NEBU-1072 Enable reverse search when told by the API via isSingleArticle.
   * The previous logic is kept for backwards API compatibility.
   */
  const reverseSearchMode =
    state.result.data.isSingleArticle || state.reverseSearchMode;
  if (reverseSearchMode) {
    // NEBU-407 reverse search: clear search value if we have to start a reverse search
    state.additionalFacets.searchTerm = "";
  }

  // delete the filter if it has no value set
  if (!value || !value.value || value.value.length === 0) {
    delete state.filters[key];
    return;
  }

  state.filters[key] = value;
};

/**
 * Set all search facet filter values
 * @param {Object} state - Vuex state
 * @param {*} filters - Filters object where keys are filter IDs
 * @returns {void}
 */
export const setFilters = (state, filters) => {
  state.filters = filters;
};

/**
 *
 * @param {Object} state - Vuex state
 * @param {Object} pagination - Pagination object consisting of `offset` and `limit`
 * @returns {void}
 */
export const setPagination = (state, pagination) => {
  state.pagination = {
    ...state.pagination,
    ...pagination,
  };
};

/**
 * Set product categories data
 * @param {Object} state - Vuex state
 * @param {Object} data - Normalized product categories data
 * @returns {void}
 */
export const setData = (state, { error, data }) => {
  state.result.lastUpdated = Date.now();
  state.result.error = error;
  state.result.data = data;
};

/**
 * Set whether product categories are currently loading
 * @param {Object} state - Vuex state
 * @param {object} event
 * @param {boolean} event.isLoading - Whether product categories are loading
 * @param {string} event.actionOrigin - Origin of the loading event
 * @returns {void}
 */
export const setIsLoading = (state, { isLoading, actionOrigin }) => {
  state.result.actionOrigin = actionOrigin;
  state.result.isLoading = isLoading;
};

/**
 * Set column sort parameters
 * @param {Object} state - Vuex state
 * @param {Object} sortBy - Object where keys are column IDs and values are either "asc" or "desc"
 * @returns {void}
 */
export const setSortBy = (state, sortBy) => {
  state.sortBy = sortBy;
};

/**
 * Set which columns are visible within the result table
 * @param {Object} state - Vuex state
 * @param {String[]} columns - Array of visible column IDs
 * @returns {void}
 */
export const setVisibleColumns = (state, columns) => {
  state.visibleColumns = columns;
};
