import whatInput from "what-input";

const WhatIntentMixin = {
  data() {
    return {
      whatIntent: whatInput.ask("intent"),
    };
  },

  mounted() {
    whatInput.registerOnChange(this.onIntentChange, "intent");
  },

  beforeDestroy() {
    whatInput.unRegisterOnChange(this.onIntentChange);
  },

  methods: {
    onIntentChange(intent) {
      this.whatIntent = intent;
    },
  },
};

export default WhatIntentMixin;
