<template>
  <Accordion
    :id="id"
    :pre-expanded="expandedFilters"
    allow-zero-expanded
    @update:pre-expanded="$emit('update:expandedFilters', $event)"
  >
    <slot :visible-filters="visibleFilters" :on-close="onClose" />
    <AtomFacetFilterGroupShowMore
      v-if="isOverflowing"
      v-model:value="isExpanded"
      :title="isExpanded ? t('showLess') : t('showMore')"
      :labels="{ more: t('showLess'), less: t('showMore') }"
      :aria-controls="id"
    />
  </Accordion>
</template>

<script>
import { uuid } from "vue-uuid";
import AtomFacetFilterGroupShowMore from "../atoms/AtomFacetFilterGroupShowMore.vue";
import Accordion from "./Accordion.vue";

export default {
  components: {
    Accordion,
    AtomFacetFilterGroupShowMore,
  },

  props: {
    expandedFilters: {
      type: Array,
      default: () => [],
    },
    filterGroup: {
      type: Object,
      required: true,
    },
    revealAfter: {
      type: Number,
      default: 5,
    },
  },
  emits: ["update:expandedFilters"],

  data() {
    return {
      uid: uuid.v4(),
      isExpanded: this.revealAfter <= 0,
    };
  },

  computed: {
    id() {
      return `fg-${this.uid}`;
    },
    canOverflow() {
      return this.revealAfter > 0;
    },
    isOverflowing() {
      return (
        this.canOverflow &&
        this.filterGroup.availableFilters.length > this.revealAfter
      );
    },
    visibleFilters() {
      const { availableFilters } = this.filterGroup;
      return !this.canOverflow || this.isExpanded
        ? availableFilters
        : availableFilters.slice(0, this.revealAfter);
    },
  },

  watch: {
    revealAfter(revealAfter) {
      if (revealAfter <= 0) {
        this.isExpanded = true;
      }
    },
  },

  methods: {
    t(key, options) {
      const prefix = "components.molecules.facetFilterGroup";
      return this.$t(`${prefix}.${key}`, options);
    },
    onClose(id) {
      this.$emit(
        "update:expandedFilters",
        this.expandedFilters.filter((x) => x !== id)
      );
    },
  },
};
</script>
