<template>
  <form :class="$style.root" @submit.prevent="onSubmit">
    <ul :class="$style.options">
      <li
        v-for="(option, i) in options"
        :key="option.value"
        :aria-selected="i === ariaSelected"
        :class="$style.option"
      >
        <BuAtomRadioButton
          :modifier="['variant']"
          :selected-value="value"
          :value="option.value"
          name="value"
          @input="() => onValueInput(option.value)"
        >
          <div :class="$style['option-label']">
            <span :class="$style['option-label__title']">{{
              option.title
            }}</span>
            <span :class="$style['option-label__count']">
              {{ option.resultCount }}
            </span>
          </div>
        </BuAtomRadioButton>
      </li>
    </ul>
    <button :class="$style['btn-submit']" tabindex="-1" type="submit">
      Submit
    </button>
  </form>
</template>

<script>
import isEqual from "lodash.isequal";

import WhatIntentMixin from "../../../lib/WhatIntentMixin";

export default {
  mixins: [WhatIntentMixin],

  props: {
    /**
     * Currently aria-selected value index
     */
    ariaSelected: {
      type: Number,
      default: -1,
    },

    /**
     * Filter options
     */
    options: {
      type: Array,
      required: true,
    },

    /**
     * Store-Set value, i.e. an Object with key `value` which is an Array
     */
    filterValue: {
      type: Object,
      default: () => ({}),
    },
  },
  emits: ["update:filter-value"],

  data() {
    return {
      value: this.filterValue.value,
    };
  },

  watch: {
    /**
     * Update internal state when external state changes
     */
    filterValue({ value }) {
      this.value = value;
    },
  },

  methods: {
    onValueInput(value) {
      this.value = value;

      if (this.whatIntent !== "keyboard") {
        this.onSubmit();
      }
    },

    /**
     * Update external state when form gets submitted
     */
    onSubmit() {
      // do not emit if value has not changed
      if (isEqual(this.filterValue.value, this.value)) return;

      this.$emit("update:filter-value", { value: this.value });
    },
  },
};
</script>

<style lang="scss" module>
@import "~@webprojects/ui-pattern-library/src/stylesheets/environment";

.root {
  padding: 0 get-space(m);
}

.options {
  list-style: none;
  margin: 0;
  padding: 0;
}

.option {
  margin: get-space(m) 0;
  position: relative;

  &[aria-selected="true"]::before {
    content: "";
    position: absolute;
    top: -1 * get-space(s);
    left: -1 * get-space(m);
    right: -1 * get-space(m);
    bottom: -1 * get-space(s);
    background: rgba(get-color(blue-dark), 0.17);
  }
}

.option-label {
  display: grid;
  grid-template-columns: 1fr auto;
  grid-template-rows: 1fr;

  &__title {
    grid-column: 1;
    hyphens: auto;
  }

  &__count {
    @include text-type-meta;
    grid-column: 2;
    margin-left: get-space(m);
  }
}

.btn-submit {
  @extend %visually-hidden;
  top: -999999em;
}
</style>
