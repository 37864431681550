<template>
  <div :class="$style.root">
    <MoleculeHintbox
      :headline="t('headline')"
      :modifier="['error']"
      :text="error.message"
    >
      <template #actions>
        <BuAtomButton
          :modifier="['small', 'light']"
          :text="t('retry')"
          @button-click="$emit('retry')"
        />
      </template>
    </MoleculeHintbox>
  </div>
</template>

<script>
import MoleculeHintbox from "./MoleculeHintbox.vue";

export default {
  components: {
    MoleculeHintbox,
  },

  props: {
    error: {
      type: Object,
      required: true,
    },
  },
  emits: ["retry"],

  methods: {
    t(key, options) {
      const prefix = "components.molecules.error";
      return this.$t(`${prefix}.${key}`, options);
    },
  },
};
</script>

<style lang="scss" module>
@import "~@webprojects/ui-pattern-library/src/stylesheets/environment";

.root {
  padding: get-space(l);
}
</style>
