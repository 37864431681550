<template>
  <div :class="$style.root">
    <div :class="$style.sidebar">
      <AtomPlaceholder :class="$style['sidebar__hl']" />
      <AtomPlaceholder :class="$style['sidebar__subhl']" />
    </div>

    <div v-for="i in 2" :key="i" :class="$style.product">
      <AtomPlaceholder :class="$style['product__img']" />
      <AtomPlaceholder :class="$style['product__hl']" />
      <AtomPlaceholder :class="$style['product__subhl']" />
    </div>
  </div>
</template>

<script>
import AtomPlaceholder from "../atoms/AtomPlaceholder.vue";

export default {
  components: { AtomPlaceholder },
};
</script>

<style lang="scss" module>
@import "~@webprojects/ui-pattern-library/src/stylesheets/environment";

.root {
  display: grid;
  grid-template-columns: 250px repeat(2, 300px);
  grid-template-rows: 1fr;
  height: 100%;
  overflow: hidden;
  padding: get-space(l);
}

.sidebar {
  border-right: 1px get-color(gray-light) solid;

  &__hl {
    height: 28px;
    margin-bottom: get-space(s);
    width: 195px;
  }

  &__subhl {
    height: 20px;
    width: 70px;
  }
}

.product {
  border-right: 1px get-color(gray-light) solid;
  padding: 0 get-space(m);

  &__img {
    height: 50px;
    margin-bottom: get-space(m);
    margin-top: get-space(l);
    width: 50px;
  }

  &__hl {
    height: 20px;
    margin-bottom: get-space(xs);
    width: 150px;
  }

  &__subhl {
    height: 16px;
    width: 100px;
  }
}
</style>
