import { apiEndpoints } from "../../settings";
import apiCall from "../apiCall";
import i18n from "../../i18n";

/**
 * Get navigation categories
 * @returns {Promise} Promise that resolves with navigation categories or rejects with an ApiError
 */
export const getCategories = async () =>
  apiCall(apiEndpoints.categories, {
    language: i18n.global.locale.value,
  });
