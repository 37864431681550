import { apiEndpoints } from "../../settings";
import apiCall from "../apiCall";
import i18n from "../../i18n";

/**
 * Get multiple articles without parts for a comparison
 * @param {String[]} skus - Article SKUs
 * @returns {Promise} Promise that resolves with articles or rejects with an ApiError
 */
export const getComparison = async (skus) =>
  apiCall(apiEndpoints.compare, {
    language: i18n.global.locale.value,
    skus,
  });
