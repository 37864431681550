<template>
  <AccordionItem
    ref="accordionItem"
    v-slot="{ isExpanded }"
    :uuid="id"
    @open="onOpen"
  >
    <AccordionItemHeading>
      <AccordionItemButton
        ref="button"
        :class="[
          $style['heading'],
          { [$style['heading--is-expanded']]: isExpanded },
        ]"
        @keydown.esc.prevent="onKeydownEsc"
      >
        <span
          :class="[
            $style['heading-inner'],
            { [$style['heading-inner--is-expanded']]: isExpanded },
          ]"
        >
          <span :class="$style['heading-inner__title']">
            {{ name }}
          </span>
          <span :class="$style['heading-inner__right']">
            <span
              v-if="filtersWithTitle"
              :class="$style['heading-inner__selected']"
            >
              <BuAtomPill
                v-for="{ value: thisValue, title } in filtersWithTitle"
                :key="thisValue"
                :class="$style['heading-inner__selected-pill']"
                is-removable
                is-small
                @remove="() => onRemoveFacetFilter(thisValue)"
              >
                <span :class="$style['heading-inner__selected-pill-label']">{{
                  title
                }}</span>
              </BuAtomPill>
            </span>
            <BuAtomSvgSprite
              id="forward"
              :class="$style['heading-inner__icon']"
            />
          </span>
        </span>
      </AccordionItemButton>
    </AccordionItemHeading>
    <AccordionItemPanel
       ref="panel"
      :class="$style.panel"
      @click-outside="() => isExpanded && onClickOutside()"
    >
      <div :class="$style['panel__inner']" @keydown.esc.prevent="onKeydownEsc">
        <!-- Hint Text -->
        <div v-if="hint" :class="$style['hint']">{{ hint }}</div>

        <!-- undocumented -->
        <MoleculeFacetFilterGroupItemSearch
          v-slot="{ filteredData, selectedOption }"
          :data="data"
          :is-searchable="isSearchable"
        >
          <MoleculeFacetFilterTypeOperator
            v-if="type === 'operator'"
            v-bind="filteredData"
            :filter-value="value"
            @update:filter-value="onSetFacetFilter"
          />
          <MoleculeFacetFilterTypeCheckbox
            v-else-if="type === 'checkbox'"
            v-bind="filteredData"
            :aria-selected="selectedOption"
            :filter-value="value"
            @update:filter-value="onSetFacetFilter"
          />
          <MoleculeFacetFilterTypeRadio
            v-else-if="type === 'radio'"
            v-bind="filteredData"
            :aria-selected="selectedOption"
            :filter-value="value"
            @update:filter-value="onSetFacetFilter"
          />
        </MoleculeFacetFilterGroupItemSearch>
      </div>
    </AccordionItemPanel>
  </AccordionItem>
</template>

<script>
import Popper from "popper.js";

import MoleculeFacetFilterGroupItemSearch from "./MoleculeFacetFilterGroupItemSearch.vue";
import MoleculeFacetFilterTypeCheckbox from "./MoleculeFacetFilterTypeCheckbox.vue";
import MoleculeFacetFilterTypeOperator from "./MoleculeFacetFilterTypeOperator.vue";
import MoleculeFacetFilterTypeRadio from "./MoleculeFacetFilterTypeRadio.vue";
import AccordionItem from "./AccordionItem.vue";
import AccordionItemHeading from "./AccordionItemHeading.vue";
import AccordionItemButton from "./AccordionItemButton.vue";
import AccordionItemPanel from "./AccordionItemPanel.vue";

/**
 * Mininum amount of options to enable the search
 * @type {Number}
 */
const SEARCH_MIN_ITEMS = 4;

export default {
  components: {
    AccordionItemPanel,
    AccordionItemButton,
    AccordionItemHeading,
    AccordionItem,
    MoleculeFacetFilterGroupItemSearch,
    MoleculeFacetFilterTypeCheckbox,
    MoleculeFacetFilterTypeOperator,
    MoleculeFacetFilterTypeRadio,
  },

  props: {
    hint: {
      type: String,
      required: false,
      default: undefined,
    },
    id: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
    data: {
      type: Object,
      required: true,
    },
    value: {
      type: Object,
      default: () => ({}),
    },
    filtersWithTitle: {
      type: Array,
      default: () => [],
    },
  },
  emits: ["close", "removeFilter", "setFilter"],

  computed: {
    /**
     * Whether the filter options can be searched
     * @returns {Boolean}
     */
    isSearchable() {
      return (
        this.type !== "operator" && this.data.options.length >= SEARCH_MIN_ITEMS
      );
    },
  },

  watch: {
    data() {
      // NEBU-799 update popper positioning on filter data change
      if (this.pI) {
        this.pI.scheduleUpdate();
      }
    },
  },

  beforeUnmount() {
    if (this.pI) {
      this.pI.destroy();
    }
  },

  methods: {
    /**
     * Closes the filter panel
     * @returns {void}
     */
    close() {
      this.$emit("close", this.id);
    },

    /**
     * Event handler for when the filter opens
     * @returns {void}
     */
    async onOpen() {
      this.$refs.accordionItem.$el.scrollIntoView({
        behavior: "instant",
        block: "nearest",
      });
      if (!this.pI) {
        this.pI = new Popper(this.$el, this.$refs.panel.$el, {
          placement: "right-start",
          removeOnDestroy: true,
          modifiers: {
            flip: {
              enabled: false,
            },
            preventOverflow: {
              boundariesElement: "viewport",
            },
            offset: {
              offset: -15,
            },
          },
        });
      }

      await this.$nextTick();

      this.pI.scheduleUpdate();

      // focus the first input
      const $input =
        (this.$refs.panel
          ? this.$refs.panel.$el.querySelector("input")
          : false) || false;
      if ($input) {
        $input.focus();
      }
    },

    /**
     * Event handler for when a click happens outside of the item
     * @returns {void}
     */
    onClickOutside() {
      this.close();
    },

    /**
     * Event handler for when
     */
    onKeydownEsc() {
      this.$refs.button.$el.focus();
      this.close();
    },

    /**
     * Event handler for when a value gets removed
     * @param {string} filterValue - To be removed filter value
     * @returns {void}
     */
    onRemoveFacetFilter(filterValue) {
      this.$emit("removeFilter", { filterId: this.id, filterValue });
    },

    /**
     * Event handler for when a value gets set
     * @param {string} filterValue - To be set filter value
     * @returns {void}
     */
    onSetFacetFilter(filterValue) {
      this.close();
      setTimeout(() => {
        this.$emit("setFilter", { filterId: this.id, filterValue });
      }, 50);
    },
  },
};
</script>

<style lang="scss" module>
@import "~@webprojects/ui-pattern-library/src/stylesheets/environment";

.hint {
  @include text-type-copy-small;
  padding: get-space(m);
  border-bottom: 1px solid get-color(light);
}

.heading {
  @extend %button-reset;
  @include text-type-copy-small;
  color: get-color(gray-dark);
  cursor: pointer !important;
  min-height: 40px;
  padding: get-space(xs) get-space(s) get-space(xs) get-space(m);
  text-align: left;
  width: 100%;
  border-bottom: 1px get-color(gray-light) solid;

  &--is-expanded {
    background: get-color(blue-light) !important;
    color: get-color(border-light);
  }
}

.heading-inner {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  &__title {
    overflow: hidden;
    padding: 2px 0;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &__right {
    display: flex;
    flex: 1 1 0%;
    justify-content: flex-end;
    margin-left: get-space(m);
  }

  &__selected {
    display: block;
    margin: calc(-1 * get-space(xs) / 2);
    text-align: right;
  }

  &__selected-pill {
    cursor: default;
    margin: calc(get-space(xs) / 2);
  }

  &__selected-pill-label {
    max-width: 130px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &__icon {
    display: block;
    flex-shrink: 0;
    margin: 2px 0 2px get-space(s);
  }

  &--is-expanded &__icon {
    visibility: hidden;
  }
}

.panel {
  background: get-color(blue-light);
  border-radius: 5px;
  border-top-left-radius: 0;
  display: flex;
  margin-top: -1px; // top border
  max-height: calc(100vh - 90px - 10px);
  overflow: hidden;
  width: 400px;
  z-index: 999;

  &__inner {
    overflow: auto;
    position: relative;
    width: 100%;
  }
}
</style>
