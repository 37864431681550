<template>
  <div :class="$style.root">
    <div v-for="i in 4" :key="i" :class="$style.row">
      <div v-for="j in 10" :key="j" :class="$style.cell">
        <AtomPlaceholder :class="$style.placeholder" />
      </div>
    </div>
  </div>
</template>

<script>
import AtomPlaceholder from "../atoms/AtomPlaceholder.vue";

export default {
  components: {
    AtomPlaceholder,
  },
};
</script>

<style lang="scss" module>
@import "~@webprojects/ui-pattern-library/src/stylesheets/environment";

$rows: 4;
$cols: 10;

.root {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat($rows, 1fr);
  padding: get-space(l);
}

.row {
  display: grid;
  grid-template-columns: repeat($cols, 1fr);
  grid-template-rows: 1fr;
}

.cell {
  padding: 5px get-space(s);
}

.placeholder {
  height: 20px;
  width: 50px;
}

.cell:first-child .placeholder {
  width: 20px;
}
</style>
