import { getSearchResults } from "../../../api";

/**
 * Request search results from API
 * @param {string} actionOrigin - Origin of the action, either "search", "facet" or "result"
 */
export const load = async ({ commit, state }, actionOrigin) => {
  const { additionalFacets } = state;

  // set loading for depending request
  commit("setIsLoading", { isLoading: true, actionOrigin });

  // set and reset pagination settings
  // based on additional facet `showInactive`
  if (additionalFacets.showInactive) {
    // disable pagination limit and show all results
    commit("setPagination", { limit: 100 });
    commit("setShowAllResults", true);
  }

  try {
    const { filters, pagination, sortBy } = state;
    const { data } = await getSearchResults({
      additionalFacets,
      filters,
      pagination,
      sortBy,
    });

    // append result if needed for `loadMore` function (offset > 0)
    if (pagination.offset > 0) {
      const items = JSON.parse(JSON.stringify(data.items)); // nosemgrep: nodejs_scan.javascript-xml-rule-node_xpath_injection
      data.items = [...state.result.data.items, ...items];
    }

    commit("setData", { data });

    commit(
      "setVisibleColumns",
      data.headers.map((h) => h.value)
    );

    // enable additionalFacets.showInactives if total item is 1 and item is not available
    if (
      data.pagination.total === 1 &&
      data.items[0].product.isAvailable == false
    )
      commit("setAdditionalFacet", {
        key: "showInactive",
        value: true,
      });

    // API 1.0 compatibility: Only override filters when they are explicitely set
    if (data.filters) {
      /* NEBU-407 reverse search: when there is only one result setting filters
       * the next occuring filter deletion should clear the searchTerm to start
       * a reverse search
       */
      const nextIsReverseSearch =
        Object.keys(state.filters).length === 0 &&
        Object.keys(data.filters).length > 0 &&
        data.pagination.total === 1;
      commit("setReverseSearchMode", nextIsReverseSearch);

      commit("setFilters", data.filters);
    }
  } catch (e) {
    let error = e;
    if (typeof e.format === `function`) error = e.format();

    commit("setData", { error, data: {} });
  }

  commit("setIsLoading", { isLoading: false, actionOrigin: null });
};
