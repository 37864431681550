<template>
  <tr
    v-roving-tabindex="!!expander"
    :class="[$style.root, computedModifierClasses]"
    :tabindex="expander && '0'"
    @click.prevent="onToggle"
    @keydown.enter.prevent="onToggle"
  >
    <td v-for="(column, i) in row" :key="i" :class="$style.col">
      <!-- cell with popup -->
      <template v-if="typeof column === 'object' && column.type === 'popup'">
        <span>{{ column.label }}</span>
        <BuAtomIconPopup :class="$style['icon-popup']" icon="info">
          <span :innerHTML="column.content" />
        </BuAtomIconPopup>
      </template>

      <!-- cell with link -->
      <template
        v-else-if="typeof column === 'object' && column.type === 'link'"
      >
        <BuAtomLink
          ref="urlSapCell"
          target="_blank"
          :label="column.label"
          :title="column.label"
          :class="$style.contentlink"
          icon="externallink"
          :modifier="['icon-right']"
          @button-click="openUrl(column.content)"
        />
      </template>
      <!-- cell with unordered list -->
      <template v-else-if="typeof column === 'object' && Array.isArray(column)">
        <template v-for="(line, li) in column">
          {{ line }}<br v-if="index != column.length - 1" :key="li" />
        </template>
      </template>

      <!-- basic cell -->
      <template v-else>{{ column }}</template>
    </td>

    <td v-if="spacer" :class="$style.col" />

    <td
      v-if="expander"
      :class="[$style.col, $style['col--with-expand-icon']]"
      width="35"
    >
      <BuAtomSvgSprite
        id="down"
        :class="[
          $style['expand-icon'],
          { [$style['expand-icon--is-expanded']]: isExpanded },
        ]"
      />
    </td>
  </tr>
</template>

<script>
export default {
  props: {
    row: {
      type: Array,
      default: () => [],
    },
    index: {
      type: Number,
      required: true,
    },
    modifier: {
      type: Array,
      default: () => [],
    },
    spacer: {
      type: Boolean,
      default: false,
    },
    expander: {
      type: String,
      default: null,
      validator: (val) => !val || ["open", "close"].indexOf(val) !== -1,
    },
  },
  emits: ["toggle"],

  computed: {
    computedModifierClasses() {
      return this.modifier.map((modifier) => this.$style[`root--${modifier}`]);
    },
    isExpanded() {
      return this.expander === "open";
    },
  },

  methods: {
    openUrl(url) {
      window.open(url);
    },
    onToggle() {
      this.$emit("toggle");
    },
  },
};
</script>

<style lang="scss" module>
@import "~@webprojects/ui-pattern-library/src/stylesheets/environment";

.root {
  &--even {
    background: get-color(light);
  }

  &--odd {
    background: get-color(blue-light);
  }

  &--can-expand {
    cursor: pointer;

    &:hover {
      color: get-color(blue-dark);
    }
  }

  &:not(#{&}--subrow) + &--subrow#{&}--even {
    border-top: 1px get-color(blue-light) solid;
  }

  &:not(#{&}--subrow) + &--subrow#{&}--odd {
    border-top: 1px get-color(light) solid;
  }

  [data-whatintent="mouse"] &,
  [data-whatintent="touch"] & {
    outline: 0;
  }
}

.col {
  @include text-type-meta;
  padding: 2px get-space(s);

  &--with-expand-icon {
    padding: 0 get-space(s);
  }

  > * {
    vertical-align: middle;
  }
}

.icon-popup {
  margin-left: get-space(s);
  white-space: normal;
}

.expand-icon {
  transition: transform 0.2s ease;

  &--is-expanded {
    transform: rotate(180deg);
  }
}
</style>
