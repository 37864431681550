<template>
  <MoleculeSearchPopupComboBox
    id="search"
    ref="search"
    v-model:value="query"
    v-model:is-expanded="isExpanded"
    label="Suche"
    :disabled="isLoading"
    @remove-before="onRemoveCategory"
    @submit="onSubmit"
  >
    <template v-if="currentCategory.title" #inputBefore>
      <BuAtomPill is-removable @remove="onRemoveCategory">
        {{ currentCategory.title }}
      </BuAtomPill>
    </template>
    <template #resetBtn>
      <SearchButtonReset :class="$style.reset" @reset="onFocusInput" />
    </template>
    <template #popup>
      <SearchPopup
        id="searchPopup"
        ref="searchPopup"
        :is-expanded="isExpanded"
        @focus-input="onFocusInput"
        @search-via="onSearchVia"
        @set-category="onSetCategory"
      />
    </template>
  </MoleculeSearchPopupComboBox>
</template>

<script>
import { mapState, mapMutations, mapGetters } from "vuex";

import MoleculeSearchPopupComboBox from "../../styleguide/molecules/MoleculeSearchPopupComboBox.vue";

import SearchButtonReset from "./SearchButtonReset.vue";
import SearchPopup from "./SearchPopup.vue";

export default {
  components: {
    MoleculeSearchPopupComboBox,
    SearchButtonReset,
    SearchPopup,
  },

  tracking: {
    eventAction: "search",
  },

  data() {
    return {
      isExpanded: false,
    };
  },

  computed: {
    ...mapState({
      isCustomerItem: (state) => state.search.result.data.isCustomerItem,
      isLoading: (state) =>
        state.search.result.isLoading &&
        state.search.result.actionOrigin === "search",
      currentCategory: (state) => {
        const { groupCode } = state.search.additionalFacets;
        return groupCode.length > 0
          ? state.categories.data.data.entities.categories[groupCode]
          : {};
      },
    }),
    ...mapGetters({
      hasResults: "search/hasResults",
    }),
    query: {
      get() {
        return this.$store.state.search.additionalFacets.searchTerm;
      },
      set(q) {
        // set new search term
        this.setAdditionalFacet({ key: "searchTerm", value: q });

        // reset shown of inactives
        // and reset pagination settings
        this.setAdditionalFacet({ key: "showInactive", value: false });
        this.resetPagination();
      },
    },
  },
  created() {
    if (this.$route.name === "showSku") {
      this.query = this.$route.params.id;
      this.onSearchVia("ident");
    }
  },
  methods: {
    t(key, options) {
      const prefix = "connected.search.hintBoxModal";
      return this.$t(`${prefix}.${key}`, options);
    },

    ...mapMutations({
      setAdditionalFacet: "search/setAdditionalFacet",
      resetPagination: "search/resetPagination",
    }),
    async onSubmit() {
      await this.$store.dispatch("search/load", "result");

      if (this.isCustomerItem) {
        this.$store.commit("global/setHintBoxModal", {
          headline: this.t("headline"),
          content: this.t("content"),
          showHintBoxModal: true,
        });
      } else {
        this.onSearchVia("any");
      }
    },
    async onSearchVia(via) {
      // @see https://developers.google.com/gtagjs/reference/event
      this.$track({
        search_term: this.query,
      });
      this.isExpanded = false;

      if (via === "ident") {
        await this.$store.dispatch("search/load", "result");

        if (this.isCustomerItem) {
          this.$store.commit("global/setHintBoxModal", {
            headline: this.t("headline"),
            content: this.t("content"),
            showHintBoxModal: true,
          });
        }
      }

      this.$store.commit("detail/reset");
      this.$store.commit("search/reset");
      this.$store.commit("compare/reset");
      this.setAdditionalFacet({ key: "searchTermType", value: via });

      await this.$store.dispatch("search/load", "search");

      /* NEBU-758 after no results the input is still focussed but the combobox
       * is closed. Explicitely re-focus the input so the combobox reappears.
       */
      if (!this.hasResults && !this.isCustomerItem) {
        this.onFocusInput();
      }
    },
    onSetCategory(categoryId) {
      this.setAdditionalFacet({ key: "groupCode", value: categoryId });
      this.onSearchVia("any");
    },
    onRemoveCategory() {
      if (this.$store.state.search.additionalFacets.groupCode.length > 0) {
        this.setAdditionalFacet({ key: "groupCode", value: "" });
      }
    },
    onFocusInput() {
      const $search = this.$refs.search.$el.querySelector(
        'input[type="search"]'
      );
      $search.focus();
      $search.dispatchEvent(new Event("focus"));
      setTimeout(() => {
        this.isExpanded = true;
      }, 50);
    },
  },
};
</script>

<style lang="scss" module>
@import "~@webprojects/ui-pattern-library/src/stylesheets/environment";

.reset {
  margin-left: get-space(m) !important;
}
</style>
