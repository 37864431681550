<template>
  <div
    :class="[
      $style.root,
      $style[`root--${arrow}`],
      { [$style['root--align-top']]: isAlignTop },
      { [$style['root--small']]: isSmall },
      { [$style['root--truly-centered']]: isTrulyCentered },
    ]"
  >
    <div :class="$style.inner">
      <BuAtomSvgSprite
        id="share"
        :class="[$style.arrow, $style[`arrow--${arrow}`]]"
      />
      <div :class="$style.text">
        <div
          :class="$style['text__headline']"
          :modifier="['big']"
          :innerHTML="text"
        />
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
/**
 * @file global note style
 */
export default {
  props: {
    text: {
      type: String,
      default: "",
    },
    arrow: {
      type: String,
      default: "top",
    },
    isSmall: {
      type: Boolean,
      default: false,
    },
    isAlignTop: {
      type: Boolean,
      default: false,
    },
    isTrulyCentered: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" module>
@import "~@webprojects/ui-pattern-library/src/stylesheets/environment";

$space: 200px;

.root {
  height: 100%;
  overflow: auto;
}

.inner {
  position: relative;
  display: flex;
  width: 100%;
  min-height: 100%;
  padding: 0 get-space(l);
  background-color: get-color(blue-light);
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .root:not(.root--small) {
    @include mq(m) {
      padding: 0 $space;
    }
  }

  .root--bottom & {
    flex-direction: column-reverse;
  }

  .root--align-top & {
    justify-content: flex-start;
  }
}

.arrow {
  height: 100px !important;
  width: 100px !important;
  color: get-color(light) !important;

  @include mq(m) {
    height: 150px !important;
    width: 150px !important;
    position: absolute;
  }

  &--top {
    transform: rotate(-90deg);
    top: get-space(m);
    left: get-space(m);
  }

  &--bottom {
    transform: rotate(-90deg) scaleX(-1);
    bottom: get-space(m);
    left: get-space(m);
  }

  &--left {
    transform: scaleX(-1);
    left: get-space(m);
    top: get-space(m);
  }

  &--right {
    transform: rotate(90deg) scaleX(-1);
    right: get-space(m);
    top: get-space(m);
  }
}

.text {
  $space: 4px;
  margin-top: -1 * get-space(l); // make it visually vertically centered by removing the height of the layout section title
  max-width: 580px;
  padding: 2 * get-space(l) 0 get-space(l) 0;

  .root--truly-centered & {
    margin-top: 0;
  }

  .root--align-top & {
    margin-top: get-space(m) + 100px + get-space(m) - get-space(l);

    @include mq(m) {
      margin-top: get-space(m) + 150px + get-space(s) - get-space(l);
    }
  }

  &__headline {
    @include text-type-headline-big;
    color: get-color(blue-dark);
    line-height: 36px;
  }

  i {
    font-style: normal;
    background-color: get-color(light);
    display: inline;
    background: get-color(light);
    padding: $space 0;
    box-shadow: $space 0 0 get-color(light), -($space) 0 0 get-color(light);
  }
}
</style>
