import getInitialState from "./state";

/**
 * Reset/Unset categories
 * @param {Object} state - Vuex state
 */
export const reset = (state) => {
  Object.assign(state, getInitialState());
};

/**
 * Set active product categories
 * @param {Object} state - Vuex state
 * @param {string[]} active - Activity-Path, e.g. ["0", "1", "1_9"]
 */
export const setActive = (state, active) => {
  state.active = active;
};

/**
 * Set product categories data
 * @param {Object} state - Vuex state
 * @param {Object} data - Normalized product categories data
 */
export const setData = (state, { error, data }) => {
  state.data.lastUpdated = Date.now();
  state.data.error = error;
  state.data.data = data;
};

/**
 * Set whether product categories are currently loading
 * @param {Object} state - Vuex state
 * @param {boolean} isLoading - Whether product categories are loading
 */
export const setIsLoading = (state, isLoading) => {
  state.data.isLoading = isLoading;
};
