<template>
  <OrganismSplitter
    :min-size="250"
    :sizes="[20, 80]"
    :snap-offset="0"
    :class="$style.root"
    tag="main"
  >
    <template #a>
      <aside :class="$style.sidebar">
        <slot name="sidebar">
          <div :class="$style.initial" />
        </slot>
      </aside>
    </template>

    <template #b>
      <div :class="$style.right">
        <slot name="fullcontent">
          <OrganismSplitter
            v-if="hasSlot('table') || hasSlot('content')"
            :snap-offset="0"
            :class="$style['right__content-wrapper']"
            direction="vertical"
          >
            <template #a>
              <section :class="$style['right__table']">
                <slot name="table" />
              </section>
            </template>
            <template #b>
              <section :class="$style['right__content']">
                <slot name="content" />
              </section>
            </template>
          </OrganismSplitter>
          <div v-else :class="$style.initial">
            <MoleculeNote :text="t('initialNote')" />
          </div>
        </slot>
        <AtomBackdrop :is-visible="contentIsMuted" />
      </div>
    </template>
  </OrganismSplitter>
</template>

<script>
/**
 * @file Draggable splitter layout providing a sidebar and two content areas
 */
import AtomBackdrop from "../atoms/AtomBackdrop.vue";
import MoleculeNote from "../molecules/MoleculeNote.vue";
import OrganismSplitter from "../organisms/OrganismSplitter.vue";

export default {
  components: {
    AtomBackdrop,
    MoleculeNote,
    OrganismSplitter,
  },

  props: {
    contentIsMuted: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    t(key, options) {
      const prefix = "components.templates.main";
      return this.$t(`${prefix}.${key}`, options);
    },
    hasSlot(name = "default") {
      return !!this.$slots[name] || !!this.$slots[name];
    },
  },
};
</script>

<style lang="scss" module>
@import "~@webprojects/ui-pattern-library/src/stylesheets/environment";

.root {
  height: 100%;
}

.sidebar {
  height: 100%;
}

.right {
  position: relative;
  height: 100%;

  &__table {
    height: 100%;
  }

  &__content {
    height: 100%;
  }

  &__content-wrapper {
    height: 100%;
  }
}

.initial {
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
  background-color: get-color(blue-light);

  padding: {
    top: get-space(l) + 2px;
  }

  &:before {
    position: absolute;
    top: 0;
    content: "";
    display: block;
    width: 100%;
    height: get-space(l) + 2px;

    border: {
      top: 1px solid get-color(light);
      bottom: 1px solid get-color(light);
    }
  }
}
</style>
