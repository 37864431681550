<template>
  <MoleculeHintbox :class="$style.root" :text="t('message')">
    <template #actions>
      <div :class="$style.btn">
        <BuAtomButton
          :modifier="['light', 'small']"
          :text="t('btnLabel')"
          @button-click="$emit('refresh')"
        />
      </div>
    </template>
  </MoleculeHintbox>
</template>

<script>
import MoleculeHintbox from "./MoleculeHintbox.vue";

export default {
  components: {
    MoleculeHintbox,
  },
  emits: ["refresh"],
  methods: {
    t(key, options) {
      const prefix = "components.molecules.hintboxUpdate";
      return this.$t(`${prefix}.${key}`, options);
    },
  },
};
</script>

<style lang="scss" module>
@import "~@webprojects/ui-pattern-library/src/stylesheets/environment";

.root {
  bottom: 0;
  left: get-space(m);
  position: fixed;
  z-index: 999;
}

.btn {
  margin-left: get-space(m);
}
</style>
