<template>
  <component :is="tag" role="heading" :aria-level="ariaLevel">
    <slot />
  </component>
</template>

<script>
export default {
  props: {
    tag: {
      type: String,
      default: "div"
    },
    ariaLevel: {
      type: Number,
      default: 3
    }
  }
};
</script>
