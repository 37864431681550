<template>
  <div :class="$style.root">
    <AtomPlaceholder :class="$style.hl" />

    <div :class="$style.nav">
      <AtomPlaceholder :class="$style['nav__item']" />
      <AtomPlaceholder :class="$style['nav__item']" />
      <AtomPlaceholder :class="$style['nav__item']" />
      <AtomPlaceholder :class="$style['nav__item']" />
      <AtomPlaceholder :class="$style['nav__item']" />
    </div>
  </div>
</template>

<script>
import AtomPlaceholder from "../atoms/AtomPlaceholder.vue";

export default {
  components: { AtomPlaceholder },
};
</script>

<style lang="scss" module>
@import "~@webprojects/ui-pattern-library/src/stylesheets/environment";

.root {
  padding: get-space(l);
}

.hl {
  height: 32px;
  margin-bottom: get-space(s2);
  width: 33%;
}

.nav {
  display: flex;
  flex-wrap: wrap;

  &__item {
    height: 20px;
    margin: 0 get-space(l) get-space(s) 0;
    width: 70px;
  }
}
</style>
