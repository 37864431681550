<template>
  <TemplateMain :content-is-muted="contentIsMuted">
    <template v-if="isLoading || (hasResults && hasFilterGroups)" #sidebar>
      <FacetFilter />
    </template>

    <template v-if="isInitial" #fullcontent>
      <OrganismLayoutSection>
        <MoleculeNote :text="$t('components.templates.main.initialNote')" />
      </OrganismLayoutSection>
    </template>
    <template
      v-else-if="
        !isLoading && (!hasResults || hasTooManyResults || hasResultsError)
      "
      #fullcontent
    >
      <OrganismLayoutSection>
        <ResultsError
          :error="error"
          :has-no-results="!hasResults"
          :has-too-many-results="hasTooManyResults"
        />
      </OrganismLayoutSection>
    </template>
    <template v-else-if="isLoading || hasResults" #table>
      <Results />
    </template>

    <template v-if="isLoading || hasResults" #content>
      <Compare v-if="hasComparison" />
      <Detail v-else />
    </template>
  </TemplateMain>
</template>

<script>
import { mapState, mapGetters } from "vuex";

import Compare from "../connected/compare/Compare.vue";
import Detail from "../connected/detail/Detail.vue";
import FacetFilter from "../connected/facet-filter/FacetFilter.vue";
import Results from "../connected/results/Results.vue";
import ResultsError from "../connected/results/ResultsError.vue";

import MoleculeNote from "../styleguide/molecules/MoleculeNote.vue";
import OrganismLayoutSection from "../styleguide/organisms/OrganismLayoutSection.vue";
import TemplateMain from "../styleguide/templates/TemplateMain.vue";

export default {
  components: {
    Compare,
    Detail,
    FacetFilter,
    Results,
    ResultsError,

    MoleculeNote,
    OrganismLayoutSection,
    TemplateMain,
  },

  computed: {
    ...mapState({
      contentIsMuted: (state) => state.global.isContentMuted,
      error: (state) => state.search.result.error,
      hasComparison: (state) =>
        state.compare.skus.length > 0 && state.compare.isVisible,
      hasFilterGroups: (state) => {
        const { filterGroups } = state.search.result.data;
        return filterGroups && filterGroups.length > 0;
      },
      hasResultsError: (state) => !!state.search.result.error,
      isInitial: (state) =>
        !state.search.result.isLoading &&
        state.search.result.lastUpdated === -1,
      isLoading: (state) =>
        state.search.result.isLoading &&
        state.search.result.actionOrigin === "search",
    }),
    ...mapGetters({
      hasResults: "search/hasResults",
      hasTooManyResults: "search/hasTooManyResults",
    }),
  },
};
</script>
