import { ref, reactive, provide, inject } from "vue";

const dispatchTrackingEvent = data => {
  (window.dataLayer = window.dataLayer || []).push(data);
};

const VueTrackingMixin = {
  setup() {
    const _parentTracking = inject("tracking", ref({}));
    const _parentTrackingOptions = inject(
      "trackingOptions",
      ref({
        dispatch: dispatchTrackingEvent
      })
    );
    const tracking = reactive({
      ..._parentTracking.value,
      ...this.$options.tracking
    });
    const trackingOptions = reactive({
      ..._parentTrackingOptions.value,
      ...this.$options.trackingOptions
    });
    provide("tracking", tracking);
    provide("trackingOptions", trackingOptions);
    return {
      tracking,
      trackingOptions
    };
  }
};
const VueTrackingDirective = {
  beforeMount(el, binding) {
    el.addEventListener(binding.arg, ev => {
      ev.preventDefault();
      binding.instance.$track(binding.value);
    });
  }
};
const VueTracking = {
  install: app => {
    app.mixin(VueTrackingMixin);
    app.directive("track", VueTrackingDirective);
    app.config.globalProperties.$track = function(data = {}, options = {}) {
      const totalData = { ...this.tracking, ...data };
      const totalOptions = { ...this.trackingOptions, ...options };
      totalOptions.dispatch(totalData);
    };
  }
};
export default VueTracking;
