<template>
  <div :class="$style.root">
    <label
      :id="`${id}__label`"
      :class="[$style.label, { [$style['label--is-hidden']]: !showLabel }]"
      :for="`${id}__input`"
      >{{ label }}</label
    >
    <div
      :id="`${id}__combobox`"
      :aria-expanded="`${isExpanded}`"
      :aria-owns="`${id}__popup`"
      :class="$style.inner"
      aria-haspopup="listbox"
      role="combobox"
    >
      <BuMoleculePopup
        id="popup_search"
        role="listbox"
        :is-opened="isExpanded"
        :popper-options="popperOptions"
        @update:is-opened="setIsExpanded($event)"
      >
        <template #handler>
          <form @submit.prevent="onSubmit">
            <BuAtomInput
              :id="`${id}__input`"
              ref="input"
              v-mousetrap.prevent="['/', 's']"
              :aria-controls="`${id}__popup`"
              :attributes="[
                { key: 'autocomplete', value: 'off' },
                { key: 'autofocus' },
              ]"
              :disabled="disabled"
              :modifier="['secondary', 'with-icon']"
              :placeholder="t('inputPlaceholder')"
              :value="value"
              aria-autocomplete="list"
              icon="search"
              type="search"
              @focus="onFocus"
              @input="$emit('update:value', $event)"
              @keydown="onKeydown"
              @mousetrap="onMousetrap"
              @submit="onSubmit"
            >
              <template v-if="$slots.inputBefore" #before>
                <slot name="inputBefore" />
              </template>
            </BuAtomInput>
          </form>
          <slot name="resetBtn" />
        </template>

        <div ref="popup" :class="$style['popup__inner']">
          <slot name="popup" />
        </div>
      </BuMoleculePopup>
    </div>
  </div>
</template>

<script>
/**
 * @file WAI-ARIA 3.8 Combo Box https://www.w3.org/TR/wai-aria-practices/#combobox
 */

const popperOptions = {
  placement: "bottom-start",
  modifiers: {
    flip: {
      enabled: false,
    },
    offset: {
      offset: "0, -1",
    },
  },
};

export default {
  props: {
    id: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    showLabel: {
      type: Boolean,
      default: false,
    },
    value: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    isExpanded: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["update:value", "update:isExpanded", "submit", "removeBefore"],

  data() {
    return {
      popperOptions,
    };
  },

  methods: {
    t(key, options) {
      const prefix = "components.molecules.searchPopupComboBox";
      return this.$t(`${prefix}.${key}`, options);
    },
    setIsExpanded(isExpanded) {
      this.$emit("update:isExpanded", isExpanded);
    },
    onFocus() {
      this.setIsExpanded(true);
    },
    onSubmit(ev) {
      this.setIsExpanded(false);
      this.$emit("submit", ev);
    },
    onMousetrap() {
      this.$refs.input.$el.querySelector("input").focus();
    },
    onKeydown(ev) {
      switch (ev.key) {
        case "Backspace": {
          if (ev.target.value === "") {
            this.$emit("removeBefore");
          }
          break;
        }

        case "Escape": {
          this.$refs.input.$el.querySelector("input").blur();
          this.setIsExpanded(false);
          break;
        }

        case "ArrowDown": {
          this.$refs.popup.querySelector("button").focus();
          break;
        }

        case "ArrowUp": {
          const $buttons = this.$refs.popup.querySelectorAll(
            "[data-roving-tabindex-container]:first-child button"
          );
          $buttons[$buttons.length - 1].focus();
          break;
        }

        default: {
          break;
        }
      }
    },
  },
};
</script>

<style lang="scss" module>
@import "~@webprojects/ui-pattern-library/src/stylesheets/environment";

.root {
  position: relative;

  form {
    width: 100%;
    max-width: 862px; // three-col combobox width
  }
}

.label--is-hidden {
  @extend %visually-hidden;
}

.popup__inner {
  // 20px top margin + 50px search input + 40px inner padding + 5px bottom margin + 2px border
  max-height: calc(100vh - 117px);

  &:after {
    content: "";
    display: block;
    height: get-space(m);
    margin-bottom: -1 * get-space(m);
  }
}
</style>
