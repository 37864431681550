/**
 * Get whether product categories are loaded
 * @returns {Boolean} Whether product categories are loaded
 */
export const getIsNotEmpty = (state) => Object.keys(state.data.data).length > 0;

/**
 * Get product categories split up into columns for display in a cascader
 * @example
 *  [[{}, {}], [{}, {}, {}]]
 * @returns {Object[][]} Array of Arrays of Objects containing product categories
 */
export const getColumns = (state, getters) => {
  if (getters.getIsNotEmpty) {
    return state.active.map(
      (active) => state.data.data.entities.categories[active]
    );
  }
  return [];
};
