<template>
  <th
    :aria-sort="sortable && (sortBy || 'none')"
    :class="[
      $style.root,
      { [$style.sortable]: sortable },
      { [$style.sorted]: sortable && sortBy },
      { [$style[`sorted-${sortBy}`]]: sortable && sortBy },
    ]"
    :tabindex="sortable && 0"
    role="columnheader"
    scope="col"
    @click="onClick"
    @keydown.enter.prevent="onClick"
  >
    <span :class="$style.inner">
      <span v-if="!abbr">{{ text }}</span>
      <abbr v-if="abbr" :title="text">{{ abbr }}</abbr>
      <BuAtomSvgSprite id="up" :class="$style['sortable-icon']" />
    </span>
  </th>
</template>

<script>
/**
 * @file Single table header cell
 */
export default {
  inheritAttrs: false,

  props: {
    abbr: {
      type: String,
      default: null,
    },
    sortable: {
      type: Boolean,
      default: false,
    },
    text: {
      type: String,
      required: true,
    },
    value: {
      type: String,
      required: true,
    },

    /**
     * Two-Way prop containing the current sort state
     */
    sortBy: {
      type: String,
      default: null,
      validator: (val) => !val || ["asc", "desc"].includes(val),
    },
  },

  emits: ["update:sortBy"],

  methods: {
    onClick() {
      if (!this.sortable) return;

      let next = null;
      if (!this.sortBy) {
        next = "asc";
      } else if (this.sortBy === "asc") {
        next = "desc";
      }

      this.$emit("update:sortBy", next);
    },
  },
};
</script>

<style lang="scss" module>
@import "~@webprojects/ui-pattern-library/src/stylesheets/environment";

.root {
  text-align: left;
  white-space: nowrap;

  [data-whatintent="mouse"] &,
  [data-whatintent="touch"] & {
    outline: 0;
  }
}

.inner {
  display: flex;
  height: 20px;
}

.sortable-icon {
  margin-left: 4px;
  opacity: 0;
  transition: all 0.2s ease;
}

.sortable {
  cursor: pointer;
  user-select: none;
}

.sortable:not(.sorted):hover,
.sortable:not(.sorted):focus {
  .sortable-icon {
    opacity: 0.5;
  }
}

.sorted .sortable-icon {
  opacity: 1;
}

.sorted-desc .sortable-icon {
  transform: rotate(180deg);
}
</style>
