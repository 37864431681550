<template>
  <OrganismLayoutSection :is-loading="isUpdating">
    <template #title>{{ t("headline") }}</template>

    <OrganismFacetFilterLoading v-if="isLoading || isUpdating" />

    <OrganismFacetFilter v-else v-model:pre-expanded="expandedSections">
      <MoleculeFacetFilterSection
        v-for="(filterGroup, i) in filterGroups"
        :key="filterGroup.name"
        :uuid="filterGroup.name"
        :filter-count="filterCount[i]"
        :filter-group="filterGroup"
      >
        <MoleculeFacetFilterGroup
          :key="`facet-group-${i}`"
          v-slot="{ visibleFilters, onClose }"
          v-model:expanded-filters="expandedFilters"
          :filter-group="filterGroup"
          :reveal-after="i === 0 ? 0 : 5"
        >
          <template
            v-for="filter in visibleFilters"
            :key="`facet-group-item-${filter.id}`"
          >
            <MoleculeFacetFilterGroupItem
              :filters-with-title="filtersWithTitle[filter.id]"
              :value="filters[filter.id]"
              v-bind="filter"
              @close="onClose"
              @remove-filter="onRemoveFacetFilter"
              @set-filter="onSetFacetFilter"
            />
          </template>
        </MoleculeFacetFilterGroup>
      </MoleculeFacetFilterSection>
    </OrganismFacetFilter>
  </OrganismLayoutSection>
</template>

<script>
import { mapState, mapGetters } from "vuex";

import MoleculeFacetFilterGroup from "../../styleguide/molecules/MoleculeFacetFilterGroup.vue";
import MoleculeFacetFilterGroupItem from "../../styleguide/molecules/MoleculeFacetFilterGroupItem.vue";
import MoleculeFacetFilterSection from "../../styleguide/molecules/MoleculeFacetFilterSection.vue";
import OrganismFacetFilter from "../../styleguide/organisms/OrganismFacetFilter.vue";
import OrganismFacetFilterLoading from "../../styleguide/organisms/OrganismFacetFilterLoading.vue";
import OrganismLayoutSection from "../../styleguide/organisms/OrganismLayoutSection.vue";

export default {
  components: {
    MoleculeFacetFilterGroup,
    MoleculeFacetFilterGroupItem,
    MoleculeFacetFilterSection,
    OrganismFacetFilter,
    OrganismFacetFilterLoading,
    OrganismLayoutSection,
  },

  data() {
    return {
      /**
       * Currently expanded section accordion elements
       */
      expandedSections: [],

      /**
       * Currently expanded filter accordion elements
       * By using the same array for all FilterGroup accordions, only one can
       * be open at a time.
       */
      internalExpandedFilters: [],
    };
  },

  computed: {
    ...mapState({
      filterGroups: (state) => state.search.result.data.filterGroups,
      filters: (state) => state.search.filters,
      isLoading: (state) => {
        const { actionOrigin, isLoading } = state.search.result;
        return isLoading && actionOrigin === "search";
      },
      isUpdating: (state) => {
        const { actionOrigin, isLoading } = state.search.result;
        return isLoading && actionOrigin === "facet";
      },
    }),
    ...mapGetters({
      availableFilters: "search/getFlattenedFiltersObj",
      filterCount: "search/filterCount",
      filtersWithTitle: "search/getSetFiltersWithTitle",
    }),

    expandedFilters: {
      set(expandedFilters) {
        this.internalExpandedFilters = expandedFilters;
      },

      get() {
        // NEBU-800 make sure that all expanded filters are also available
        return this.internalExpandedFilters.filter((id) =>
          ({}.hasOwnProperty.call(this.availableFilters, id))
        );
      },
    },
  },

  watch: {
    filterGroups(filterGroups = []) {
      this.expandedSections = filterGroups.map((fg) => fg.name);
    },
    expandedFilters(expandedFilters) {
      const filterIsOpened = expandedFilters.length > 0;
      this.$store.commit("global/setIsContentMuted", filterIsOpened);
    },
  },

  methods: {
    t(key, options) {
      const prefix = "connected.facetFilter";
      return this.$t(`${prefix}.${key}`, options);
    },
    /**
     * Event handler for when a value gets removed
     * @param {Object} options
     * @param {string} options.filterId - ID of the filter that should be removed
     * @param {string} options.filterValue - To be removed filter value
     * @returns {void}
     */
    onRemoveFacetFilter({ filterId, filterValue }) {
      if (Array.isArray(this.filters[filterId].value)) {
        const value = this.filters[filterId].value.filter(
          (x) => x !== filterValue
        );
        this.$store.commit("search/setFilter", {
          key: filterId,
          value: { value },
        });
      } else {
        this.$store.commit("search/setFilter", { key: filterId });
      }
      this.$store.commit("compare/reset");
      this.$store.dispatch("search/load", "facet");
    },

    /**
     * Event handler for when a value gets set
     * @param {Object} options
     * @param {string} options.filterId - ID of the filter that should be set
     * @param {string} options.filterValue - To be set filter value
     * @returns {void}
     */
    onSetFacetFilter({ filterId, filterValue }) {
      this.$store.commit("search/setFilter", {
        key: filterId,
        value: filterValue,
      });
      this.$store.commit("search/resetPagination");
      this.$store.commit("compare/reset");
      this.$store.dispatch("search/load", "facet");
    },
  },
};
</script>
