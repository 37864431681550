<template>
  <BuOrganismModalNew
    :modifier="['small', 'no-content-padding']"
    :headline="{ text: headline }"
    :is-opened="showHintBoxModal"
    @update:is-opened="resetHintBox()"
  >
    <p>{{ content }}</p>
  </BuOrganismModalNew>
</template>

<script>
import { mapState } from "vuex";

export default {
  computed: {
    ...mapState({
      headline: (state) => state.global.hintBoxModal.headline,
      content: (state) => state.global.hintBoxModal.content,
      showHintBoxModal: (state) => state.global.hintBoxModal.showHintBoxModal,
    }),
  },

  methods: {
    resetHintBox() {
      this.$store.commit("global/setHintBoxModal", {
        headline: "",
        content: "",
        showHintBoxModal: false,
      });
    },
  },
};
</script>

<style scoped></style>
