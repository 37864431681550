<template>
  <div :class="$style.root">
    <div :class="$style.title">
      <AtomLoaderBar
        :class="$style['title__progress']"
        :is-loading="isLoading"
      />

      <div :class="$style['title__inner']">
        <h4 :class="$style['title__title']">
          <slot name="title" />
        </h4>
        <div v-if="$slots.actions" :class="$style['title__actions']">
          <slot name="actions" />
        </div>
      </div>
    </div>
    <div :class="$style.content" :aria-busy="`${isLoading}`">
      <slot />
    </div>
  </div>
</template>

<script>
import AtomLoaderBar from "../atoms/AtomLoaderBar.vue";

export default {
  components: {
    AtomLoaderBar,
  },

  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" module>
@import "~@webprojects/ui-pattern-library/src/stylesheets/environment";

.root {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 42px 1fr;
  height: 100%;
}

.title {
  align-items: center;
  background-color: get-color(blue-light);
  border-bottom: 1px solid get-color(light);
  border-top: 1px solid get-color(light);
  display: flex;
  grid-row: 1;
  position: relative;

  &__inner {
    display: grid;
    flex: 1 1 0%;
    grid-gap: get-space(s);
    grid-template-columns: auto 1fr;
    grid-template-rows: auto;
    padding: 0 get-space(m);
  }

  &__title {
    @include text-type-copy-small-extended;
    margin: auto 0;
    overflow: hidden;
    position: relative;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &__progress {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    margin: 0;
  }

  &__actions {
    display: flex;
    justify-content: flex-end;

    > * {
      margin-left: get-space(s);

      &:first-of-type {
        margin-left: 0;
      }
    }
  }
}

.content {
  -webkit-overflow-scrolling: touch;
  grid-row: 2;
  overflow: auto;
}
</style>
