/**
 * Creates an object with only some keys
 *
 * @param {Object} obj - The source object
 * @param {string[]} keys - The properties to pick
 * @returns {Object} Object with only `keys`
 */
export const pick = (obj, keys) =>
  Object.keys(obj).reduce(
    (acc, cur) => (keys.includes(cur) ? { ...acc, [cur]: obj[cur] } : acc),
    {}
  );

/**
 * Creates an object omitting some keys
 *
 * @param {Object} obj - The source object
 * @param {string[]} keys - The keys to omit
 * @returns {Object} Object without `keys`
 */
export const omit = (obj, keys) =>
  Object.keys(obj).reduce(
    (acc, cur) => (!keys.includes(cur) ? { ...acc, [cur]: obj[cur] } : acc),
    {}
  );
