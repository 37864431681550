/**
 * Dispatch a tracking event using window.gtag
 *
 * @see https://developers.google.com/analytics/devguides/collection/gtagjs/events
 * @param {Object} data - Tracking data with additional properties hitType and eventAction
 * @returns {void}
 */
const dispatchTrackingEvent = (data) => {
  const { hitType = "event", eventAction, ...eventData } = data;

  if (process.env.NODE_ENV !== "production") {
    // eslint-disable-next-line no-console
    console.log("dispatch tracking:", hitType, eventAction, eventData);
  }

  if (window.gtag) {
    window.gtag(hitType, eventAction, eventData);
  }
};

export default dispatchTrackingEvent;
