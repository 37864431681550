<template>
  <form :class="$style.root" @submit.prevent="onSubmit">
    <ul :class="$style.operators">
      <li v-for="op in operators" :key="op" :class="$style.operator">
        <BuAtomRadioButton
          :modifier="['variant']"
          :selected-value="operator"
          :value="op"
          name="operator"
          @input="() => onOperatorInput(op)"
          >{{ op }}</BuAtomRadioButton
        >
      </li>
    </ul>
    <ul :class="$style.options">
      <li v-for="option in options" :key="option.value" :class="$style.option">
        <BuAtomRadioButton
          :value="option.value"
          :selected-value="value"
          :modifier="['variant']"
          name="value"
          @input="() => onValueInput(option.value)"
        >
          <div :class="$style['option-label']">
            <span :class="$style['option-label__title']">
              {{ option.title }}
            </span>
            <span :class="$style['option-label__count']">{{
              option.resultCount
            }}</span>
          </div>
        </BuAtomRadioButton>
      </li>
    </ul>
    <button :class="$style['btn-submit']" tabindex="-1" type="submit">
      Submit
    </button>
  </form>
</template>

<script>
import WhatIntentMixin from "../../../lib/WhatIntentMixin";

export default {
  mixins: [WhatIntentMixin],

  props: {
    defaultOperator: {
      type: String,
      default: ">=",
    },
    operators: {
      type: Array,
      required: true,
    },
    options: {
      type: Array,
      required: true,
    },

    /**
     * Store-Set value, i.e. an Object with keys `operator` and `value`
     */
    filterValue: {
      type: Object,
      default: () => ({}),
    },
  },
  emits: ["update:filter-value"],

  data() {
    return {
      operator: this.filterValue.operator || this.defaultOperator,
      value: this.filterValue.value,
    };
  },

  watch: {
    /**
     * Update internal state when external state changes
     */
    filterValue({ operator, value }) {
      this.operator = operator || this.defaultOperator;
      this.value = value || [];
    },
  },

  methods: {
    onOperatorInput(operator) {
      this.operator = operator;
      this.value = null;
    },

    onValueInput(value) {
      this.value = value;

      if (this.whatIntent !== "keyboard") {
        this.onSubmit();
      }
    },

    /**
     * Update external state when internal state changes
     */
    onSubmit() {
      const { operator, value } = this;

      // do not emit if value is not set
      if (!value) return;

      this.$emit("update:filter-value", {
        operator,
        value,
      });
    },
  },
};
</script>

<style lang="scss" module>
@import "~@webprojects/ui-pattern-library/src/stylesheets/environment";

.root {
  display: grid;
  grid-template-columns: 90px 1fr;
  grid-template-rows: 1fr;
}

.operators,
.options {
  list-style: none;
  margin: 0;
  padding: 0;
}

.operators {
  border-right: 1px get-color(light) solid;
  grid-column: 1;
}

.operator {
  margin: get-space(m);
}

.options {
  grid-column: 2;
}

.option {
  border-bottom: 1px get-color(light) solid;
  padding: get-space(m);

  &:last-child {
    border-bottom: 0;
  }
}

.option-label {
  display: grid;
  grid-template-columns: 1fr auto;
  grid-template-rows: 1fr;

  &__title {
    grid-column: 1;
    hyphens: auto;
  }

  &__count {
    @include text-type-meta;
    grid-column: 2;
    margin-left: get-space(m);
  }
}

.btn-submit {
  @extend %visually-hidden;
  top: -999999em;
}
</style>
