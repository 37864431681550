import { getArticle, getParts, getPrice } from "../../../api";

const updateCustomerPrices = (data, prices) => {
  const updatedData = {
    ...(data || {}),
    product: {
      ...(data || { product: {} }).product,
      ...prices,
    },
  };
  return updatedData;
};

/**
 * Load the selected article
 */
export const load = async ({ commit, state }) => {
  const { sku } = state;
  if (!sku) {
    return;
  }

  commit("setIsLoading", true);

  try {
    const { data } = await getArticle(sku);
    commit("setData", { data });

    // set custom prices to session storage
    const customerPriceString = sessionStorage.getItem(
      `buerkert.product.${sku}`
    );
    if (customerPriceString) {
      const customerPrices = JSON.parse(customerPriceString);
      commit("setQuantity", Number(customerPrices.quantity));
      commit("setData", {
        data: {
          ...data,
          ...updateCustomerPrices(data, customerPrices.prices),
        },
      });
    } else {
      // store minOrderQuantity as default quantity
      if (data.product && data.product.minOrderQuantity) {
        commit("setQuantity", data.product.minOrderQuantity);
      }
    }
  } catch (e) {
    commit("setData", { error: e.format(), data: {} });
  }

  commit("setIsLoading", false);
};

/**
 * Load customer price for article
 */
export const loadCustomerPrice = async ({ commit, state }) => {
  const { sku, quantity, data } = state;
  if (!sku) {
    return;
  }

  commit("setIsRunningRequest", "loadCustomerPrice");

  try {
    const { prices } = await getPrice(sku, quantity);

    // add custom prices to session storage
    sessionStorage.setItem(
      `buerkert.product.${sku}`,
      JSON.stringify({ quantity, prices })
    );
    commit("setData", { data: updateCustomerPrices(data.data, prices) });
  } catch (e) {
    commit("setData", { error: e.format(), data: {} });
  }

  commit("setIsRunningRequest", false);
};

/**
 * Load parts / bill of material
 */
export const loadParts = async ({ commit, state }) => {
  const { sku } = state;
  if (!sku) {
    return;
  }

  commit("setPartsIsLoading", true);

  try {
    const { data } = await getParts(sku);
    commit("setPartsData", { data });
  } catch (e) {
    commit("setPartsData", { error: e.format(), data: {} });
  }

  commit("setPartsIsLoading", false);
};
