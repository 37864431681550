<template>
  <div>
    <BuAtomButton
      v-if="!isLoading && hasNext"
      :modifier="['small', 'light']"
      :text="t('label')"
      @button-click="onClickLoadMore"
    />
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  computed: {
    ...mapState({
      isLoading: (state) => state.search.result.isLoading,
      limit: (state) => state.search.pagination.limit,
      offset: (state) => state.search.pagination.offset,
      totalItems: (state) => state.search.result.data.pagination.total,
    }),

    hasNext() {
      return this.offset + this.limit < this.totalItems;
    },
  },

  methods: {
    t(key, options) {
      const prefix = "connected.pagination";
      return this.$t(`${prefix}.${key}`, options);
    },
    onClickLoadMore() {
      const nextOffset = this.offset + this.limit;

      this.$store.commit("search/setPagination", { offset: nextOffset });
      this.$store.dispatch("search/load", "result");
    },
  },
};
</script>
