<template>
  <div :class="$style.root">
    <div :class="$style.inner">
      <div :class="$style.header">
        <slot name="header" />
      </div>
      <div :class="$style.main">
        <slot name="main" />
      </div>
      <div :class="$style.footer">
        <slot name="footer" />
      </div>
    </div>
  </div>
</template>

<script>
/**
 * @file Main app layout providing a header and a main area
 */
export default {};
</script>

<style lang="scss" module>
@import "~@webprojects/ui-pattern-library/src/stylesheets/environment";

.inner {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr;
  height: 100vh;
}

.header {
  grid-row: 1;
}

.footer {
  grid-row: 3;
}

.main {
  grid-row: 2;
  overflow: hidden;
}
</style>
