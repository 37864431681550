<template>
  <MoleculeNote v-bind="$attrs">
    <form :class="$style.form" @submit.prevent="onSubmit">
      <BuAtomRadioButton
        v-for="c in computedChoices"
        :key="c.id"
        :value="c.id"
        :modifier="['big', 'variant']"
        :class="{ [$style.category]: isCategories, category: isCategories }"
        name="choice"
        @input="(e) => onInput(c)"
      >
        <template v-if="isCategories">
          <div :class="$style['category__subtitle']">{{ c.title[0] }}</div>
          <div :class="$style['category__title']">{{ c.title[1] }}</div>
        </template>
        <template v-else>{{ c.title }}</template>
      </BuAtomRadioButton>
      <button :class="$style['btn-submit']" tabindex="-1" type="submit">
        Submit
      </button>
    </form>
  </MoleculeNote>
</template>

<script>
import WhatIntentMixin from "../../../lib/WhatIntentMixin";
import MoleculeNote from "./MoleculeNote.vue";

export default {
  components: {
    MoleculeNote,
  },

  mixins: [WhatIntentMixin],

  props: {
    /**
     * Options to choose from
     */
    choices: {
      type: Array,
      default: () => [],
    },

    /**
     * Whether the displayed choices are product categories or not
     */
    type: {
      type: String,
      default: "default",
      validator: (value) => ["default", "categories"].includes(value),
    },
  },
  emits: ["choice"],

  data() {
    return {
      choice: null,
    };
  },

  computed: {
    isCategories() {
      return this.type === "categories";
    },
    computedChoices() {
      if (this.isCategories) {
        /**
         * Split the category into path and leaf making sure that at least the leaf is set
         * Example: Foo / Bar / Baz --> ["Foo / Bar /", "Baz"]
         * Example: FooBar --> [null, "FooBar"]
         */
        return this.choices.map((c) => ({
          ...c,
          title: (
            c.title.match(/^(.* \/) (.*)$/) || [null, null, c.title]
          ).slice(1),
        }));
      }
      return this.choices;
    },
  },

  mounted() {
    this.$el.querySelector("input").focus();
  },

  methods: {
    onInput(choice) {
      this.choice = choice.id;
      if (this.whatIntent !== "keyboard") {
        this.onSubmit();
      }
    },
    onSubmit() {
      this.$emit("choice", this.choice);
    },
  },
};
</script>

<style scoped>
.category :deep(.a-radio-button__custom-radio-button) {
  margin-top: 25px;
}
</style>

<style lang="scss" module>
@import "~@webprojects/ui-pattern-library/src/stylesheets/environment";

.form {
  margin-top: get-space(l);
}

.btn-submit {
  @extend %visually-hidden;
}

.category {
  margin-bottom: get-space(s) !important;

  &__subtitle {
    @include text-type-copy-small;
    min-height: 20px;
  }

  &__title {
    @include text-type-headline-big;
    color: get-color(blue-dark);
  }
}
</style>
