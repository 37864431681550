import getInitialState from "./state";

/**
 * Reset/Unset the article
 * @param {Object} state - Vuex state
 */
export const reset = (state) => {
  Object.assign(state, getInitialState());
};

/**
 * Set checkboxes force-visible
 * @param {Object} state - Vuex state
 * @param {Boolean} checkboxesEnabled - Whether checkboxes are force-enabled
 */
export const setCheckboxesEnabled = (state, checkboxesEnabled) => {
  state.checkboxesEnabled = checkboxesEnabled;
};

/**
 * Set the API response data
 * @param {Object} state - Vuex state
 * @param {Object} data - Response data
 */
export const setData = (state, { error, data }) => {
  state.data.lastUpdated = Date.now();
  state.data.error = error;
  state.data.data = data;
};

/**
 * Set whether data is loading
 * @param {Object} state - Vuex state
 * @param {Boolean} isLoading - Whether data is loading
 */
export const setIsLoading = (state, isLoading) => {
  state.data.isLoading = isLoading;
};

/**
 * Set whether compare list is visible
 * @param {Object} state - Vuex state
 * @param {Boolean} isVisible - Whether compare list is visible
 */
export const setIsVisible = (state, isVisible) => {
  state.isVisible = isVisible;
};

/**
 * Set whether only differences are shown
 * @param {Object} state - Vuex state
 * @param {Boolean} onlyDifferences - Whether to only show differences
 */
export const setOnlyDifferences = (state, onlyDifferences) => {
  state.onlyDifferences = onlyDifferences;
};

/**
 * Set the compare skus
 * @param {Object} state - Vuex state
 * @param {Object} skus - Response data
 */
export const setSkus = (state, skus) => {
  state.skus = skus;

  if (state.skus.length > 0) {
    state.isVisible = true;
  }
};

/**
 * Add or delete an SKU from the comparison
 * @param {Object} state - Vuex state
 * @param {Object} payload
 * @param {String} payload.sku - SKU that should be added/deleted
 * @param {Boolean} payload.inComparison - Default: true. Whether the SKU should be added or deleted
 */
export const setSkuInComparison = (state, { sku, inComparison = true }) => {
  const idx = state.skus.indexOf(sku);

  if (inComparison && idx === -1) {
    state.skus.push(sku);
  } else if (!inComparison) {
    state.skus.splice(idx, 1);
  }
};

/**
 * Set SKU that is highlighted as being a substitute
 * @param {Object} state - Vuex state
 * @param {String} substitute - SKU that is a substitute
 */
export const setSubstitute = (state, substitute) => {
  state.substitute = substitute;
};
