<template>
  <article
    ref="container"
    v-mousetrap="['d', 'v']"
    :class="$style.root"
    @mousetrap="initialFocus"
  >
    <!-- neew Branch -->
    <!-- Top buttons  -->
    <div style="display: flex; margin-top: 16px; margin-left: 20px">
      <!-- Compare Product -->
      <div v-if="$slots['compare']">
        <slot name="compare" />
      </div>
      <!-- Export as Word -->
      <div>
        <BuAtomButton
          ref="exportAsWord"
          :modifier="['link', 'small']"
          :text="t('export.title') + ' ' + t('export.type.word')"
          target="_blank"
          icon="download"
          @button-click="exportProduct('word')"
        />
      </div>
      <!-- Export as Excel -->
      <div>
        <BuAtomButton
          ref="exportAsExcel"
          :modifier="['link', 'small']"
          :text="t('export.title') + ' ' + t('export.type.excel')"
          target="_blank"
          icon="download"
          @button-click="exportProduct('excel')"
        />
      </div>
      <!-- print product -->
      <div>
        <BuAtomButton
          ref="printInfo"
          :modifier="['link', 'small']"
          text="Drucken"
          icon="print"
          @button-click="printInfo()"
        />
      </div>
    </div>
    <div id="productInfo" :class="$style.inner">
      <div ref="spy" :class="$style.spy" />
      <header
        ref="header"
        :class="[
          $style.header,
          { [$style['header--is-scrolled']]: isScrolled },
          { [$style['header--has-btn-back']]: previousProduct },
        ]"
      >
        <BuAtomLink
          v-if="previousProduct"
          :class="$style['header__btn-back']"
          icon="back"
          @button-click="openPreviousProduct"
        >
          {{ t("backToPreviousProduct") }}
        </BuAtomLink>

        <!-- HEADLINE -->
        <div style="margin-top: 16px" :class="$style['header__title-wrapper']">
          <BuAtomHeadline
            :class="$style['header__title']"
            :text="product.meta.title"
            :modifier="['big']"
          />
        </div>

        <!-- ONSITE NAVIGATION -->
        <nav>
          <ul
            id="nav_header"
            ref="nav"
            v-roving-tabindex-container.horizontal
            :class="$style['header__nav']"
          >
            <li v-for="section in product.sections" :key="section.id">
              <a
                v-roving-tabindex
                :class="[
                  $style['header__nav-item'],
                  {
                    [$style['header__nav-item--is-active']]:
                      section.id === activeSection,
                  },
                ]"
                :href="`#${section.id}`"
                @click.prevent="onGoToSection"
                >{{ section.title }}</a
              >
            </li>
          </ul>
        </nav>
      </header>

      <!-- NOTIFICATIONS -->
      <section v-if="computedNotifications" :class="$style.section">
        <MoleculeHintbox
          v-for="(
            { headline, text, type, substitute }, index
          ) in computedNotifications"
          :key="index"
          :headline="headline"
          :text="substitute ? t('substitute') : text"
          :modifier="[type]"
        >
          <template v-if="substitute">
            <span v-for="(sub, idx) in substitute" :key="sub.id">
              <BuAtomLink
                :modifier="['bold', 'underline']"
                @button-click="openSubstitute(sub.id)"
              >
                {{ sub.title }}
              </BuAtomLink>
              <span v-if="idx !== substitute.length - 1">, </span>
            </span>
          </template>
          <template v-if="substitute" #actions>
            <BuAtomButton
              :modifier="['small', 'light']"
              :text="t('substituteCompare')"
              @button-click="
                onCompareWithSubstitutes(substitute.map((sub) => sub.id))
              "
            />
          </template>
        </MoleculeHintbox>
      </section>

      <section
        v-for="section in product.sections"
        :id="section.id"
        :key="section.id"
        ref="section"
        :class="[$style.section, section.id]"
      >
        <BuAtomHeadline
          tag="h3"
          :text="section.title"
          :modifier="['small']"
          :class="$style.headline"
        />

        <!-- GENERAL -->
        <div
          v-if="section.type === 'general'"
          :class="$style['section__general']"
        >
          <BuMoleculeItemBox
            v-bind="section.data"
            id="test"
            :class="{
              [$style['section__item-box']]: true,
              [$style['section__item-box--has-price']]: product.product,
            }"
            :is-collapsed="false"
            :is-shrinked="false"
            :modifier="['standalone']"
            :disclaimer="t('imageDisclaimer')"
            :button="{}"
          >
            <template #subheadline>
              <dl :class="['m-item-box__subheadline', 'm-item-box__deflist']">
                <dt>{{ t("type") }}</dt>
                <dd>{{ section.data.productType }}</dd>
                <template v-if="section.data.productIdent">
                  <dt>{{ t("ident") }}</dt>
                  <dd>{{ section.data.productIdent }}</dd>
                </template>
                <template v-if="section.data.productStatus">
                  <dt>{{ t("status") }}</dt>
                  <dd>{{ section.data.productStatus }}</dd>
                </template>
              </dl>
            </template>
            <template
              v-if="section.data.productInfo && section.data.productInfo.length"
              #productinfo
            >
              <BuAtomList :list="section.data.productInfo"></BuAtomList>
            </template>
          </BuMoleculeItemBox>

          <!-- PRICE -->
          <MoleculeCustomerPrice
            v-if="product.product"
            :product="product.product"
            :sales-org="product.salesOrg"
          />
        </div>

        <!-- DOWNLOADS -->
        <div
          v-else-if="section.type === 'links-downloads'"
          :class="$style.contentrow"
        >
          <div
            v-for="(content, index) in section.data"
            :key="index"
            :class="$style.contentcol"
          >
            <BuMoleculeContentBox
              v-roving-tabindex-container
              v-bind="content"
              :headline="{ text: content.headline }"
              :modifier="['small']"
            >
              <BuAtomLink
                v-for="(link, lindex) in content.links"
                v-bind="link"
                :key="lindex"
                v-roving-tabindex
                :class="$style.contentlink"
                :modifier="['icon-right']"
                icon="externallink"
                target="_blank"
              />
            </BuMoleculeContentBox>
          </div>
        </div>

        <!-- PARTS -->
        <slot v-else-if="section.type === 'parts'" name="parts"></slot>

        <!-- SHIPPING || DATASHEET -->
        <div
          v-else-if="
            section.type === 'price-shipping' || section.type === 'datasheet'
          "
          :class="$style.contentrow"
        >
          <div
            v-for="(content, index) in section.data"
            :key="index"
            :class="$style.contentcol"
          >
            <MoleculeTable v-bind="content" :id="`table_${section.type}`" />
          </div>
        </div>
      </section>

      <aside v-if="product.exports" :class="$style.export">
        <BuMoleculePopup
          :id="`detail-export-${uid}`"
          :handler-attributes="{ title: t('export.title') }"
          :popper-options="{
            placement: 'top-end',
            positionFixed: true,
            modifiers: {
              offset: { offset: '15, 15' },
            },
          }"
          icon="download-alternate"
        >
          <BuMoleculeContextmenu
            :id="`detail-export-menu-${uid}`"
            :headline="t('export.title')"
          >
            <BuMoleculeContextmenuItem
              v-for="exp in product.exports"
              :key="exp.type"
              :href="exp.url"
              rel="nofollow noopener"
              target="_blank"
            >
              {{ t(`export.type.${exp.type}`) }}
            </BuMoleculeContextmenuItem>
          </BuMoleculeContextmenu>
        </BuMoleculePopup>
      </aside>
    </div>
  </article>
</template>

<script>
import { uuid } from "vue-uuid";
import MoleculeCustomerPrice from "../molecules/MoleculeCustomerPrice.vue";
import MoleculeHintbox from "../molecules/MoleculeHintbox.vue";
import MoleculeTable from "../molecules/MoleculeTable.vue";

import mixins from "../../../lib/GlobalMixins";

export default {
  components: {
    MoleculeCustomerPrice,
    MoleculeHintbox,
    MoleculeTable,
  },

  mixins: [mixins],

  props: {
    product: {
      type: Object,
      required: true,
    },
    previousProduct: {
      type: String,
      default: null,
    },
  },

  data() {
    return {
      previousSection: null,
      activeSection: null,
      isScrolled: false,
      uid: uuid.v4(),
    };
  },

  computed: {
    /**
     * API 1.0 compatibility wrapper: substitute now always is an Array
     */
    computedNotifications() {
      const { notifications } = this.product;

      if (!notifications) return;

      return notifications.map((notification) =>
        notification.substitute
          ? {
              ...notification,
              substitute: [].concat(notification.substitute),
            }
          : notification
      );
    },
  },

  mounted() {
    this.spyObserver = new IntersectionObserver(this.onObserveSpy, {
      root: this.$el,
    });

    this.spyObserver.observe(this.$refs.spy);

    this.sectionObserver = new IntersectionObserver(this.onObserveSection, {
      root: this.$el,
      rootMargin: `-${this.$refs.header.offsetHeight + 20}px 0px 20px 0px`,
      threshold: [0.25],
    });

    this.$refs.section.forEach(($section) =>
      this.sectionObserver.observe($section)
    );

    // TODO always call initialFocus except when the detail view is called from the results table
    if (this.previousProduct) {
      this.initialFocus();
    }
  },

  beforeUnmount() {
    this.spyObserver.disconnect();
    this.sectionObserver.disconnect();
  },

  methods: {
    t(key, options) {
      const prefix = "components.organisms.details";
      return this.$t(`${prefix}.${key}`, options);
    },
    async printInfo() {
      await this.$store.dispatch("detail/loadParts");
      let content = document.getElementById("productInfo")?.innerHTML || "";

      let stylesHtml = Array.from(
        document.querySelectorAll('link[rel="stylesheet"], style')
      )
        .map((node) => node.outerHTML)
        .join("");

      let printWindow = document.createElement("iframe");
      printWindow.style.display = "none";

      printWindow.srcdoc = `
        <html>
          <head>
            ${stylesHtml}
          </head>
          <body>
            ${content}
          </body>
        </html>
      `;

      printWindow.onload = () => {
        printWindow.contentWindow.onafterprint = () => {
          document.body.removeChild(printWindow);
        };
        printWindow.contentWindow.print();
      };
      document.body.appendChild(printWindow);
    },

    exportProduct(type) {
      const exportTyps = [];
      for (const exp of this.product.exports) {
        exportTyps.push({
          key: exp.type,
          href: exp.url,
        });
      }
      if (type == "word") {
        window.open(exportTyps[0].href);
      } else if (type == "excel") {
        window.open(exportTyps[1].href);
      }
    },
    test() {
      return true;
    },
    onObserveSection(ev) {
      const { isIntersecting, target } = ev[0];
      const direction =
        ev[0].boundingClientRect.y < ev[0].rootBounds.y ? "up" : "down";

      // Fixes for FF IntersectionObserver issue if multiple section are in viewport
      // on scroll down
      if (
        isIntersecting &&
        direction === "down" &&
        target.nextSibling !== null &&
        this.activeSection === target.nextSibling.id
      )
        return;

      // Fixes for FF IntersectionObserver issue if multiple section are in viewport
      // on scroll up
      if (
        isIntersecting &&
        direction === "up" &&
        target.previousSibling !== null &&
        this.activeSection === target.previousSibling.id
      )
        return;

      if (isIntersecting) {
        this.previousSection = this.activeSection;
        this.activeSection = target.id;
      }
    },
    onObserveSpy(ev) {
      const { isIntersecting } = ev[0];
      this.isScrolled = !isIntersecting;
    },
    onGoToSection(ev) {
      const top =
        this.$el.querySelector(ev.target.hash).offsetTop -
        this.$refs.header.getBoundingClientRect().height -
        20;

      this.$el.scrollTo({
        top,
        behavior: "smooth",
      });
    },
    openSubstitute(sku) {
      this.$store.commit("detail/setPreviousSku", this.$store.state.detail.sku);
      this.$store.commit("detail/setSku", sku);
      this.$store.dispatch("detail/load", true);
    },
    openPreviousProduct() {
      this.$store.commit("detail/setSku", this.previousProduct);
      this.$store.commit("detail/setPreviousSku", null);
      this.$store.dispatch("detail/load", true);
    },
    initialFocus() {
      this.$nextTick(() => {
        this.$refs.nav.querySelector('[tabindex="0"]').focus();
      });
    },
    onCompareWithSubstitutes(substituteIds) {
      const skus = [this.$store.state.detail.sku, ...substituteIds];
      this.$store.commit("compare/reset");
      this.$store.commit("compare/setSkus", skus);
      this.$store.commit("compare/setSubstitute", substituteIds);
      this.$store.commit("compare/setIsVisible", true);
      this.$store.dispatch("compare/load");
    },
  },
};
</script>

<style>
@media print {
  section#links-downloads,
  #nav_header {
    display: none !important;
  }
}
</style>

<style lang="scss" module>
@import "~@webprojects/ui-pattern-library/src/stylesheets/environment";

@mixin global {
  // max-width: 888px;
  // margin: 0 auto;
}

.root {
  -webkit-overflow-scrolling: touch;
  max-height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  position: relative;
}

.inner {
  /* div only exists to fix safari bug that prevents position: sticky; to work
   * when the parent has overflow set.
   */
}

/**
 * The spy checks whether the area got scrolled to activate the header shadow
 */
.spy {
  background: get-color(light);
  height: 1px;
  margin-bottom: -1px;
  width: 100%;
}

.header {
  // reduce 26px font-size to 20px font-size using transform: scale();
  $hlScaleFactor: calc(20/26);
  // rises negative translateY to remove scaled headline whitespace
  $topScaleFactor: (1 + (1 - $hlScaleFactor));

  background: get-color(light);
  margin-bottom: get-space(m);
  padding: get-space(s) get-space(l) get-space(m) get-space(l);
  position: sticky;
  top: 0;
  transition: transform 0.2s ease;
  will-change: transform;
  z-index: 1;

  &--is-scrolled {
    box-shadow: 0 0 40px #ebeced;
    transform: translateY(-#{ceil(get-space(m) * $topScaleFactor)});
  }

  &--has-btn-back {
    padding-top: get-space(m);
  }

  &--is-scrolled.header--has-btn-back {
    transform: translateY(-#{ceil(get-space(s) * $topScaleFactor)});
  }

  &__btn-back {
    display: flex !important;
    margin-bottom: get-space(s) !important;
    margin-left: -30px !important;
    transition: all 0.2s ease !important;
    will-change: transform;
  }

  &--is-scrolled &__btn-back {
    transform: translateY(#{ceil(get-space(xs) * $topScaleFactor)});
  }

  &__title-wrapper {
    @include global;
    display: grid;
    grid-gap: get-space(m);
    grid-template-columns: 1fr auto;
    grid-template-rows: auto;
    margin-bottom: get-space(s);
  }

  &__title {
    color: get-color(blue-dark) !important;
    transform-origin: bottom left;
    transition: transform 0.2s ease;
    will-change: transform;
  }

  &--is-scrolled &__title {
    transform: scale($hlScaleFactor);
  }

  &__compare {
    display: flex;
    flex-direction: column;
    transition: transform 0.2s ease;
  }

  &--is-scrolled &__compare {
    transform: translateY(10px);
  }

  &__nav {
    @include global;
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    margin-bottom: -1 * get-space(s);
  }

  &__nav-item {
    @include text-type-copy-small;
    color: get-color(gray-dark);
    display: block;
    margin: 0 get-space(l) get-space(s) 0;
    text-decoration: none;

    [data-whatintent="mouse"] &,
    [data-whatintent="touch"] & {
      outline: 0;
    }

    &--is-active {
      color: get-color(blue-dark);
    }
  }
}

.section {
  @include global;
  width: calc(100% - #{2 * get-space(l)});
  margin: get-space(l) auto;

  // first section after header
  .header + & {
    margin-top: 0;
  }

  &__item-box {
    max-width: 888px;
    z-index: 0 !important;

    &--has-price {
      max-width: 100%;
    }
  }

  &__general {
    width: 100%;

    @include mq(l) {
      display: flex;
    }
  }
}

.headline {
  padding-bottom: get-space(m);
}

.contentrow {
  @include mq(m) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    grid-column-gap: get-space(l);
  }
}

.contentcol {
  margin-bottom: get-space(l);

  &:last-child {
    margin-bottom: 0;
  }

  @include mq(m) {
    margin-bottom: 0;
  }
}

.contentlink {
  display: block !important;

  margin: {
    bottom: 5px !important;
  }

  &:last-child {
    margin-bottom: 0 !important;
  }
}

.export {
  background: get-color(gray-dark);
  border-top-left-radius: 4px;
  bottom: 0;
  float: right;
  padding: get-space(s) get-space(m);
  position: sticky;

  svg {
    fill: get-color(light) !important;
    margin: 0 !important;
  }
}
</style>
