<template>
  <MoleculeNote v-if="isEmpty" :text="t('note')" is-truly-centered />
  <OrganismDetailsLoading v-else-if="!isEmpty && isLoading" />
  <MoleculeError v-else-if="error" :error="error" @retry="onRetry" />
  <OrganismDetails
    v-else
    :product="product"
    :previous-product="previousProduct"
  >
    <template #compare>
      <BuAtomButton
        :modifier="['link', 'small']"
        :text="t('toComparison')"
        icon="eye"
        @button-click="onCompare"
      />
    </template>
    <template #parts>
      <DetailParts />
    </template>
  </OrganismDetails>
</template>

<script>
import { mapState } from "vuex";

import MoleculeError from "../../styleguide/molecules/MoleculeError.vue";
import MoleculeNote from "../../styleguide/molecules/MoleculeNote.vue";
import OrganismDetails from "../../styleguide/organisms/OrganismDetails.vue";
import OrganismDetailsLoading from "../../styleguide/organisms/OrganismDetailsLoading.vue";

import DetailParts from "./DetailParts.vue";

export default {
  components: {
    MoleculeError,
    MoleculeNote,
    OrganismDetails,
    OrganismDetailsLoading,

    DetailParts,
  },

  computed: {
    ...mapState({
      error: (state) => state.detail.data.error,
      isEmpty: (state) => state.detail.sku === null,
      isLoading: (state) => state.detail.data.isLoading,
      previousProduct: (state) => state.detail.previousSku,
      product: (state) => state.detail.data.data,
      sku: (state) => state.detail.sku,
    }),
  },

  methods: {
    t(key, options) {
      const prefix = "connected.detail";
      return this.$t(`${prefix}.${key}`, options);
    },
    onRetry() {
      this.$store.dispatch("detail/load");
    },
    onCompare() {
      this.$store.commit("compare/setSkuInComparison", { sku: this.sku });
      this.$store.commit("compare/setSubstitute", []);
      this.$store.commit("detail/reset");
      this.$store.dispatch("compare/load");
      this.$store.commit("compare/setIsVisible", true);
    },
  },
};
</script>
