import { createStore } from "vuex";

import categories from "./modules/categories";
import compare from "./modules/compare";
import detail from "./modules/detail";
import global from "./modules/global";
import search from "./modules/search";

const store = createStore({
  strict: process.env.NODE_ENV !== "production",
  modules: {
    categories,
    compare,
    detail,
    global,
    search,
  },
});

// NEBU-513 automatically open the detail view of the first result
store.watch(
  (state) => state.search.result.lastUpdated,
  () => {
    if (
      store.getters["search/hasResults"] &&
      !store.getters["search/hasTooManyResults"]
    ) {
      const { sku } = store.state.search.result.data.items[0].values;
      store.commit("compare/setIsVisible", false);
      store.commit("detail/setSku", sku);
      store.commit("detail/setPreviousSku", null);
      store.dispatch("detail/load");
    }
  }
);

// NEBU-790 always reset isContentMuted when there are no results
store.watch(
  (state, getters) => getters["search/hasResults"],
  () => {
    if (
      !store.getters["search/hasResults"] &&
      store.state.global.isContentMuted
    ) {
      store.commit("global/setIsContentMuted", false);
    }
  }
);

export default store;
