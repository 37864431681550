<template>
  <component :is="tag">
    <slot :is-expanded="isThisExpanded" />
  </component>
</template>

<script>
import { uuid } from "vue-uuid";
export default {
  inject: ["isExpanded", "onExpandFirst"],

  provide() {
    return {
      uuid: this.uuid
    };
  },

  props: {
    tag: {
      type: String,
      default: "div"
    },
    uuid: {
      type: String,
      default() {
        return `accordion-item-${uuid.v4()}`;
      }
    }
  },

  emits: ['open'],

  computed: {
    isThisExpanded() {
      return this.isExpanded(this.uuid);
    }
  },

  watch: {
    isThisExpanded(isExpanded) {
      this.$emit(isExpanded ? "open" : "close");
    }
  },

  mounted() {
    this.onExpandFirst(this.uuid);

    // when initially expanded, trigger the open event
    // TODO is this a good idea?
    if (this.isThisExpanded) {
      this.$emit("open");
    }
  }
};
</script>
