const state = () => ({
  /**
   * SKU of the currently opened product
   * @type {String}
   */
  sku: null,

  /**
   * SKU of the previously opened product
   * Only set when navigating from a product to it's substitute
   * @type {String}
   */
  previousSku: null,

  /**
   * Quantity of current product
   * @type {Number}
   */
  quantity: 1,

  data: {
    /**
     * Article API result data
     * @type {Object}
     */
    data: {},

    /**
     * API response error
     * @type {Boolean|Object}
     */
    error: false,

    /**
     * Whether data is currently loading
     * @type {Boolean}
     */
    isLoading: false,

    /**
     * Whether partial data is currently loading
     * @type {Boolean, String}
     */
    isRunningRequest: false,

    /**
     * When the API last got called
     * @type {Number}
     */
    lastUpdated: -1,
  },

  parts: {
    /**
     * Parts API result data
     * @type {Object}
     */
    data: {},

    /**
     * API response error
     * @type {Boolean|Object}
     */
    error: false,

    /**
     * Whether data is currently loading
     * @type {Boolean}
     */
    isLoading: true,

    /**
     * When the API last got called
     * @type {Number}
     */
    lastUpdated: -1,
  },
});

export default state;
