<template>
  <Teleport to="#modals">
    <BuOrganismModalNew v-bind="$attrs" :headline="{ text: t('headline') }">
      <BuAtomCopy :modifier="['small']" :text="t('text')" />
      <div v-for="(accordion, index) in accordions" :key="`changelog_${index}`">
        <BuMoleculeContentBox
          :headline="{ text: accordion.headline }"
          :modifier="['small', 'no-content-padding']"
        >
          <BuOrganismAccordion :items="accordion.items" render-html />
        </BuMoleculeContentBox>
      </div>
    </BuOrganismModalNew>
  </Teleport>
</template>

<script>
import { ref } from "vue";
import { useI18n } from "vue-i18n";

export default {
  inheritAttrs: false,

  setup() {
    const { data, onChange } = useI18n();
    const accordions = ref(
      data?.components?.organisms?.modalChangelog?.accordions || []
    );

    onChange(({ data }) => {
      accordions.value =
        data?.components?.organisms?.modalChangelog?.accordions || [];
    });

    return {
      accordions,
    };
  },
  methods: {
    t(key, options) {
      const prefix = "components.organisms.modalChangelog";
      return this.$t(`${prefix}.${key}`, options);
    },
  },
};
</script>
