<template>
  <div>
    <div v-for="i in 2" :key="i">
      <div :class="$style.header">
        <BuAtomSvgSprite id="up" :class="$style['header__icon']" />
        <AtomPlaceholder :class="$style['header__hl']" />
      </div>
      <div v-for="j in 4" :key="j" :class="$style.filter">
        <AtomPlaceholder :class="$style['filter__hl']" />
      </div>
    </div>
  </div>
</template>

<script>
import AtomPlaceholder from "../atoms/AtomPlaceholder.vue";

export default {
  components: {
    AtomPlaceholder,
  },
};
</script>

<style lang="scss" module>
@import "~@webprojects/ui-pattern-library/src/stylesheets/environment";

.header {
  border-bottom: 1px get-color(gray-light) solid;
  display: flex;
  padding: get-space(m);

  &__icon {
    margin-right: get-space(s);
    color: #e6e6e6;
  }

  &__hl {
    height: 20px;
    width: 140px;
  }
}

.filter {
  padding: get-space(xs) get-space(m);
  border-bottom: 1px get-color(gray-light) solid;

  &__hl {
    height: 16px;
    width: 80px;
    margin: 4px 0;
  }
}
</style>
