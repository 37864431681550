<template>
  <li :class="$style.root">
    <button
      v-roving-tabindex
      v-bind="$attrs"
      :class="[$style.button, { [$style['button--is-active']]: isActive }]"
      type="button"
    >
      <span v-if="$slots['icon-before']" :class="$style['icon-before']">
        <slot name="icon-before" />
      </span>

      <span :class="$style.label">
        <slot />
      </span>

      <span v-if="$slots['icon-after']" :class="$style['icon-after']">
        <slot name="icon-after" />
      </span>
    </button>
  </li>
</template>

<script>
export default {
  props: {
    isActive: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" module>
@import "~@webprojects/ui-pattern-library/src/stylesheets/environment";

.root {
  display: block;
  margin: get-space(s) 0;

  &:first-child {
    margin-top: 0;
  }

  &:last-child {
    margin-bottom: 0;
  }
}

.button {
  @extend %button-reset;
  align-items: center;
  display: flex;
  width: 100%;

  &:hover,
  &--is-active {
    color: get-color(blue-dark) !important; // important overrides :focus reset
  }
}

.label {
  @include text-type-copy-small;
  display: block;
  hyphens: auto;
  text-align: left;
}

.icon-before img {
  display: block;
  width: 60px;
  height: 60px;
}

.icon-before svg,
.icon-after svg {
  display: block;
  height: 16px;
  width: 16px;
}

.icon-after {
  margin-left: auto;
}
</style>
