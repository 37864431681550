import { apiEndpoints } from "../../settings";
import apiCall from "../apiCall";
import i18n from "../../i18n";

/**
 * Get search results
 * @param {Object} body - Object of request body parameters
 * @returns {Promise} Promise that resolves with search results or rejects with an ApiError
 */
export const getSearchResults = async (body) =>
  apiCall(apiEndpoints.search, {
    language: i18n.global.locale.value,
    ...body,
  });
