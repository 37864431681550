<template>
  <div :class="$style.root">
    <div :class="$style.row">
      <div v-for="c in cols" :key="c" :class="$style.headerCell">
        <AtomPlaceholder :class="$style.headerPlaceholder" />
      </div>
    </div>

    <div v-for="r in rows" :key="r" :class="$style.row">
      <div v-for="c in cols" :key="c" :class="$style.cell">
        <AtomPlaceholder :class="$style.placeholder" />
      </div>
    </div>
  </div>
</template>

<script>
import AtomPlaceholder from "../atoms/AtomPlaceholder.vue";

export default {
  components: {
    AtomPlaceholder,
  },

  props: {
    cols: {
      type: Number,
      default: 3,
    },
    rows: {
      type: Number,
      default: 3,
    },
  },
};
</script>

<style lang="scss" module>
@import "~@webprojects/ui-pattern-library/src/stylesheets/environment";

.root {
  max-width: 800px;
}

.row {
  display: flex;
  margin: 0 -1 * get-space(s);
}

.headerCell {
  flex: 1 1 0%;
  padding: 0 0 get-space(m) 0;
  margin: 0 get-space(s);
}

.headerPlaceholder {
  height: 20px;
  width: 60%;
}

.cell {
  flex: 1 1 0%;
  padding: 2px 0;
  margin: 0 get-space(s);
}

.placeholder {
  height: 16px;
  width: 100%;
}
</style>
