const state = () => ({
  /**
   * Whether the comparison is currently visible
   * @type {Boolean}
   */
  isVisible: false,

  /**
   * Whether the comparison checkboxes are force-enabled
   * @type {Boolean}
   */
  checkboxesEnabled: false,

  /**
   * Whether only differences are displayed
   * @type {Boolean}
   */
  onlyDifferences: false,

  /**
   * SKUs of the products that should be compared
   * @type {String[]}
   */
  skus: [],

  /**
   * SKUs that are highlighted as being a substitute
   * @type {String[]}
   */
  substitute: [],

  data: {
    /**
     * Comparison API result data
     * @type {Object}
     */
    data: {},

    /**
     * API response error
     * @type {Boolean|Object}
     */
    error: false,

    /**
     * Whether data is currently loading
     * @type {Boolean}
     */
    isLoading: false,

    /**
     * When the API last got called
     * @type {Number}
     */
    lastUpdated: -1,
  },
});

export default state;
