import { apiEndpoints } from "../../settings";
import apiCall from "../apiCall";
import i18n from "../../i18n";

/**
 * Get single article with parts
 * @param {String} sku - Article SKU
 * @returns {Promise} Promise that resolves with an article or rejects with an ApiError
 */
export const getArticle = async (sku) =>
  apiCall(apiEndpoints.article, {
    language: i18n.global.locale.value,
    sku,
  });
