<template>
  <button
    :id="id"
    :aria-controls="ariaControls"
    :aria-disabled="`${ariaDisabled}`"
    :aria-expanded="`${ariaExpanded}`"
    data-accordion-component="AccordionItemButton"
    type="button"
    @click.prevent="onClick"
    @keydown="onKeyDown"
  >
    <slot />
  </button>
</template>

<script>
const SELECTOR_ACCORDION = '[data-accordion-component="Accordion"]';
const SELECTOR_ACCORDION_ITEM_BUTTON =
  '[data-accordion-component="AccordionItemButton"]';

export default {
  inject: [
    "allowMultipleExpanded",
    "allowZeroExpanded",
    "isExpanded",
    "onExpand",
    "uuid"
  ],

  emits: ['keydown'],

  computed: {
    id() {
      return `accordion__button-${this.uuid}`;
    },
    ariaControls() {
      return `accordion__panel-${this.uuid}`;
    },
    ariaDisabled() {
      return (
        this.ariaExpanded &&
        (!this.allowMultipleExpanded || !this.allowZeroExpanded)
      );
    },
    ariaExpanded() {
      return this.isExpanded(this.uuid);
    }
  },

  methods: {
    /**
     * Get all direct headings of the parent accordion
     * The filter is some crazy hackery to make nested accordions work.
     * @returns {HTMLElement[]} List of all direct headings
     */
    getHeadings() {
      return Array.from(
        this.$el
          .closest(SELECTOR_ACCORDION)
          .querySelectorAll(SELECTOR_ACCORDION_ITEM_BUTTON)
      ).filter(
        $btn =>
          $btn.closest(SELECTOR_ACCORDION) ===
          this.$el.closest(SELECTOR_ACCORDION)
      );
    },
    onClick() {
      this.onExpand(this.uuid);
    },
    onKeyDown(ev) {
      switch (ev.key) {
        case "ArrowUp": {
          ev.preventDefault();

          const $headings = this.getHeadings();
          const cur = $headings.indexOf(this.$el);
          const next = cur === 0 ? $headings.length - 1 : cur - 1;

          $headings[next].focus();

          break;
        }
        case "ArrowDown": {
          ev.preventDefault();

          const $headings = this.getHeadings();
          const cur = $headings.indexOf(this.$el);
          const next = cur === $headings.length - 1 ? 0 : cur + 1;

          $headings[next].focus();

          break;
        }
        case "Home": {
          ev.preventDefault();

          const $headings = this.getHeadings();
          $headings[0].focus();
          break;
        }
        case "End": {
          ev.preventDefault();

          const $headings = this.getHeadings();
          $headings[$headings.length - 1].focus();
          break;
        }
        default: {
          this.$emit("keydown", ev);
          break;
        }
      }
    }
  }
};
</script>
