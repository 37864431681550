const state = () => ({
  /**
   * Path of active categories, e.g. ["0", "1", "1_9"]
   * @type {string[]}
   */
  active: ["0"],

  data: {
    /**
     * Normalized product category received from API
     * @type {Object}
     */
    data: {},

    /**
     * API response error
     * @type {Boolean|Object}
     */
    error: false,

    /**
     * Whether data is currently loading
     * @type {Boolean}
     */
    isLoading: false,

    /**
     * When the API last got called
     * @type {Number}
     */
    lastUpdated: -1,
  },
});

export default state;
