<template>
  <transition
    :enter-class="$style['root-enter']"
    :leave-to-class="$style['root-leave-to']"
  >
    <div v-show="isVisible" :class="$style.root" />
  </transition>
</template>

<script>
export default {
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" module>
.root {
  background-color: rgba(0, 0, 0, 0.3);
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  transition: opacity 0.2s ease;
  width: 100%;
  z-index: 2;

  &-enter,
  &-leave-to {
    opacity: 0;
  }
}
</style>
