import store from "./store";

const initSw = async () => {
  const { Workbox } = await import("workbox-window");

  const wb = new Workbox(`${process.env.BASE_URL}service-worker.js`);
  window.wb = wb;

  // @see https://developers.google.com/web/tools/workbox/guides/advanced-recipes#offer_a_page_reload_for_users
  wb.addEventListener("waiting", () =>
    store.commit("global/setHasUpdate", true)
  );

  wb.register();
};

if (process.env.NODE_ENV === "production" && "serviceWorker" in navigator) {
  initSw();
}
