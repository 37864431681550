const defaultOptions = {
  /**
   * Used to split items' textKey into words.
   *
   * @type {Regex}
   */
  splitRegex: /\s+/,
};

/**
 * This is a autosuggest suffix tree so one can search for substrings
 *
 * Based on ideas from https://github.com/moroshko/autosuggest-trie
 *
 * It basically creates a tree of all suffixes of a word where each word
 * in a sentence gets priorized by its position in the sentence. When
 * searching for some query, later words filter results from previous
 * words.
 */
class AutosuggestSuffixTree {
  /**
   * Split up a string into all of its suffixes
   *
   * Example input: foobar
   *
   * Example output:
   * r
   * ar
   * bar
   * obar
   * oobar
   * foobar
   *
   * @param {string} word - Input word
   * @returns {[]string} All suffixes of a word
   */
  static getSuffixes(word) {
    return [...Array(word.length)].map((_, i) =>
      word.slice(word.length - i - 1, word.length)
    );
  }

  /**
   * Create a new suffix tree
   *
   * @param {[]object} items - Search documents
   * @param {string} textKey - Key which should be used inside items
   * @param {object} options - Tree options. Optional. Default: defaultOptions
   */
  constructor(items, textKey, options = defaultOptions) {
    this.items = items;
    this.options = options;
    this.tree = {};

    // Add all items to the tree
    this.items.forEach((item, i) => this.addSentence(item[textKey], i));

    // this bindings
    this.addSuffix = this.addSuffix.bind(this);
    this.addWord = this.addWord.bind(this);
    this.addSentence = this.addSentence.bind(this);
    this.getWordMatches = this.getWordMatches.bind(this);
    this.getMatches = this.getMatches.bind(this);
  }

  /**
   * Add a single suffix into the tree
   *
   * @param {string} suffix - Single suffix
   * @param {id} - Corresponding search document ID
   * @param {number} suffixIndex - Distance from the word beginning
   */
  addSuffix(suffix, id, suffixIndex) {
    let node = this.tree;

    suffix.split("").forEach((char) => {
      if (!node[char]) {
        node[char] = {
          ids: [],
        };
      }

      if (!node[char].ids[suffixIndex]) {
        node[char].ids[suffixIndex] = [];
      }

      node[char].ids[suffixIndex].push(id);

      node = node[char];
    });
  }

  /**
   * Add a single word into the tree
   *
   * @param {string} word - Single word, e.g. "foo"
   * @param {string} id - Corresponding search document ID
   * @param {number} wordIndex - Index of the word inside the words
   */
  addWord(word, id, wordIndex) {
    AutosuggestSuffixTree.getSuffixes(word).forEach((suffix) =>
      this.addSuffix(suffix, id, wordIndex + (word.length - suffix.length))
    );
  }

  /**
   * Split up a sentence string into single words and add them to the tree
   *
   * @param {string} sentence - Sentence, e.g. "foo bar baz"
   * @param {string} id - Corresponding search document ID
   */
  addSentence(sentence, id) {
    sentence
      .trim()
      .toLowerCase()
      .split(this.options.splitRegex)
      .forEach((word, i) => this.addWord(word, id, i));
  }

  /**
   * Get matches for a single word
   *
   * @param {string} word - Single word to search for
   * @returns {[]number} Sorted array of matching search document index numbers
   */
  getWordMatches(word) {
    let node = this.tree;

    const chars = word.trim().toLowerCase();

    // Use a set so we do not have to care about duplicates
    const resultIds = new Set();

    // performance.mark('getWordMatches-start');

    // use a for loop here so we can break using return
    for (let i = 0; i < chars.length; i += 1) {
      if (node[chars[i]]) {
        node = node[chars[i]];
      } else {
        return resultIds;
      }
    }

    node.ids.forEach((ids) => ids.forEach((id) => resultIds.add(id)));

    // performance.mark('getWordMatches-end');

    return resultIds;
  }

  /**
   * Get matches for a query, e.g. multiple words
   *
   * @param {string} query - Search query, e.g. "foo bar"
   * @returns {[]object} Sorted array with matching search documents
   */
  getMatches(query) {
    const words = query
      .split(this.options.splitRegex)
      .filter((word) => word.trim().length > 0);

    // performance.mark('getMatches-start');

    return words
      .map(this.getWordMatches)
      .reduce(
        (acc, wordResults, i) =>
          i === 0
            ? // first word: include all results
              [...wordResults]
            : // all other words: filter previous results with results of next word
              acc.filter((id) => wordResults.has(id)),
        []
      )
      .map((id) => this.items[id]);

    // performance.mark('getMatches-end');
  }
}

export default AutosuggestSuffixTree;
