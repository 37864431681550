<template>
  <article
    v-mousetrap="['d', 'v']"
    :class="[$style.root, { [$style['root--is-scrolled']]: canScrollLeft }]"
    @mousetrap="onMousetrap"
  >
    <div style="display: flex; margin-top: 16px; margin-left: 20px">
      <!-- Export as Word -->
      <div>
        <BuAtomButton
          ref="exportAsWord"
          :modifier="['link', 'small']"
          text="Export as word"
          target="_blank"
          icon="download"
          @button-click="exportProduct('word')"
        />
      </div>
      <!-- Export as Excel -->
      <div style="padding-left: 8px">
        <BuAtomButton
          ref="exportAsExcel"
          :modifier="['link', 'small']"
          text="Export as excel"
          target="_blank"
          icon="download"
          @button-click="exportProduct('excel')"
        />
      </div>
    </div>
    <div
      ref="wrapper"
      :class="[
        $style.wrapper,
        { [$style['wrapper--has-note']]: !gteTwoProducts },
      ]"
    >
      <!-- the left spy takes care of whether to show the shadow and whether we can scroll left -->
      <div ref="spy-left" :class="$style.spy" />

      <div :class="$style['table-wrapper']">
        <div :class="$style.pagination" aria-hidden="true">
          <BuAtomButton
            :class="[
              $style['pagination__btn'],
              { [$style['pagination__btn--is-visible']]: canScrollRight },
            ]"
            :modifier="['light']"
            :tabindex="canScrollRight ? '0' : '-1'"
            icon="right"
            @button-click="onScrollRight"
          />
          <BuAtomButton
            :class="[
              $style['pagination__btn'],
              { [$style['pagination__btn--is-visible']]: canScrollLeft },
            ]"
            :modifier="['light']"
            :tabindex="canScrollLeft ? '0' : '-1'"
            icon="left"
            @button-click="onScrollLeft"
          />
        </div>
        <table ref="table" :class="$style.table">
          <thead>
            <!-- top margin made by empty cells -->
            <tr aria-hidden="true">
              <td :class="[$style['cell-margin'], $style['cell-sidebar']]" />
              <td :class="$style['cell-margin']" :colspan="items.length" />
            </tr>

            <tr>
              <!-- top left organism header -->
              <th :class="[$style['cell-sidebar'], $style['cell-header']]">
                <div :class="$style['cell-header__hl']">
                  <BuAtomHeadline :text="t('title')" :modifier="['big']" />

                  <BuAtomButton
                    ref="printComparisonButton"
                    :class="$style['printComparisonButton']"
                    icon="print"
                    icon-sprite="default"
                    :modifier="['link', 'small', 'link']"
                    @button-click="print()"
                  />
                </div>
                <div :class="$style['cell-header__subhl']">
                  <strong>{{
                    t("productCount", { count: items.length })
                  }}</strong>
                </div>

                <!-- Concept - Ticket 2000157963 / comparing 2 products via direct SKU input -->
                <span class="idSkuComparison">
                  <h5>{{ t("idSkuComparison.label") }}</h5>
                  <div v-if="idNotFound" :class="$style['id-not-found']">
                    {{ t("idSkuComparison.error.note") }}
                  </div>
                  <BuAtomInput
                    ref="skuInputForComparison"
                    v-model:value="skuToCompare"
                    label="Suche ein"
                    :class="[$style['app-search__input']]"
                    :modifier="['with-icon', 'with-reset', 'secondary']"
                    placeholder=""
                    icon="search"
                    btn-type="submit"
                    @submit="addSkuToCompare($event)"
                    @keydown="addSkuToCompareOnKeyDown($event)"
                  />
                </span>
                <!-- Concept - Ticket 2000157963 / comparing 2 products via direct SKU input -->

                <!-- show differences toggle that is only shown when there is more than one product -->
                <BuAtomToggle
                  v-if="gteTwoProducts"
                  :id="`toggle-${uid}`"
                  :label="t('differencesToggle')"
                  :value="showDifferences"
                  @change="
                    $emit('update:showDifferences', $event.target.checked)
                  "
                />
              </th>

              <!-- product column headers -->
              <th
                v-for="{ id, product } in items"
                :key="id"
                :class="[
                  $style['cell'],
                  $style['cell-content'],
                  $style['cell-product'],
                  {
                    [$style['cell-highlighted']]: !product.isAvailable,
                  },
                ]"
              >
                <AtomInfoBadge
                  v-if="!product.isAvailable"
                  :class="$style['cell-product__badge']"
                  :label="t('productNotAvailable.label')"
                  type="warning"
                >
                  {{ t("productNotAvailable.content") }}
                </AtomInfoBadge>
                <AtomInfoBadge
                  v-else-if="substituteSkus.includes(product.sku)"
                  :class="$style['cell-product__badge']"
                  :label="t('productIsSubstitute.label')"
                >
                  {{ t("productIsSubstitute.content") }}
                </AtomInfoBadge>
                <!-- TODO: Quickfix for #8: ui-pattern-library must be updated in this project -->
                <img
                  :class="$style['cell-product__img']"
                  :src="product.image.src"
                  loading="lazy"
                />
                <h2 :class="$style['cell-product__hl']">
                  {{ product.title }}
                </h2>
                <h3 :class="$style['cell-product__subhl']">
                  Identnummer {{ product.sku }}
                </h3>
                <BuAtomButton
                  ref="detailsButton"
                  :modifier="['secondary', 'small']"
                  @button-click="$emit('openProduct', product.sku)"
                >
                  {{ t("productDetails") }}
                </BuAtomButton>
                <div :class="$style['cell-product__btn-remove']">
                  <BuAtomLink
                    :title="t('removeProduct')"
                    icon="close"
                    @button-click="$emit('removeProduct', product.sku)"
                  />
                </div>
              </th>
            </tr>
          </thead>

          <tbody>
            <template v-for="(section, si) in sections" :key="section.id">
              <!-- section header -->
              <tr>
                <td :class="$style['cell-sidebar']">
                  <h4 :class="$style['section-hl']">{{ section.title }}</h4>
                </td>
                <td
                  v-for="{ product } in items"
                  :key="product.sku"
                  :style="`background-position-y: ${
                    backgroundOffsets[si + 2]
                  }px`"
                  :class="[
                    $style['cell'],
                    {
                      [$style['cell-highlighted']]: !product.isAvailable,
                    },
                  ]"
                />
              </tr>

              <!-- properties -->
              <tr
                v-for="(header, hi) in section.headers"
                :key="header.id"
                :class="$style['tbody-tr-content']"
              >
                <td :class="[$style['cell-sidebar'], $style['cell-content']]">
                  {{ header.title }}
                </td>
                <td
                  v-for="item in items"
                  :key="item.id"
                  :style="`background-position-y: ${
                    backgroundOffsets[hi + 3]
                  }px`"
                  :class="[
                    $style['cell'],
                    $style['cell-content'],
                    {
                      [$style['cell-highlighted']]: !item.product.isAvailable,
                    },
                  ]"
                >
                  {{ item.values[si][hi] }}
                </td>
              </tr>
            </template>

            <!-- bottom margin made by empty cells -->
            <tr aria-hidden="true">
              <td :class="[$style['cell-margin'], $style['cell-sidebar']]" />
              <td :class="$style['cell-margin']" :colspan="items.length" />
            </tr>
          </tbody>
        </table>
      </div>

      <!-- the right spy takes care of whether we can scroll right -->
      <div ref="spy-right" :class="$style.spy" />
      <MoleculeNote
        v-if="!gteTwoProducts"
        :text="t('note')"
        is-small
        is-align-top
        is-inline
      />
    </div>
  </article>
</template>

<script>
import { uuid } from "vue-uuid";
import isequal from "lodash.isequal";

// ICONS
import { mapActions } from "vuex";
import AtomInfoBadge from "../atoms/AtomInfoBadge.vue";
import MoleculeNote from "../molecules/MoleculeNote.vue";
import { apiEndpoints } from "../../../settings";

export default {
  components: {
    AtomInfoBadge,
    MoleculeNote,
  },

  props: {
    sections: {
      type: Array,
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
    showDifferences: {
      type: Boolean,
    },
    isLoading: {
      type: Boolean,
    },
    substituteSkus: {
      type: Array,
      default: () => [],
    },
  },
  emits: ["update:showDifferences", "openProduct", "removeProduct"],

  data() {
    return {
      uid: uuid.v4(),
      canScrollLeft: false,
      canScrollRight: false,

      backgroundOffsets: [],
      idNotFound: false,
      skuToCompare: "",
    };
  },

  computed: {
    gteTwoProducts() {
      return this.items.length > 1;
    },
  },

  mounted() {
    this.spyObserver = new IntersectionObserver(this.onObserveSpy, {
      root: this.$el,
    });
    this.spyObserver.observe(this.$refs["spy-left"]);
    this.spyObserver.observe(this.$refs["spy-right"]);

    this.updateBackgroundOffsets();
  },

  updated() {
    this.updateBackgroundOffsets();
  },

  beforeUnmount() {
    this.spyObserver.disconnect();
  },

  methods: {
    t(key, options) {
      const prefix = "components.organisms.compare";
      return this.$t(`${prefix}.${key}`, options);
    },
    exportProduct(type) {
      const idsProduct = [];
      for (let i = 0; i < this.items.length; i++) {
        idsProduct.push(this.items[i].id);
      }

      let urlType;
      switch (type) {
        case "excel":
          urlType = "xlsx";
          break;
        case "word":
        default:
          urlType = "docx";
          break;
      }

      const url = `${apiEndpoints.export}/${idsProduct}?lang=${this.$i18n.locale}&type=${urlType}`;
      window.open(url);
    },
    ...mapActions({
      addSkuToComparison: "compare/addSkuToComparison",
    }),
    updateBackgroundOffsets() {
      this.$nextTick(() => {
        const $rows = this.$refs.table.querySelectorAll("tr");
        const offsets = Array.from($rows).reduce(
          (acc, cur, i, arr) => [
            ...acc,
            i === 0 ? 0 : -(arr[i - 1].clientHeight - acc[i - 1]) % 10,
          ],
          []
        );
        if (!isequal(this.backgroundOffsets, offsets)) {
          this.backgroundOffsets = offsets;
        }
      });
    },

    onObserveSpy(ev) {
      ev.forEach((obs) => {
        const { isIntersecting } = obs;
        switch (obs.target) {
          case this.$refs["spy-left"]: {
            this.canScrollLeft = !isIntersecting;
            return;
          }
          case this.$refs["spy-right"]: {
            this.canScrollRight = !isIntersecting;
            return;
          }
          default: {
            return;
          }
        }
      });
    },
    onScrollRight() {
      this.$refs.wrapper.scrollTo({
        left: this.$refs.wrapper.scrollLeft + 300,
        behavior: "smooth",
      });
    },
    onScrollLeft() {
      this.$refs.wrapper.scrollTo({
        left: this.$refs.wrapper.scrollLeft - 300,
        behavior: "smooth",
      });
    },
    onMousetrap() {
      this.$nextTick(() => {
        this.$refs.detailsButton[0].$el.focus();
      });
    },
    async addSkuToCompare() {
      this.idNotFound = !(await this.addSkuToComparison(this.skuToCompare));
    },
    addSkuToCompareOnKeyDown(event) {
      let { keyCode } = event;

      // listen to "enter" (keycode=13) only
      if (keyCode === 13) {
        this.addSkuToCompare();
      }
    },
    print() {
      const printWindow = window.open("");

      // copy the compare section to the new window
      const organismCompare = window.document.querySelector(
        "article[class^=OrganismCompare]"
      );
      printWindow.document.write(
        "<html><head><title>" +
          this.t("title") +
          "</title></head><body> " +
          organismCompare.innerHTML +
          "</body></html>"
      );

      // add css style
      this.addStyle(printWindow);
      // remove elements not needed for printing
      this.filterElements(printWindow);

      printWindow.print();
    },
    addStyle(printWindow) {
      const styles = `
        html * {
          font-family: Arial
        }

        th, td {
          border: 1px solid;
        }

        table {
          border-collapse: collapse;
        }
      `;

      const styleElement = printWindow.document.createElement("style");
      styleElement.appendChild(printWindow.document.createTextNode(styles));
      printWindow.document
        .getElementsByTagName("head")[0]
        .appendChild(styleElement);
    },
    filterElements(printWindow) {
      // remove all buttons
      const buttons = printWindow.document.querySelectorAll("button");
      buttons.forEach((button) => {
        button.parentNode.removeChild(button);
      });

      // remove all toggles
      const toggles = printWindow.document.querySelectorAll(
        "label[class=a-toggle]"
      );
      toggles.forEach((toggle) => {
        toggle.parentNode.removeChild(toggle);
      });

      // remove sku comparison input field
      const skuComparison = printWindow.document.querySelector(
        "span[class=idSkuComparison]"
      );
      skuComparison.parentNode.removeChild(skuComparison);
    },
  },
};
</script>

<style lang="scss" module>
@import "~@webprojects/ui-pattern-library/src/stylesheets/environment";

$sidebarWidth: 250px;
$productWidth: 300px;

.printComparisonButton {
  float: right;
}

.root {
  position: relative;
  height: 100%;
}

.wrapper {
  -webkit-overflow-scrolling: touch;
  display: grid;
  grid-template-columns: auto 1fr auto;
  grid-template-rows: 1fr;
  height: 100%;
  overflow: auto;

  &--has-note {
    grid-template-columns: auto auto auto 1fr;
    grid-template-rows: 1fr;
  }
}

.spy {
  width: 1px;

  &:first-child {
    margin-right: -1px;
  }

  &:last-child {
    margin-left: -1px;
  }
}

.pagination {
  position: absolute;
  z-index: 4;
  left: get-space(l) + $sidebarWidth - 16px;
  top: 50%;
  transform: translateY(-50%);

  &__btn {
    display: flex !important;
    opacity: 0;
    padding: 0 5px !important;
    transition: all 0.2s ease !important;

    &--is-visible {
      opacity: 1;
    }

    &:last-child {
      margin-top: -1px !important;
    }
  }
}

.table-wrapper {
  margin: 0 get-space(l);
}

.wrapper--has-note .table-wrapper {
  margin-right: 0;
}

.table {
  table-layout: fixed;
}

.wrapper--has-note .table {
  margin-right: 0;

  .cell:last-child {
    border-right-color: get-color(light);
  }
}

.cell {
  min-width: $productWidth;
  text-align: left;
  width: $productWidth;
  border-right: 1px get-color(gray-light) solid;
}

.cell-content {
  @include text-type-meta;
  padding: 2px get-space(m);
}

/* sidebar cells that get sticky on scroll */
.cell-sidebar {
  background-color: get-color(light);
  background-clip: padding-box;
  border-right: 1px get-color(gray-light) solid;
  left: get-space(l);
  margin-left: -1 * get-space(l);
  min-width: $sidebarWidth;
  position: sticky;
  width: $sidebarWidth;
  z-index: 2;

  /* white left "margin" so cells that are behind the sticky sidebar cells are not visible */
  &:before {
    content: "";
    display: block;
    width: get-space(l);
    background-color: get-color(light);
    position: absolute;
    top: 0;
    left: -1 * get-space(l);
    height: 100%;
  }

  /* the shadow on the right, emulated using a linear-gradient background */
  &:after {
    content: "";
    position: absolute;
    top: 0;
    height: 100%;
    right: -15px;
    width: 15px;
    background: linear-gradient(to right, rgba(#000, 0.1), rgba(#fff, 0));
    opacity: 0;
    transition: all 0.1s ease;
  }

  &.cell-content {
    padding: 2px get-space(s);
  }
}

.root--is-scrolled .cell-sidebar:after {
  opacity: 1;
}

/* top left main header cell */
.cell-header {
  padding-right: get-space(l);
  text-align: left;
  vertical-align: top;

  &__hl {
    margin-bottom: get-space(s);
  }

  &__subhl {
    @include text-type-copy-small;
    margin-bottom: get-space(m2);

    strong {
      @include text-type-copy-small-bold;
    }
  }
}

/* top product header cell */
.cell-product {
  position: relative;
  vertical-align: top;

  &__badge {
    left: get-space(m);
    position: absolute;
    top: -15px;
  }

  &__img {
    height: 50px;
    margin-top: get-space(l);
    margin-bottom: get-space(m);
  }

  &__hl {
    @include text-type-copy-small-extended;
    margin-bottom: get-space(xs);
  }

  &__subhl {
    @include text-type-meta;
    margin-bottom: get-space(m);
  }

  &__btn-remove {
    position: absolute;
    right: get-space(m);
    top: get-space(s);
  }
}

.cell-highlighted {
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="10" height="10"><path stroke="%23F7ECAD" d="M0 0l10 10"/></svg>');
  background-repeat: repeat;
}

.cell-margin {
  border: 0;
  height: get-space(l);
}

.section-hl {
  @include text-type-copy-small-extended;
  margin: get-space(m2) 0 get-space(m) 0;
}

.tbody-tr-content {
  &:nth-child(even) td {
    background-color: get-color(blue-light);
    background-clip: padding-box;
  }
}

.app-search__input {
  margin-top: get-space(s);
  margin-bottom: get-space(l);
}

.id-not-found {
  color: red;
}
</style>
