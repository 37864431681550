import getInitialState from "./state";

/**
 * Set whether the app got into an erroneous state
 * @param {Object} state - Vuex state
 * @param {Boolean} hasError - Whether the app got into an erroneous state
 */
export const setHasError = (state, hasError) => {
  state.hasError = hasError;
};

/**
 * Set whether the app has an update available
 * @param {Object} state - Vuex state
 * @param {Boolean} hasUpdate - Whether there is an update available
 */
export const setHasUpdate = (state, hasUpdate) => {
  state.hasUpdate = hasUpdate;
};

/**
 * Set whether main content is muted using a translucent black overlay
 * @param {Object} state - Vuex state
 * @param {Boolean} isMuted - Whether main content is muted
 */
export const setIsContentMuted = (state, isMuted) => {
  state.isContentMuted = isMuted;
};

/**
 * Set initialization state
 * @param {Object} state - Vuex state
 * @param {Object} isInitialized - Whether the app is initialized
 */
export const setIsInitialized = (state, isInitialized) => {
  state.isInitialized = isInitialized;
};

/**
 * Set whether the keyboard shortcuts help modal is shown
 * @param {Object} state - Vuex state
 * @param {Boolean} showModal - Whether the modal is shown
 */
export const setShowKeyboardShortcutsModal = (state, showModal) => {
  state.showKeyboardShortcutsModal = showModal;
};

/**
 * Set the user data
 * @param {Object} state - Vuex state
 * @param {Object} data - Response data
 */
export const setUserData = (state, { error, data }) => {
  state.user.lastUpdated = Date.now();
  state.user.error = error || false;
  Object.assign(state.user.data, { ...getInitialState().user.data, ...data });
};

export const logoutUser = (state) => {
  state.user.lastUpdated = null;
  state.user = {};
  state.user.error = true;
  state.user.data = {};
};

/**
 * Set whether user data is loading
 * @param {Object} state - Vuex state
 * @param {Boolean} isLoading - Whether data is loading
 */
export const setUserIsLoading = (state, isLoading) => {
  state.user.isLoading = isLoading;
};

/**
 * Set Miner customer from component
 * @param {Object} state - Vuex state
 * @param {String} customer - Customer Number
 */
export const setCustomer = (state, customer) => {
  state.minerCustomer = customer;
};

/**
 * Set Miner customer from component
 * @param {Object} state - Vuex state
 * @param {String} customer - Customer Number
 */
export const setSegment = (state, segment) => {
  state.minerSegment = segment;
};

export const setSegmentList = (state, segmentList) => {
  state.minerSegmentList = segmentList;
};
/**
 * Set headline and hint from component
 * @param {Object} state - Vuex state
 * @param {Object} hintBox - Hint box data
 */
export const setHintBoxModal = (state, hintBoxModal) => {
  state.hintBoxModal.headline = hintBoxModal.headline;
  state.hintBoxModal.content = hintBoxModal.content;
  state.hintBoxModal.showHintBoxModal = hintBoxModal.showHintBoxModal;
};
