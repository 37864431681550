import { apiEndpoints } from "../../settings";
import apiCall from "../apiCall";

/**
 * Get string translations
 * @param {String} language - Requested language code
 * @returns {Promise} Promise that resolves with the translations or rejects with an ApiError
 */
export const getTranslations = async (language) =>
  apiCall(apiEndpoints.translation, {
    language,
  });
