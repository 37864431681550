<template>
  <div
    :class="[$style.root, { [$style.loading]: isLoading }]"
    role="progressbar"
  >
    <div :class="$style.primary">
      <div :class="$style['primary__inner']" />
    </div>
    <div :class="$style.secondary">
      <div :class="$style['secondary__inner']" />
    </div>
  </div>
</template>

<script>
/**
 * @source https://github.com/material-components/material-components-web/tree/master/packages/mdc-linear-progress
 * @license MIT
 */
export default {
  props: {
    isLoading: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

<style lang="scss" module>
@import "~@webprojects/ui-pattern-library/src/stylesheets/environment";

$bg: get-color(blue-light);
$fg: rgba(get-color(blue-secondary), 0.5);

.root {
  background: $bg;
  height: 1px;
  margin-bottom: -1px;
  opacity: 0;
  overflow: hidden;
  position: relative;
  transition: opacity 0.2s ease;
}

.loading {
  opacity: 1;
}

.primary,
.primary__inner,
.secondary,
.secondary__inner {
  height: 100%;
  position: absolute;
  width: 100%;
}

.loading .primary {
  animation: primary-indeterminate-translate 2s infinite linear;
  left: -145.166611%;

  &__inner {
    animation: primary-indeterminate-scale 2s infinite linear;
    background: $fg;
  }
}

.loading .secondary {
  animation: secondary-indeterminate-translate 2s infinite linear;
  left: -54.888891%;

  &__inner {
    animation: secondary-indeterminate-scale 2s infinite linear;
    background: $fg;
  }
}

@keyframes primary-indeterminate-translate {
  0% {
    transform: translateX(0);
  }

  20% {
    animation-timing-function: cubic-bezier(0.5, 0, 0.701732, 0.495819);
    transform: translateX(0);
  }

  59.15% {
    animation-timing-function: cubic-bezier(0.302435, 0.381352, 0.55, 0.956352);
    transform: translateX(83.67142%);
  }

  100% {
    transform: translateX(200.611057%);
  }
}

@keyframes primary-indeterminate-scale {
  0% {
    transform: scaleX(0.08);
  }

  36.65% {
    animation-timing-function: cubic-bezier(0.334731, 0.12482, 0.785844, 1);
    transform: scaleX(0.08);
  }

  69.15% {
    animation-timing-function: cubic-bezier(0.06, 0.11, 0.6, 1);
    transform: scaleX(0.661479);
  }

  100% {
    transform: scaleX(0.08);
  }
}

@keyframes secondary-indeterminate-translate {
  0% {
    animation-timing-function: cubic-bezier(0.15, 0, 0.515058, 0.409685);
    transform: translateX(0);
  }

  25% {
    animation-timing-function: cubic-bezier(0.31033, 0.284058, 0.8, 0.733712);
    transform: translateX(37.651913%);
  }

  48.35% {
    animation-timing-function: cubic-bezier(0.4, 0.627035, 0.6, 0.902026);
    transform: translateX(84.386165%);
  }

  100% {
    transform: translateX(160.277782%);
  }
}

@keyframes secondary-indeterminate-scale {
  0% {
    animation-timing-function: cubic-bezier(
      0.205028,
      0.057051,
      0.57661,
      0.453971
    );
    transform: scaleX(0.08);
  }

  19.15% {
    animation-timing-function: cubic-bezier(
      0.152313,
      0.196432,
      0.648374,
      1.004315
    );
    transform: scaleX(0.457104);
  }

  44.15% {
    animation-timing-function: cubic-bezier(
      0.257759,
      -0.003163,
      0.211762,
      1.38179
    );
    transform: scaleX(0.72796);
  }

  100% {
    transform: scaleX(0.08);
  }
}
</style>
