<template>
  <ul :class="$style.root">
    <slot />
  </ul>
</template>

<style lang="scss" module>
.root {
  list-style: none;
  margin: 0;
  padding: 0;
}
</style>
