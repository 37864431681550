<template>
  <div :class="$style.wrapper">
    <div ref="table-wrapper" :class="$style['table-wrapper']">
      <MoleculeDataTableColumnChooser
        v-model:visible-columns="visibleColumns"
        :class="$style['column-chooser']"
        :headers="filterableHeaders"
      />
      <div :class="$style['table-wrapper-inner']">
        <OrganismDataTable
          ref="table"
          v-model:selected="selected"
          v-model:sort-by="sortBy"
          :active-item="activeSku"
          :class="$style.table"
          :headers="headers"
          :items="items"
          :show-select="comparisonEnabled"
          item-key="sku"
          @row-click="onRowClick"
        />
        <ResultsTableLoadMore :class="$style.loadMore" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";

import MoleculeDataTableColumnChooser from "../../styleguide/molecules/MoleculeDataTableColumnChooser.vue";
import OrganismDataTable from "../../styleguide/organisms/OrganismDataTable.vue";

import ResultsTableLoadMore from "./ResultsTableLoadMore.vue";

export default {
  components: {
    MoleculeDataTableColumnChooser,
    OrganismDataTable,

    ResultsTableLoadMore,
  },

  computed: {
    ...mapState({
      activeSku: (state) => state.detail.sku,
      isUpdating: (state) => {
        const { actionOrigin, isLoading } = state.search.result;
        return isLoading && actionOrigin === "result";
      },
      items: (state) => state.search.result.data.items,
    }),

    ...mapGetters({
      comparisonEnabled: "compare/checkboxesEnabled",
      filterableHeaders: "search/getFilterableHeaders",
      headers: "search/getHeaders",
    }),

    sortBy: {
      get() {
        return this.$store.state.search.sortBy;
      },
      set(sortBy) {
        this.$store.commit("search/setSortBy", sortBy);
        this.$store.dispatch("search/load", "result");
      },
    },

    selected: {
      get() {
        return this.$store.state.compare.skus;
      },
      set(skus) {
        this.$store.commit("detail/reset");
        this.$store.commit("compare/setSkus", skus);
        this.$store.commit("compare/setSubstitute", []);
        this.$store.dispatch("compare/load");
      },
    },

    visibleColumns: {
      get() {
        return this.$store.state.search.visibleColumns;
      },
      set(visibleColumns) {
        this.$store.commit("search/setVisibleColumns", visibleColumns);
      },
    },
  },

  watch: {
    isUpdating(isUpdating, wasUpdating) {
      if (!isUpdating && wasUpdating) {
        // scroll table to top after content change (pagination, filter change, ...)
        this.$refs["table-wrapper"].scrollTo({ top: 0 });
      }
    },
  },

  mounted() {
    this.$nextTick(() => {
      // focus first row after mount
      this.$refs.table.$el.querySelector("tbody tr").focus();
    });
  },

  methods: {
    onRowClick(row) {
      if (this.activeSku === row.sku) {
        this.$store.commit("detail/reset");
      } else {
        this.$store.commit("compare/setIsVisible", false);
        this.$store.commit("detail/setSku", row.sku);
        this.$store.commit("detail/setPreviousSku", null);
        this.$store.dispatch("detail/load");
      }
    },
  },
};
</script>

<style lang="scss" module>
@import "~@webprojects/ui-pattern-library/src/stylesheets/environment";

.wrapper {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr;
  height: 100%;
}

.table-wrapper {
  -webkit-overflow-scrolling: touch;
  grid-row: 2;
  overflow: auto;
}

.table-wrapper-inner {
  padding: get-space(l);
}

.loadMore {
  padding: get-space(xs) get-space(s);
  text-align: center;
}

.pagination {
  background: get-color(light);
  border-top: 1px get-color(gray-light) solid;
  bottom: 0;
  grid-row: 3;
  padding: get-space(xs) get-space(s);
}

.column-chooser {
  background: get-color(light);
  border-bottom-left-radius: 5px;
  grid-row: 1;
  padding: get-space(s);

  float: right;
  left: calc(100% - 40px); // 40px is the column chooser's width
  position: sticky;
  top: 0;
  z-index: 2;
  width: 0;
}
</style>
